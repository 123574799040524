import React, { useEffect} from 'react'
import { useSelector } from 'react-redux'

const useHideMedium = ({ fetchItems, groupedGetSet, normalGetSet, isHiddenList }) => {
  // fetchItems will be called on load more click
  // groupedGetSet is an array of [groupedMedia, setGroupedMedia]
  const user_medium_hidden = useSelector((state) => state.user_medium_hidden)

  useEffect(() => {
    if(user_medium_hidden.medium_id && user_medium_hidden.is_hidden === (isHiddenList ? false : true)) {
      if(groupedGetSet) {
        for(var i = 0; i < groupedGetSet.length; i++) {
          if(groupedGetSet[i].length == 2) {
            if(groupedGetSet[i][0].grouped_media) {
              removePaginatedGrouped(user_medium_hidden.medium_id, groupedGetSet[i][0], groupedGetSet[i][1])
            } else {
              removeGroupedMedium(user_medium_hidden.medium_id, groupedGetSet[i][0], groupedGetSet[i][1])
            }
          }
        }
      }
      if(normalGetSet) {
        for(var i = 0; i < normalGetSet.length; i++) {
          if(normalGetSet[i].length == 2) {
            removeMedium(user_medium_hidden.medium_id, normalGetSet[i][0], normalGetSet[i][1])
          }
        }
      }
    }
  }, [user_medium_hidden])

  const removeMedium = (mediumId, arrayItems, setArrayItems) => {
    var hasReloadItem = false
    var filtered = []
    var countHidden = 0

    for(var x = 0; x < arrayItems.length; x++) {
      var childItem = arrayItems[x]
      if(childItem.id === mediumId) {
        childItem.hidden = true
        countHidden += 1
      } else if(childItem.hidden === true) {
        countHidden += 1
      } else if(childItem.is_reload === true) {
        hasReloadItem = true
      }
      filtered.push(childItem)
    }
    if(!hasReloadItem && countHidden > 0 && filtered.length >= 25) {
      filtered.push({is_reload: true})
    }
    if(countHidden > 0) {
      setArrayItems(filtered)
    }
    
    return filtered
  }
  
  // Put hidden flag in the item
  // If has more than xx hidden items, put an "is_reload" item
  const removePaginatedGrouped = (mediumId, arrayItems, setArrayItems) => {
    var changed = false
    var arrayFiltered = []

    for(var i = 0; i < arrayItems.grouped_media.length; i++) {
      var hasReloadItem = false
      const item = arrayItems.grouped_media[i]
      var filtered = []
      var countHidden = 0

      for(var x = 0; x < item.media.length; x++) {
        var childItem = item.media[x]

        if(childItem.id === mediumId) {
          childItem.hidden = true
          countHidden += 1
          changed = true
        } else if(childItem.hidden === true) {
          countHidden += 1
        } else if(childItem.is_reload === true) {
          hasReloadItem = true
        }
        filtered.push(childItem)
      }
      if(!hasReloadItem && countHidden > 0 && filtered.length >= 25) {
        filtered.push({is_reload: true})
      }
      
      changed = changed || item.media.length !== filtered.length
      item.media = filtered
      arrayFiltered.push(item)
    }

    if(changed) {
      setArrayItems({...arrayItems, grouped_media: arrayFiltered })
    }
    return arrayFiltered
  }
  
  // Put hidden flag in the item
  // If has more than xx hidden items, put an "is_reload" item
  const removeGroupedMedium = (mediumId, arrayItems, setArrayItems) => {
    var changed = false
    var arrayFiltered = []
    for(var i = 0; i < arrayItems.length; i++) {
      var hasReloadItem = false
      const item = arrayItems[i]
      var filtered = []
      var countHidden = 0

      for(var x = 0; x < item.media.length; x++) {
        var childItem = item.media[x]

        if(childItem.id === mediumId) {
          childItem.hidden = true
          countHidden += 1
          changed = true
        } else if(childItem.hidden === true) {
          countHidden += 1
        } else if(childItem.is_reload === true) {
          hasReloadItem = true
        }
        filtered.push(childItem)
      }
      if(!hasReloadItem && countHidden > 0 && filtered.length >= 25) {
        filtered.push({is_reload: true})
      }
      
      changed = changed || item.media.length !== filtered.length
      item.media = filtered
      arrayFiltered.push(item)
    }

    if(changed) {
      setArrayItems(arrayFiltered)
    }
    return arrayFiltered
  }

  return {}
}

export default useHideMedium