import { Routes, Route } from "react-router-dom"
import SubscriptionSuccess from '../Payment/SubscriptionSuccess'
import SubscriptionCanceled from '../Payment/SubscriptionCanceled'
import Logout from '../Login/Logout'
import PlanList from '../Payment/PlanList'
import TabDiscover from './TabDiscover'
import TabMyLists from './TabMyLists'
import TabRating from './TabRating'
import TabSearch from './TabSearch'
import ShowPerson from './ShowPerson'
import TabSettings from './TabSettings'
import HiddenList from './HiddenList'
import ResetPasswordForm from '../Login/ResetPasswordForm'
import GetTheApp from '../Footer/GetTheApp'
import About from '../Footer/About'
import Faq from '../Footer/Faq'
import Contact from '../Footer/Contact'
import TermsPage from './TermsPage'
import PrivacyPage from './PrivacyPage'
import DeletionPage from './DeletionPage'
import DeleteAccountPage from './DeleteAccountPage'
import ConfirmDeleteAccountPage from './ConfirmDeleteAccountPage'
import AcceptRejectList from './AcceptRejectList'
import ShowList from '../List/Show'

export default function MemberRoutes({ selection, setSelection }) {
  return (
    <Routes>
      <Route path="/get_the_app" element={<GetTheApp />} />
      <Route path="/about" element={<About />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/deletion" element={<DeletionPage />} />
      <Route path="/delete_account" element={<DeleteAccountPage />} />
      <Route path="/confirm_delete_account" element={<ConfirmDeleteAccountPage />} />
      <Route path="/users/password/edit" element={<ResetPasswordForm />} />
      <Route path="/checkout_success" element={<SubscriptionSuccess />} />
      <Route path="/checkout_canceled" element={<SubscriptionCanceled />} />
      <Route path="/plans" element={<PlanList/>} />
      <Route path="/logout" element={<Logout/>} />
      <Route path="/accept_list/:code" element={<AcceptRejectList isAccept={true} />} />
      <Route path="/reject_list/:code" element={<AcceptRejectList isAccept={false} />} />
      <Route path="/people/:personId" element={<ShowPerson setSelection={setSelection} selection={selection} initialSelection={'from_person'} />} />
      <Route path="/people/:personId/media/:mediaId" element={<ShowPerson setSelection={setSelection} selection={selection} initialSelection={'from_person'} />} />
      <Route path="/discover" element={<TabDiscover setSelection={setSelection} selection={selection} initialSelection={''} />} />
      <Route path="/discover/media/:mediaId" element={<TabDiscover setSelection={setSelection} selection={selection} initialSelection={''} />} />
      <Route path="/hidden" element={<HiddenList selection={selection} setSelection={setSelection}/>} />
      <Route path="/hidden/media/:mediaId" element={<HiddenList selection={selection} setSelection={setSelection}/>} />
      <Route path="/my_lists" element={<TabMyLists setSelection={setSelection} selection={selection} initialSelection={'my_lists'} /> } />
      <Route path="/my_lists/media/:mediaId" element={<TabMyLists setSelection={setSelection} selection={selection} initialSelection={'my_lists'} /> } />
      <Route path="/my_lists/:id"                element={<ShowList setSelection={setSelection} selection={selection} initialSelection={'my_lists'} /> } />
      <Route path="/my_lists/:id/media/:mediaId" element={<ShowList setSelection={setSelection} selection={selection} initialSelection={'my_lists'} /> } />
      <Route path="/my_lists/share/:listId" element={<TabMyLists setSelection={setSelection} selection={selection} initialSelection={'my_lists'} /> } />
      <Route path="/rating" element={<TabRating setSelection={setSelection} selection={selection} initialSelection={'rating'} />} />
      <Route path="/rating/media/:mediaId" element={<TabRating setSelection={setSelection} selection={selection} initialSelection={'rating'} />} />
      <Route path="/search" element={<TabSearch setSelection={setSelection} selection={selection} initialSelection={'search'} />} />
      <Route path="/search/media/:mediaId" element={<TabSearch setSelection={setSelection} selection={selection} initialSelection={'search'} />} />
      <Route path="/settings" element={<TabSettings setSelection={setSelection} selection={selection} initialSelection={'settings'} />} />
      <Route path="/" element={<TabDiscover setSelection={setSelection} selection={selection} initialSelection={''}/>} />
    </Routes>
  )
}
