import { useState, useEffect } from 'react'
import { Form } from "react-bootstrap"
import { api_client, errorHandler } from '../../config/api-client'
import ConfirmDialog from '../Settings/ConfirmDialog'

export default function CollaborateFlag({ userList }) {
  const [ collaborate, setCollaborate ] = useState(userList.collaborate)
  const [ showConfirmation, setShowConfirmation ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if(collaborate != userList.collaborate) setCollaborate(userList.collaborate)
  }, [ userList ])

  if(!userList.is_owner) return (<div />)

  const confirmChangeCollaborate = () => {
    const access_token = localStorage.getItem('access_token')
    setLoading(true)
    api_client(access_token).put(`/api/v1/user_lists/${userList.id}.json`, {
      user_list: {
        collaborate: !collaborate,
      } 
    }).then(res => {
      setCollaborate(!collaborate)
      setLoading(false)
      setShowConfirmation(false)
    }).catch((error) => {
      setLoading(false)
      setShowConfirmation(false)
      errorHandler(error, dispatch)
    })
  }

  return (
    <div className="filter-row">
      <span className="check-label">Collaborative List</span>
      <Form.Check
        className="check-field custom-switch"
        type="switch"
        id="collaborate-switch"
        checked={collaborate}
        onChange={() => setShowConfirmation(true)}
      />

      {showConfirmation && <ConfirmDialog
        visible={true}
        setVisible={setShowConfirmation}
        title="Confirm"
        buttonLabel="Yes, I am sure"
        prompt={(
          <div>
            {!collaborate && <div style={{marginTop: '10px', fontSize: '1.1rem'}}>
              Are you sure you want to allow all list members to be able to edit it?
            </div>}
            {collaborate && <div style={{marginTop: '10px', fontSize: '1.1rem'}}>
              Are you sure you want to remove permission to modify this list?
            </div>}
          </div>
        )}
        loading={loading}
        onConfirm={confirmChangeCollaborate}
      />}
    </div>
  )
}