import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function SubscriptionCanceled() {
  const navigate = useNavigate()

  const selectAPlan = () => {
    navigate('/plans')
  }

  return(
    <div>
      <span>You have canceled the subscription operation.</span>
      <div>
        <a href="/plans" className="color-link">back to select a plan</a>
        <button type="button" className="btn" onClick={selectAPlan}>Back to Select a Plan</button>
      </div>
    </div>
  )
}
