import './App.css'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedInUser } from '../../actions/user'
import { setNotification } from '../../actions/notification'
import TopNavigation from '../Layout/TopNavigation'
import StartNavigation from '../Layout/StartNavigation'
import { api_client } from '../../config/api-client'
import MemberRoutes from './MemberRoutes'
import GuestRoutes from './GuestRoutes'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import { useLocation, useNavigate } from "react-router-dom"
import ReactPixel from 'react-facebook-pixel'
import { configurePixel } from '../../config/facebook-pixel'
import ReactGA from 'react-ga4'
import { configureGoogleAnalytics } from '../../config/google-analytics'
import CookieConsent from './CookieConsent'
import MediaModal from '../MediaBrowser/MediaModal'
import { isMobile } from 'react-device-detect'

export default function App() {
  const dispatch = useDispatch()
  const [loggedIn, setLoggedIn] = useState(false)
  const user = useSelector(state => state.user)
  const [selection, setSelection] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
    
  useEffect(() => {
    configurePixel()
    configureGoogleAnalytics()
    const pagePath = `${location.pathname}${location.search}`
    if(isMobile && canOpenInMobile(pagePath)) {
      window.location = `vuniverse://vuniverse.com${pagePath}`
    }
    ReactGA.send({ hitType: "pageview", page: pagePath })
    ReactPixel.pageView()
  }, [location])

  const canOpenInMobile = (pathUrl) => {
    if(pathUrl === '/' || pathUrl.indexOf('/media/') !== -1 ||
       pathUrl.indexOf('/accept_list/') !== -1 || 
       pathUrl.indexOf('/reject_list/') !== -1 ||
       pathUrl.indexOf('/my_lists/share/') !== -1 ) {
      return true
    }
    return false
  }

  useEffect(() => {
    const loadUser = () => {
      const access_token = localStorage.getItem('access_token')

      if (access_token && access_token !== 'false') {
        api_client(access_token).get('/api/v1/user.json').then((response) => {
          dispatch(setLoggedInUser(response.data.user, response.data.user.access_token))
        }).catch((error) => {
          logOutUser()
        })
      } else {
        logOutUser()
      }
    }

    loadUser()
  }, [dispatch])

  const logOutUser = () => {
    if(loggedIn) {
      setLoggedIn(false)
    }
  }

  useEffect(() => {
    if(loggedIn) {
      api_client(localStorage.getItem('access_token')).get('/api/v1/user_interactions.json').then((response) => {
        if(response.data.user_interactions && response.data.user_interactions.length > 0) {
          const userInteraction = response.data.user_interactions[0]
          dispatch(setNotification({id: userInteraction.id, user_interaction: true}))
        }
      }).catch((error) => {
        console.log('User interaction request error', error)
      })
    }
  }, [loggedIn])

  useEffect(() => {
    if(user.reload) {
      api_client(localStorage.getItem('access_token')).get('/api/v1/user.json').then((response) => {
        dispatch(setLoggedInUser(response.data.user, response.data.user.access_token))
      }).catch((error) => {
        localStorage.removeItem('access_token')
        logOutUser()
      })
    } else if (user.access_token && user.trial_or_subscribed) {
      if(!loggedIn) {
        if(localStorage.getItem('redirect_to')) {
          const pathParts = localStorage.getItem('redirect_to').split("/")
          if(pathParts.length === 4 && pathParts[2] === 'share') {
            const listId =  pathParts[3].split('?')[0]
            api_client(localStorage.getItem('access_token')).post(`/api/v1/user_lists/${listId}/shared_link.json`).then((response) => {
              localStorage.removeItem('redirect_to')
              navigate('/my_lists') 
            }).catch((error) => {
              console.log('could not accept shared list link', error)
            })
          } else {
            const redirectPath = localStorage.getItem('redirect_to')
            localStorage.removeItem('redirect_to')
            navigate(redirectPath)
          }
        }
        setLoggedIn(true)
      }
    } else {
      logOutUser()
    }
  }, [user])

  return (
    <div className="App">
      <ScrollToTop />
      <StartNavigation location={location} loggedIn={loggedIn} />
      <Container className="main-container">
        <Row>
          {loggedIn && <Col md={2} className="sidebar">
            <TopNavigation selection={selection} setSelection={setSelection} />
          </Col>}
          <Col className={loggedIn && user.trial_or_subscribed ? "sidecontent" : ''} md={loggedIn ? 10 : 12}>
            <div className="side-content">
              {loggedIn ?
                <MemberRoutes selection={selection} setSelection={setSelection}/>
              :
                <GuestRoutes  selection={selection} setSelection={setSelection}/>
              }
            </div>
            {!(!loggedIn && location.pathname === '/' && !user.access_token) && <Footer loggedIn={loggedIn} />}
          </Col>
        </Row>
      </Container>
      <CookieConsent />
      <MediaModal />
    </div>
  )
}
