import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader"

const override = css`
  display: block;
  margin: 0 auto;
  height: 20px;
`

export default function AcceptRejectList({ isAccept }) {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { code } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    var url = ''
    if(isAccept) {
      url = `/accept_list/${code}`
    } else {
      url = `/reject_list/${code}`
    }
    api_client().get(url).then(res => {
      setLoading(false)
      navigate('/my_lists')
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
      navigate('/my_lists')
    })
  }, [])

  return (
    <>
      {isAccept && <h4>Accepting shared list</h4>}
      {!isAccept && <h4>Rejecting shared list</h4>}
      <div className={`loading-container ${loading ? '' : 'd-none'}`}>
        <PropagateLoader color="#f1af3b" loading={loading} css={override} size={10} />
      </div>
    </>
  );
}
