export const DEVELOPMENT = false
export const API_URL = DEVELOPMENT ? 'http://192.168.0.119:3000/' : 'https://vuniverse-api.herokuapp.com/'
export const DEFAULT_EMAIL = 'help@vuniverse.com'

export const SET_USER = 'SET_USER'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_LIST_MODAL_DATA = 'SET_LIST_MODAL_DATA'
export const SET_RATING_MODAL_DATA = 'SET_RATING_MODAL_DATA'
export const SET_USER_MEDIUM_RATING_SAVED = 'SET_USER_MEDIUM_RATING_SAVED'
export const SET_USER_MEDIUM_HIDDEN_SAVED = 'SET_USER_MEDIUM_HIDDEN_SAVED'
export const SET_USER_LISTS_MEDIUM_SAVED = 'SET_USER_LISTS_MEDIUM_SAVED'
export const SET_MEDIA_MODAL_DATA = 'SET_MEDIA_MODAL_DATA'
export const SET_SHARE_MODAL_DATA = 'SET_SHARE_MODAL_DATA'
export const LAST_SIGNUP_STEP = 4
export const SET_RELOAD_USER = 'SET_RELOAD_USER'
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const TERMS_FILE = 'terms_v2.html'
export const PRIVACY_FILE = 'privacy_v2.html'


export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 32

export const RECAPTCHA_TOKEN = "recaptchaToken"
export const RECAPTCHA_KEY = DEVELOPMENT ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : "6LeYPq8mAAAAAI-Q76kb8KNRFNIRyZ_crMjLO_iE"