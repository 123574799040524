import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setSearchQuery } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'
import useHideMedium from './hooks/useHideMedium'

export default function HiddenList({ selection, setSelection }) {
  const initialSelection = 'settings'
  const [media, setMedia] = useState([])
  const [mediaByGenre, setMediaByGenre] = useState([])
  const [loading, setLoading] = useState(true)
  const [mediaLoaded, setMediaLoaded] = useState(false)
  const [genreLoaded, setGenreLoaded] = useState(false)
  const dispatch = useDispatch()
 
  const fetchMedia = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { selection: 'hidden' }
    api_client(access_token).get(`/api/v1/media`, { params: requestParams }).then(res => {
      setMedia(res.data.media)
      setMediaLoaded(true)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchMediaByGenre = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { grouped_genres: true, selection: 'hidden' }
    api_client(access_token).get(`/api/v1/media`, { params: requestParams }).then(res => {
      setMediaByGenre(res.data.by_genre)
      setGenreLoaded(true)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchAll = () => {
    fetchMedia()
    fetchMediaByGenre()
  }
  
  useHideMedium({
    fetchItems: fetchAll, 
    groupedGetSet: [
      [ mediaByGenre, setMediaByGenre ],
    ],
    normalGetSet: [
      [ media, setMedia ],
    ],
    isHiddenList: true
  })

  useEffect(() => {
    if(media.length < 1 && mediaByGenre.length < 1) {
      fetchAll()
    }
    dispatch(setSearchQuery(''))
  }, [])

  useEffect(() => {
    if(mediaLoaded && genreLoaded) {
      if(loading) setLoading(false)
    } else {
      if(!loading) setLoading(true)
    }
  }, [mediaLoaded, genreLoaded])

  useEffect(() => {
    if(selection !== initialSelection) setSelection(initialSelection)
  }, [])

  return (
    <>
      {media.length === 0 && mediaByGenre.length === 0 && !loading && <div className="no-items-text" style={{ marginTop: '50px'}}>You haven't hidden any items</div>}
      {(media.length > 0 || mediaByGenre.length > 0 || loading) && <MediaBrowser
        loading={loading}
        setSelection={setSelection}
        selection={selection}
        initialSelection={initialSelection}
        media={media}
        mediaReload={fetchAll}
        mediaByGenre={mediaByGenre}
      />}
    </>
  );
}
