import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default function MediumProviderSelector({
  medium_provider, isSelected, changeSelected
}) {
  const [active, setActive] = useState(isSelected)

  const rowClassValue = (isSelected) => {
    return "medium-provider-select" + (isSelected ? ' selected' : '')
  }

  const [containerClass, setContainerClass] = useState(rowClassValue(isSelected))

  const toggleMediumProvider = (newValue) => {
    setActive(newValue)
    setContainerClass(rowClassValue(newValue))
    changeSelected(medium_provider.id, newValue)
  }

  return (
    <Col md={6} className="medium-provider-column" onClick={() => { toggleMediumProvider(!active) }}>
      <div className={containerClass}>
        <img className="medium-provider-image" src={medium_provider.image} alt={medium_provider.name} />
      </div>
    </Col>
  )
}
