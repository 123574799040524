import React, { useState, useEffect } from 'react'
import './TopNavigation.scss'
import logo from '../../assets/images/vuniverse-logo-red.png'
import { BsCompass, BsGear, BsGrid, BsSearch, BsStar } from 'react-icons/bs'
import { BiLogOutCircle } from 'react-icons/bi'
import LeftMenuItem from './LeftMenuItem'

export default function TopNavigation({
  selection,
  setSelection
}) {
  return(
    <div className="left-navigation">
      <img src={logo} className="logo" alt="VUniverse" />
      <LeftMenuItem icon={<BsCompass/>} label="Discover" path="" current={selection === ''} setSelection={setSelection} />
      <LeftMenuItem icon={<BsGrid/>} label="My Lists" path="my_lists" current={selection === 'my_lists'} setSelection={setSelection} />
      <LeftMenuItem icon={<BsStar/>} label="Rating" path="rating" current={selection === 'rating'} setSelection={setSelection} />
      <LeftMenuItem icon={<BsSearch/>} label="Search" path="search" current={selection === 'search'} setSelection={setSelection} />
      <LeftMenuItem icon={<BsGear/>} label="Settings" path="settings" current={selection === 'settings'} setSelection={setSelection} />
      <LeftMenuItem icon={<BiLogOutCircle/>}  label="Sign out" path="logout" />
    </div>
  )
}
