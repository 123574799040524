import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedInUser } from '../../actions/user'
import { api_client } from '../../config/api-client'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import { useNavigate } from 'react-router-dom'
import './SubscriptionSuccess.scss'

export default function SubscriptionSuccess() {
  const user = useSelector(state => state.user)
  const [loggedIn, setLoggedIn] = useState(false)
  const [fromSignup, setFromSignup] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadUser = () => {
    const access_token = localStorage.getItem('access_token')

    if (access_token && access_token !== 'false') {
      api_client(access_token).get('/api/v1/user.json')
      .then((response) => {
        dispatch(setLoggedInUser(response.data.user, response.data.user.access_token))
      }).catch((error) => {
        localStorage.setItem('access_token', false)
        setLoggedIn(false)
      })
    } else {
      setLoggedIn(false)
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  useEffect(() => {
    if (user.access_token) {
      if(user.signup_step <= LAST_SIGNUP_STEP) {
        if(!fromSignup) setFromSignup(true)
      } else {
        if(fromSignup) setFromSignup(false)
      }
    }
  }, [user])

  const continueButton = () => {
    if(fromSignup) {
      navigate('/')
    } else {
      navigate('/')
    }
  }

  return(
    <div className="subscription">
      <h3>You have successfully subscribed!</h3>
      {user.access_token && fromSignup && <div>You can now finish your application by selecting the genres you like to watch.</div>}
      {user.access_token && fromSignup && <button type="button" className="btn" onClick={continueButton}>Select Genres</button>}

      {user.access_token && !fromSignup && <div>Go now to your dashboard and start discovering great movies and tv shows.</div>}
      {user.access_token && !fromSignup && <button type="button" className="btn" onClick={continueButton}>Explore now</button>}
    </div>
  )
}
