import { SET_MEDIA_MODAL_DATA } from "../config/constants.js"

const media_modal_data = (state = {}, action) => {
  switch (action.type) {
    case SET_MEDIA_MODAL_DATA:
      return action.media_modal_data
    default:
      return state
  }
}

export default media_modal_data
