import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setSearchQuery, setSearchType } from '../../actions/search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FormControl, Dropdown } from 'react-bootstrap'
import './SearchBox.scss'

export default function SearchBox() {
  const MOVIE_SHOW_LABEL = 'Movie / Show'
  const searchQuery = useSelector((state) => state.search)
  const [ query, setQuery ] = useState(searchQuery.query)
  const [ filterName, setFilterName ] = useState(MOVIE_SHOW_LABEL)
  const [ filter, setFilter ] = useState('media')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const changedSearch = (event) => {
    let fieldName = event.target.name
    let fieldValue = event.target.value
    setQuery(fieldValue)
  }

  const doSearch = (withFilter) => {
    const path = `/search?query=${encodeURIComponent(query)}&search_type=${withFilter}`
    navigate(path)
    dispatch(setSearchQuery(query))
    dispatch(setSearchType(filter))
  }

  useEffect(() => {
    setQuery(searchQuery.query)
    setFilter(searchQuery.search_type)
  }, [searchQuery])

  const changeFilter = (newValue) => {
    setFilter(newValue)
    doSearch(newValue)
  }

  useEffect(() => {
    switch(filter) {
      case 'media':
        setFilterName(MOVIE_SHOW_LABEL)
        break
      default:
        setFilterName('Cast & Crew')
        break
    }
  }, [filter])

  return(
    <div className="search-box">
      <FormControl
        type="search"
        placeholder="Search"
        className="mr-2"
        aria-label="Search"
        defaultValue={query}
        onChange={changedSearch}
        onKeyPress={event => { if (event.key === "Enter") { doSearch(filter) } }}
      />

      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {filterName} &nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#male" onClick={() => { changeFilter('media') }}>{MOVIE_SHOW_LABEL}</Dropdown.Item>
          <Dropdown.Item href="#female" onClick={() => { changeFilter('people') }}>Cast &amp; Crew</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
