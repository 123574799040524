import { useDispatch } from 'react-redux'
import { setShareModalData } from '../../actions/share_modal_data'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropagateLoader from "react-spinners/PropagateLoader"

export default function ListTitle({ title, selection, list, loading, linkEnabled }) {
  const dispatch = useDispatch()
  var ownerName = ''
  if(list && list.id && !list.is_owner && !list.staff_pick) {
    ownerName = `shared by ${list.owner_name}`
  }
  
  const shareAction = () => {
    dispatch(setShareModalData({ visible: true, user_list_id: list.id }))
  }

  const hasShareOption = () => {
    return selection === 'my_lists' && 
           title !== 'Watch Later' && 
           title !== 'Haven\'t Watched' && 
           (list.is_owner || list.can_share)
  }
  
  const listTitle = (isLink) => {
    return (
      <h5 style={{ display: 'flex', fontWeight: isLink ? 'bold' : ''}}>{title}{list && list.count_media ? ` (${list.count_media})` : ''} <span className='list-owner'>{ownerName}</span>
        {loading && <span><PropagateLoader 
          color="#f1af3b"
          loading={true}
          css={{ display: 'block', marginLeft: '90px', paddingTop: '12px'}}
          size={5} />
        </span>}
      </h5>
    )
  }

  return (
    <Row>
      <Col className='left-aligned'>
        {linkEnabled && list && list.id ? <Link to={`/my_lists/${list.id}`} style={{textDecoration: 'none'}}>
          {listTitle(true)}
        </Link> : listTitle()}
      </Col>
      {hasShareOption() && <Col className='right-aligned'>
        <div onClick={shareAction} className="share-button"><h5>Share List</h5></div>
      </Col>}
    </Row>
  )
}