import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Container, Form, Card } from 'react-bootstrap'

export default function ShortMediumDetail({ medium }) {
  return (
    <Container>
      <Row>
        <Col md={3} className="left-column">
          <Card.Img src={medium.poster_url} className="media-main-image" draggable={false} />
        </Col>
        <Col md={9}>
          <div className="details-container">
            {medium.release_year && <div>
              <span className="label">Details: </span>
              <span className="label-value">{medium.release_year}</span>
            </div>}
            {medium.genres && <>
              <span className="label">Genres: </span>
              <span className="label-value">{medium.genres}</span>
            <br/></>}
          </div>
          <div>
            <span><strong>Synopsis</strong></span>
            <p style={{marginTop: '10px'}}>{medium.overview}</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}