import { combineReducers } from 'redux'
import user from './user'
import search from './search'
import list_modal_data from './list_modal_data'
import rating_modal_data from './rating_modal_data'
import user_medium_rating from './user_medium_rating'
import user_medium_hidden from './user_medium_hidden'
import media_modal_data from './media_modal_data'
import user_lists_medium from './user_lists_medium'
import share_modal_data from './share_modal_data'
import notification from './notification'

export default combineReducers({
  user,
  search,
  list_modal_data,
  rating_modal_data,
  user_medium_rating,
  user_medium_hidden,
  media_modal_data,
  user_lists_medium,
  share_modal_data,
  notification
})
