import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CloseButton } from 'react-bootstrap'
import { setMediaModalData } from '../../actions/media_modal_data'
import { Modal } from 'react-bootstrap'
import Media from '../Media/Media'
import './MediaModal.scss'
import { useNavigate, useLocation } from 'react-router-dom'

export default function MediaModal() {
  const media_modal_data = useSelector((state) => state.media_modal_data)
  const [ medium, setMedium ] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClose = () => {
    const access_token = localStorage.getItem('access_token')
    if(access_token && access_token.length > 10) {
      const pathParts = location.pathname.split('/')
      if(pathParts[1] === 'people' || (pathParts[1] === 'my_lists') && pathParts.length == 5) {
        navigate(`/${pathParts[1]}/${pathParts[2]}${location.search}`)
      } else {
        navigate(`/${pathParts[1]}${location.search}`)
      }
    }
    dispatch(setMediaModalData({ visible: false, medium: {} }))
  }

  useEffect(() => {
    if(media_modal_data.medium && medium.id !== media_modal_data.medium.id) {
      setMedium(media_modal_data.medium)
    }
  }, [media_modal_data])

  return (
    <>
      <Modal
        show={media_modal_data.visible}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="media-modal"
      >
        <Modal.Body>
          <div className="modal-top">
            <CloseButton variant="white" onClick={handleClose} />
          </div>
          {media_modal_data.visible && medium.id && <Media medium={medium} handleClose={handleClose} />}
        </Modal.Body>
      </Modal>
    </>
  )
}
