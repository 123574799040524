import { Row, Col, Checkbox } from 'react-bootstrap'
import './ListModalUserList.scss'

export default function ListModalUserList({
  list, toggleUserList, checked
}) {
  return (
    <Row className="list-modal-user-list">
      <Col md={1}><input type="checkbox" onChange={(e) => { toggleUserList(list) } } checked={checked} /></Col>
      <Col md={11}>{list.name}</Col>
    </Row>
  )
}
