import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { api_client, errorHandler } from '../../config/api-client'
import LoginForm from '../Login/LoginForm'

const MemberRouteSaver = ({ }) => {
  const location = useLocation()

  const loadUser = () => {
    const access_token = localStorage.getItem('access_token')

    if (access_token && access_token !== 'false') {
      saveRoute()
      api_client(access_token).get('/api/v1/user.json')
      .then((response) => {
      }).catch((error) => {
        console.log('MemberRouteSaver error', error)
      })
    } else {
      saveRoute()
    }
  }

  const saveRoute = () => {
    const routePath = `${location.pathname}${location.search}`
    localStorage.setItem('redirect_to', routePath)
  }

  useEffect(() => {
    loadUser()
  }, [])

  return (<LoginForm/>)
}

export default MemberRouteSaver