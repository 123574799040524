import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { api_client } from '../../config/api-client'
import PasswordField from '../Login/PasswordField'

export default function PasswordConfirmationDialog({ visible, setVisible, onConfirm }) {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleClose = () => {
    setPassword('')
    setError('')
    setVisible(false)
  }

  const checkValidPassword = () => {
    setError('')
    api_client(localStorage.getItem('access_token'))
    .post('/api/v1/users/password_confirmation.json', { user: { password: password } }).then((response) => {
      onConfirm()
      handleClose()
    }).catch((e) => {
      if(e.response && e.response.data && e.response.data.message) {
        setError('Wrong password')
      } else {
        setError('Unknown error validating password')
      }
    })
  }

  return (
    <>
      <Modal show={visible} onHide={handleClose} backdrop="static">

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Please enter your password to confirm account deletion</Form.Label>
              <PasswordField
                placeholder="Type your password"
                value={password}
                setPassword={setPassword}
              />
            </Form.Group>
          </Form>
          {error && <div className='error'>{error}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={checkValidPassword}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
