import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLoggedInUser } from '../../actions/user'
import { api_client } from '../../config/api-client'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import './PublicPage.scss'

export default function DeleteAccountPage() {
  const [ deleted, setDeleted ] = useState(false)
  const user = useSelector(state => state.user)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    if (user.access_token && user.signup_step > LAST_SIGNUP_STEP) {
      if(!loggedIn) setLoggedIn(true)
    } else {
      if(loggedIn) setLoggedIn(false)
    }
  }, [user])

  return (
    <div>
      {(loggedIn || deleted) && <LoggedInPage user={user} deleted={deleted} setDeleted={setDeleted} />}
      {!(loggedIn || deleted) && <NotLoggedInPage />}
    </div>
  )
}

const NotLoggedInPage = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deletionSent, setDeletionSent] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(false)
    setLoading(true)
    api_client().post('/api/v1/user/delete_account.json', {
      user: {
        email: email,
      },
    }).then(() => {
      setLoading(false)
      setDeletionSent(true)
    }).catch((error) => {
      setLoading(false)
      if(error.response && error.response.data) {
        if(error.response.data.message) {
          setError(error.response.data.message)
        } else {
          setError(error.response.data.error)
        }
      } else {
        console.log(error.response)
      }
    })
  }

  return (
    <div className='public-page' style={{ marginTop: '3%', padding: '20px'}}>
      <h1>Account Deletion</h1>
      {!deletionSent && <div style={{ display: 'inline-block', maxWidth: '600px'}}>
        <div>
          After submitting, you will receive an email to confirm account deletion.
          <br />
          <br />
          <p>
            <span style={{color: 'red'}}>Warning: </span>
            This action is irreversible and will delete all your data from <a href="https://vuniverse.com">VUniverse</a> (web app), <a href="https://play.google.com/store/apps/details?id=com.vuniverse.VU">VU by VUniverse</a> (android app) 
            and <a href="https://apps.apple.com/us/app/vuniverse/id1480137466?ign-itscg=30200&ign-itsct=apps_box_badge">VUniverse</a> (iOS app).
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Email<br />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{width: '300px'}}
              disabled={loading}
            />
          </label>
          <br />
          {error && <div style={{color: 'red'}}>{error}</div>}
          <button type="submit" style={{marginTop: '10px'}} disabled={loading}>Submit</button>
        </form>
      </div>}
      {deletionSent && <div>
        <div style={{color: 'lightgreen'}}>Account deletion request successfully sent to {email}!</div>
        <div style={{marginTop: '15px'}}>Please check your email for a confirmation message and follow the instructions to complete the deletion process.</div>
      </div>}
    </div>
  )
}

const LoggedInPage = ({user, deleted, setDeleted}) => {
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const confirmDeletion = async (e) => {
    e.preventDefault()
    const shouldSubmit = window.confirm('Are you sure you want to delete your account? This cannot be undone!');

    if(!shouldSubmit) {
      return
    }

    setError(false)
    setLoading(true)

    api_client(localStorage.getItem('access_token')).post('/api/v1/users/destroy.json').then((response) => {
      dispatch(setLoggedInUser({}, ''))
      setLoading(false)
      setDeleted(true)
      navigate('/')
    }).catch((error) => {
      setLoading(false)
      if(error.response && error.response.data) {
        if(error.response.data.message) {
          setError(error.response.data.message)
        } else {
          setError(error.response.data.error)
        }
      } else {
        console.log(error.response)
      }
    })
  }

  return (
    <div className='public-page' style={{ marginTop: '3%', padding: '20px'}}>
      <h1>Account Deletion</h1>
      {!deleted && <div style={{ display: 'inline-block', maxWidth: '600px'}}>
        <p>
          <span style={{color: 'red'}}>Warning: </span>
          This action is irreversible and will delete all your data from <a href="https://vuniverse.com">VUniverse</a> (web app), <a href="https://play.google.com/store/apps/details?id=com.vuniverse.VU">VU by VUniverse</a> (android app) 
          and <a href="https://apps.apple.com/us/app/vuniverse/id1480137466?ign-itscg=30200&ign-itsct=apps_box_badge">VUniverse</a> (iOS app).
        </p>
        <p>
          Your account:<br />
          {user.email}
        </p>
        <form onSubmit={confirmDeletion}>
          {error && <div style={{color: 'red'}}>{error}</div>}
          <button type="submit" style={{marginTop: '10px'}} disabled={loading} data>Delete my account</button>
        </form>
      </div>}
      {deleted && <div>
        <div style={{color: 'lightgreen'}}>Account deleted successfully!</div>
      </div>}
    </div>
  )
}