import { Modal } from 'react-bootstrap'
import './TermsModal.scss'
import TermsPage from '../App/TermsPage'

export default function TermsModal({ visible, setVisible }) {

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="terms-modal"
      >
        <Modal.Body>

          <TermsPage />
        </Modal.Body>
      </Modal>
    </>
  )
}
