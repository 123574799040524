import { useState, useEffect } from 'react'
import { api_client, errorHandler } from '../../config/api-client'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Card, Col, Row, FloatingLabel, Form } from 'react-bootstrap'
import { setShareModalData } from '../../actions/share_modal_data'
import { setUserListsMediumSaved } from '../../actions/user_lists_medium'
import { Modal, Button } from 'react-bootstrap'
import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader"
import ShareOptions from '../Media/ShareOptions'
import ShareModalItem from './ShareModalItem'
import './ShareModal.scss'

const override = css`
  display: block;
  margin: 0 auto;
  height: 20px;
`

export default function ShareModal() {
  const share_modal_data = useSelector((state) => state.share_modal_data)
  const [ sharedUsers, setSharedUsers ] = useState([])
  const [ displayUsers, setDisplayUsers ] = useState([...sharedUsers])
  const [ userList, setUserList ] = useState({})
  const [ hasChanges, setHasChanges ] = useState(false)
  const [ addEnabled, setAddEnabled ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ shareAllowed, setShareAllowed ] = useState(true)
  const [ email, setEmail ] = useState('')
  const dispatch = useDispatch()

  const handleClose = () => {
    setEmail('')
    setSharedUsers([])
    setDisplayUsers([])
    setHasChanges(false)
    setAddEnabled(false)
    setShareAllowed(true)
    setUserList({})
    dispatch(setShareModalData({ visible: false, user_list: {} }))
  }

  const toggleDelete = (item, deleted) => {
    if(!hasChanges) setHasChanges(true)
    if(deleted) {
      setSharedUsers(sharedUsers.filter(i => i !== item))
    } else {
      addItem(item);
    }
  }

  const addItem = (toAdd) => {
    if(!hasChanges) setHasChanges(true)
    setSharedUsers([...sharedUsers, toAdd])
    if(displayUsers.filter(i => i.display_name === toAdd.display_name && i.id === toAdd.id).length === 0) {
      setDisplayUsers([...displayUsers, toAdd])
    }
  }

  const addIfNotExists = () => {
    if(sharedUsers.filter(i => i.display_name === email && i.id === 0).length > 0) {
      alert('E-mail already added to the list!')
    } else {
      addItem({id: 0, display_name: email, share_allowed: userList.is_owner && shareAllowed})
      setEmail('')
    }
  }

  const changeShareAllowed = (item) => {
    setHasChanges(true)
    var newArray = [];
    for(var i=0; i < sharedUsers.length; i++){
      var arrayItem = sharedUsers[i]
      if(arrayItem.display_name == item.display_name) {
        arrayItem.share_allowed = !arrayItem.share_allowed
      }
      newArray.push(arrayItem)
    }
    setSharedUsers(newArray)
  }

  useEffect(() => {
    let reg = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
      if(addEnabled) setAddEnabled(false)
    } else {
      if(sharedUsers.filter(i => i === email).length > 0) {
        if(addEnabled) setAddEnabled(false)
      } else {
        if(!addEnabled) setAddEnabled(true)
      }
    }
  }, [email])

  useEffect(() => {
    setHasChanges(false)
    setEmail('')
    if(share_modal_data && share_modal_data.visible && share_modal_data.user_list_id) {
      fetchList()
    }
  }, [share_modal_data])

  const fetchList = () => {
    setLoading(true)
    const access_token = localStorage.getItem('access_token')
    const userListId = share_modal_data.user_list_id
    if(userListId) {
      api_client(access_token).get(`/api/v1/user_lists/${userListId}`).then(res => {
        setUserList(res.data.user_list)
        setSharedUsers(res.data.user_list.share_items)
        setDisplayUsers(res.data.user_list.share_items)
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        errorHandler(error, dispatch)
      })
    }
  }

  const SharedList = ({items}) => {
    return (
      <div>
        <Container>
          {displayUsers.length > 0 && userList && <div style={{ marginTop: '10px'}}><b>Share <span style={{ color: '#f1af3b' }}>{userList.name}</span> with</b></div>}
        </Container>
        <Container className="share-list-container">
          {displayUsers.map((item, index) => <ShareModalItem
            toggleDelete={toggleDelete}
            changeShareAllowed={changeShareAllowed}
            item={item}
            key={item}
            is_owner={userList && userList.is_owner}
            deleted={sharedUsers.filter(i => i.display_name === item.display_name && i.id === item.id).length == 0}
          /> )}
        </Container>
      </div>
    )
  }

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      addIfNotExists()
    }
  }

  const saveChanges = () => {
    setLoading(true)
    const access_token = localStorage.getItem('access_token')
    const userListId = share_modal_data.user_list_id
    api_client(access_token).post(`/api/v1/user_lists/${userListId}/share`, { user_list: { share_items: sharedUsers } }).then(res => {
      setLoading(false)
      handleClose()
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  return (
    <>
      <Modal
        show={share_modal_data.visible}
        onHide={handleClose}
        backdrop="static"
        className="share-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>List Sharing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{ textAlign: 'center'}}>
              <div className={`loading-container ${loading ? '' : 'd-none'}`}>
                <PropagateLoader color="#f1af3b" loading={loading} css={override} size={10} />
              </div>
            </Row>
            <Card className="center-card transparent">
              <div className='form-container'>
                <div style={{ width: '74%'}}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="E-mail to invite"
                  >
                    <Form.Control type="email" placeholder="name@example.com" onKeyPress={onKeyPress} onChange={(e) => { setEmail(e.target.value) }} value={email} />
                  </FloatingLabel>
                </div>
                <div style={{ width: '25%', paddingLeft: '20px', paddingTop: '10px'}}>
                  <Button variant="rating" onClick={addIfNotExists} disabled={!addEnabled}>Add</Button>
                </div>
              </div>
              {userList && userList.is_owner && <div style={{ width: '100%', marginTop: '10px'}}>
                <input type="checkbox" onChange={(e) => { setShareAllowed(!shareAllowed) } } checked={shareAllowed} />
                <span className="checkbox-label">Allow to share your list</span>
              </div>}
            </Card>
          </Container>
          <SharedList />
          {userList && userList.share_code !== undefined && userList.share_code.length > 0 && <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <span>You can also share by link</span>
            <br />
            <ShareOptions userList={userList} urlPath={`/my_lists/share/${userList.share_code}`} />
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!hasChanges} onClick={saveChanges}>Save changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}