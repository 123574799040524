import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { api_client } from '../../config/api-client'
import { Container, Row, Col, Button } from 'react-bootstrap'
import PlanListItem from './PlanListItem'
import FreePlanListItem from './FreePlanListItem'
import './PlanList.scss'
import { Link } from 'react-router-dom'
import ConfirmDialog from '../Settings/ConfirmDialog'
import PasswordConfirmationDialog from '../Settings/PasswordConfirmationDialog'
import { setLoggedInUser } from '../../actions/user'
import logo from '../../assets/images/vuniverse-logo-red.png'

export default function PlanList({ onCancel, hideLogo }) {
  const [plans, setPlans] = useState([])
  const user = useSelector((state) => state.user)
  const [showingDeleteAccount, setShowingDeleteAccount] = useState(false)
  const [requestingPassword, setRequestingPassword] = useState(false)
  const [errors, setErrors] = useState({})
  const canListPayments = (user.subscription_processor !== 'apple' && user.subscription_processor !== 'google') || !user.trial_or_subscribed
  const dispatch = useDispatch()

  const fetchList = () => {
    api_client(localStorage.getItem('access_token')).get('/api/v1/plans.json').then((response) => {
      setPlans(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  const confirmDeleteAccount = () => {
    setShowingDeleteAccount(true)
  }

  const deleteAccount = () => {
    setErrors({})
    setShowingDeleteAccount(false)
    api_client(localStorage.getItem('access_token')).post('/api/v1/users/destroy.json').then((response) => {
      dispatch(setLoggedInUser({}, ''))
    }).catch((error) => {
      console.log(error)
      setErrors({ message: 'Unknown error trying to delete your account'})
    })
  }

  useEffect(() => {
    if (user.access_token) {
      fetchList()
    }
  }, [user.access_token])

  return(
    <Container className="plan-list">
      <ConfirmDialog
        visible={showingDeleteAccount}
        setVisible={setShowingDeleteAccount}
        title={"Delete Account"}
        prompt={(
          <span>
            Are you sure you want to delete your account? You'll lose all your data and this operation cannot be undone.
            If there's something we can do to keep you, please <a className="App-link" href="mailto:help@vuniverse.com?subject=Canceling my account">let us know</a> before you delete.
          </span>)
        }
        onConfirm={() => { setShowingDeleteAccount(false); setRequestingPassword(true) }}
        buttonLabel="Yes, please delete my account"
      />
      <PasswordConfirmationDialog
        visible={requestingPassword}
        setVisible={setRequestingPassword}
        onConfirm={deleteAccount}
      />

      {!hideLogo && <Row style={{ marginBottom: '10px'}}>
        <Link className='navbar-brand' to="/"><img src={logo} alt="VUniverse" /></Link>
      </Row>}
      <Row>
        <Col md={4}></Col>
        {!canListPayments && <Col md={4} className="shadowed-box">
          <Container>
            <Row>
              <SelectAPlanTitle />
              <div>
                {plans.filter(plan => plan.id === user.product_id).map((item, index) => (
                  <PlanListItem key={item.id} item={item} />
                ))}
              </div>
              {!canListPayments && user.subscription_processor === 'apple' && <div className="note">Subscription managed by Apple.</div>}
              {!canListPayments && user.subscription_processor === 'google' && <div className="note">Subscription managed by Google.</div>}
            </Row>
          </Container>
        </Col>}
        {canListPayments && <Col md={4} className="shadowed-box">
          <Container>
            <Row>
              <Col>
                {!user.trial_or_subscribed && user.has_creation_trial && <div className="subtitle" style={{color: '#f1af3b'}}>Please sign a plan to continue using VUniverse.</div>}
                <SelectAPlanTitle />
              </Col>
            </Row>
            <GroupedPlans plans={plans} current_user={user} />
            {onCancel && <Row style={{marginTop: '15px'}}>
              <Button variant="secondary" onClick={onCancel}>Back</Button>
            </Row>}
          </Container>
        </Col>}
        <Col md={4}></Col>
      </Row>
      {!user.trial_or_subscribed && <Row style={{ marginTop: '25px'}}>
        <Col md={4}></Col>
        <Col md={4}>
          {errors && errors.message && <div className='error'>{errors.message}</div>}
          <Button variant="danger" onClick={confirmDeleteAccount}>Delete Account</Button>
        </Col>
      </Row>}
    </Container>
  )
}

const SelectAPlanTitle = () => {
  return (
    <div>
      <h3>Select a plan</h3>
      <div className="subtitle"></div>
      <div className="subtitle2">Special Limited Time Offer</div>
    </div>
  )
}

const GroupedPlans = ({plans, current_user}) => {
  const topPlans = plans.filter(plan => plan.pricings[0].is_free)
  const otherPlans = plans.filter(plan => !plan.pricings[0].is_recurring && !plan.pricings[0].is_free)
  return (
    <div>
      {topPlans.map((item, index) => (
        <>
          {item.pricings[0].is_free && <FreePlanListItem key={item.id} item={item} current_user={current_user} />}
          {!item.pricings[0].is_free && <PlanListItem key={item.id} item={item} />}
        </>
      ))}
      {topPlans.length > 0 && <div className="subtitle3">Or Support The VU App by ..</div>}
      {otherPlans.map((item, index) => (
        <PlanListItem key={item.id} item={item} />
      ))}
    </div>
  )
}