
import ReactPixel from 'react-facebook-pixel'

export const configurePixel = () => {
  const advancedMatching = {} //{ em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  }
  ReactPixel.init('918536951849334', advancedMatching, options)
}