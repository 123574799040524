import { SET_SEARCH_QUERY, SET_SEARCH_TYPE } from "../config/constants.js"

const querySearch = (state = { query: '', search_type: 'media' }, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      return { ...state, query: action.query }
    case SET_SEARCH_TYPE:
      return { ...state, search_type: action.search_type }
    default:
      return state
  }
}

export default querySearch
