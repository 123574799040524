import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import { FiSend } from 'react-icons/fi'
import { setShareModalData } from '../../actions/share_modal_data'

export default function SuggestToShareListModal({ userLists, setUserLists }) {
  const dispatch = useDispatch()

  const handleClose = () => {
    setUserLists([])
  }
  
  const shareAction = (item) => {
    setUserLists(userLists.filter((i) => i.id !== item.id))
    dispatch(setShareModalData({ visible: true, user_list_id: item.id }))
  }

  return (
    <>
      <Modal
        show={userLists.length > 0}
        onHide={handleClose}
        backdrop="static"
        className="lists-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Your List With the World!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row className='align-items-center'>
              <Col style={{ paddingBottom: '10px'}}>
                Hey, great list. You know who else will love it? Your friends!
              </Col>
            </Row>
            {userLists.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((item, index) => 
              <Row className='align-items-center' style={{ marginTop: '10px', paddingTop: '10px', paddingBottom: '10px', marginLeft: '0px', marginRight: '0px', backgroundColor: '#30323f', borderRadius: '5px' }}>
                <Col md={10} style={{ paddingLeft: '15px' }}>{item.name}</Col>
                <Col md={2} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { shareAction(item) }}>
                  <FiSend size='1.3em' /><br />
                  <span style={{ color: '#ffffffaa', fontSize: '.8em' }}>Share</span>
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Maybe later</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

