import { apiClientWithToken } from '../../../config/api-client'
import { IoClose } from 'react-icons/io5'
import { BiShow } from 'react-icons/bi'

export default function HideShowButtons({ medium, mediaReload, selection, setCardVisible}) {
  if(!mediaReload) return <></>

  const hideMedia = () => {
    apiClientWithToken().post(`/api/v1/media/${medium.id}/user_medium_hiddens`, {}).then(_ => {
      setCardVisible(false)
    })
  }

  const undoHideMedia = () => {
    apiClientWithToken().post(`/api/v1/media/${medium.id}/user_medium_hiddens/remove`, {}).then(_ => {
      setCardVisible(false)
    })
  }

  return (
    <span onClick={(selection === 'settings' ? undoHideMedia : hideMedia)} className="hide-button">
      <div className="hide-container">
        {selection !== 'settings' && <IoClose className="hide-icon" style={{height: '20px', width: '20px', strokeWidth: "40"}} title="Hide"/>}
        {selection === 'settings' && <BiShow className="hide-icon" style={{height: '20px', width: '20px'}} title="Unhide"/>}
      </div>
    </span>
  )
}