import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setSearchQuery } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'
import MediaBrowserFilter from '../MediaBrowser/MediaBrowserFilter'
import './Tabs.scss'
import { Container } from "react-bootstrap"
import { setLoggedInUser } from '../../actions/user'
import UserInteractionModal from '../Media/UserInteractionModal'
import ReferralSourceModal from '../Signup/ReferralSourceModal'
import useHideMedium from './hooks/useHideMedium'
import useRefreshOnRating from './hooks/useRefreshOnRating'

var tabDiscoverTimeout = false
var recommendedTimeoutId = false
export default function TabDiscover({
  initialSelection,
  setSelection,
  selection,
  match
}) {
  const user = useSelector(state => state.user)
  const user_medium_rating = useSelector((state) => state.user_medium_rating)
  const [ allStreaming, setAllStreaming ] = useState(user.preferences.filter_all_streaming_services === 'true')
  const [ mediaType, setMediaType ] = useState(user.preferences.filter_media_type)
  const [ lastFilter, setLastFilter ] = useState({})

  const [media, setMedia] = useState([])
  const [mediaByGenre, setMediaByGenre] = useState([])
  const [recommendedMedia, setRecommendedMedia] = useState([])
  const [staffMedia, setStaffMedia] = useState([])
  const [loading, setLoading] = useState(true)
  const [staffLoaded, setStaffLoaded] = useState(false)
  const [genreLoaded, setGenreLoaded] = useState(false)
  const [recommendedLoaded, setRecommendedLoaded] = useState(false)
  const dispatch = useDispatch()

  const refreshRecommended = () => {
    clearTimeout(recommendedTimeoutId)
    recommendedTimeoutId = setTimeout(() => {
      fetchRecommendedMedia()
    }, 6000)
    if(recommendedLoaded) {
      setRecommendedLoaded(false)
    }
  }

  const addParams = (p) => {
    return {...p, all_streaming: allStreaming, media_type: mediaType}
  }

  const updateAllStreaming = () => {
    updatePreference('filter_all_streaming_services', allStreaming)
  }

  const updateMediaType = () => {
    updatePreference('filter_media_type', mediaType)
  }

  const updatePreference = (prefKey, prefValue) => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(
      '/api/v1/user_preferences/save',
      { user_preference: { key: prefKey, value: prefValue }}
    ).then(res => {
      const updatedUser = {...user, preferences: {...user.preferences, [prefKey]: prefValue.toString()}}
      dispatch(setLoggedInUser(updatedUser, access_token))
    }).catch((error) => {
      errorHandler(error, dispatch)
    })
  }

  const scheduleFetchAll = () => {
    if(tabDiscoverTimeout) {
      clearTimeout(tabDiscoverTimeout)
    }
    tabDiscoverTimeout = setTimeout(function() {
      fetchAll(true)
      tabDiscoverTimeout = false
    }.bind(this), 600)
  }

  const fetchStaffMedia = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({ only_staff: true })
    setStaffLoaded(false)
    api_client(access_token).get(`/api/v2/media`, { params: requestParams }).then(res => {
      const formatted = {...res.data.pagination, grouped_media: res.data.grouped_media }
      setStaffMedia(formatted)
      setStaffLoaded(true)
    }).catch((error) => {
      setLoading(false)
      setStaffLoaded(true)
      errorHandler(error, dispatch)
    })
  }

  const fetchMediaByGenre = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({ grouped_genres: true, selection: initialSelection })
    setGenreLoaded(false)
    api_client(access_token).get(`/api/v2/media`, { params: requestParams }).then(res => {
      const resData = res.data.grouped_media
      setMedia(resData[0].media)
      setMediaByGenre(resData.slice(1))
      setGenreLoaded(true)
    }).catch((error) => {
      setGenreLoaded(true)
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchRecommendedMedia = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({ selection: 'recommended' })
    setRecommendedLoaded(false)
    api_client(access_token).get(`/api/v2/media`, { params: requestParams }).then(res => {
      setRecommendedMedia(res.data.media)
      setRecommendedLoaded(true)
    }).catch((error) => {
      setLoading(false)
      setRecommendedLoaded(true)
      errorHandler(error, dispatch)
    })
  }

  const fetchAll = (fromTimeout) => {
    if(!fromTimeout) {
      scheduleFetchAll()
      return
    }
    setLastFilter(addParams({}))
    fetchMediaByGenre()
    fetchRecommendedMedia()
    fetchStaffMedia()
  }

  useHideMedium({fetchItems: fetchAll, 
    groupedGetSet: [
      [ mediaByGenre, setMediaByGenre ],
      [ staffMedia, setStaffMedia ]
    ],
    normalGetSet: [
      [ media, setMedia ],
      [ recommendedMedia, setRecommendedMedia ]
    ]
  })

  useRefreshOnRating({fetchItems: fetchAll, setLoading: setLoading,
    groupedGetSet: [
      [ mediaByGenre, setMediaByGenre ],
      [ staffMedia, setStaffMedia ]
    ],
    normalGetSet: [
      [ media, setMedia ],
      [ recommendedMedia, setRecommendedMedia ]
    ]
  })

  useEffect(() => {
    var newValue = user.preferences.filter_all_streaming_services === 'true'
    if(allStreaming !== newValue) {
      setAllStreaming(newValue)
    }
    newValue = user.preferences.filter_media_type
    if(mediaType !== newValue) {
      setMediaType(newValue)
    }
  },[user])

  useEffect(() => {
    if(user_medium_rating && user_medium_rating.medium_id) {
      const filtered = recommendedMedia.filter((m) => { return m.id !== user_medium_rating.medium_id })
      if(recommendedMedia.length !== filtered.length) {
        setRecommendedMedia(filtered)
        refreshRecommended()
      }
    }
  }, [user_medium_rating])

  useEffect(() => {
    if((recommendedMedia.length < 1 && mediaByGenre.length < 1) || lastFilter !== addParams({})) {
      fetchAll()
    }
    dispatch(setSearchQuery(''))
    if(selection !== initialSelection) setSelection(initialSelection)
    const user_all_streaming = user.preferences.filter_all_streaming_services === 'true'
    if(user_all_streaming !== allStreaming) {
      updateAllStreaming()
    }
    if(user.preferences.filter_media_type !== mediaType) {
      updateMediaType()
    }
  }, [allStreaming, mediaType])

  useEffect(() => {
    if(recommendedLoaded && staffLoaded && genreLoaded) {
      if(loading) setLoading(false)
    } else {
      if(!loading) setLoading(true)
    }
  }, [recommendedLoaded, staffLoaded, genreLoaded])

  return (
    <div className="tab-content">
      <UserInteractionModal />
      {user.pending_referral_source && <ReferralSourceModal />}
      <Container>
        <MediaBrowserFilter allStreaming={allStreaming} setAllStreaming={setAllStreaming} mediaType={mediaType} setMediaType={setMediaType} />
      </Container>
      <MediaBrowser recommendedLoaded={recommendedLoaded} loading={loading} setSelection={setSelection} userLists={staffMedia} selection={selection} initialSelection={''} media={media} mediaReload={fetchAll} mediaByGenre={mediaByGenre} recommendedMedia={recommendedMedia}/>
    </div>
  );
}
