import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import MediaBrowserFilter from '../MediaBrowser/MediaBrowserFilter'
import { Container } from "react-bootstrap"
import useMediaFilter from '../../hooks/useMediaFilter'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import PropagateLoader from "react-spinners/PropagateLoader"
import useHideMedium from '../App/hooks/useHideMedium'
import ListsModal from '../MediaBrowser/ListsModal'
import RatingModal from '../Rating/RatingModal'
import ShareModal from '../MediaBrowser/ShareModal'
import ListTitle from './ListTitle'
import Grid from './Grid'
import Autocomplete from './Autocomplete'
import './Show.scss'
import CollaborateFlag from './CollaborateFlag'
import SortTitlesFlag from './SortTitlesFlag'
 
var showListTimeout = false
var fetchMediaListTimeout = false

export default function Show() {
  const params = useParams()
  const [ userList, setUserList ] = useState({id: params && params.id ? params.id : 0 })
  const user_lists_medium = useSelector((state) => state.user_lists_medium)

  const [ pagination, setPagination ] = useState({})
  const [ media, setMedia ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ loadingMedia, setLoadingMedia ] = useState(true)
  const [ sortTitles, setSortTitles ] = useState(false)
  const dispatch = useDispatch()

  const scheduleFetchUserList = () => {
    if(showListTimeout) {
      clearTimeout(showListTimeout)
    }
    showListTimeout = setTimeout(function() {
      fetchUserList(true)
      showListTimeout = false
    }.bind(this), 1000)
  }

  const updateSort = (sorted) => {
    const newOrder = sorted.map((i) => i.id.toString())
    if(media.map((i) => i.id).join(',') !== newOrder.join(',')) {
      const access_token = localStorage.getItem('access_token')
      const beforeSort = [...media]
      setMedia(sorted)
      setLoading(true)
      api_client(access_token).put(`/api/v1/user_lists/${userList.id}`, {
        user_list: { sorted_medium_ids: keepNotLoadedIds(newOrder) } 
      }).then(res => {
        setUserList(res.data.user_list)
        setLoading(false)
      }).catch((error) => {
        console.log(error)
        setMedia(beforeSort)
        setLoading(false)
        errorHandler(error, dispatch)
      })
    }
  }

  const keepNotLoadedIds = (newOrder) => {
    var previousIds = []
    if(userList.sorted_medium_ids) {
      previousIds = userList.sorted_medium_ids.split(',')
    }
    previousIds = previousIds.filter((i) => !newOrder.includes(i))
    return [...newOrder, ...previousIds].join(',')
  }

  const scheduleFetchMedia = (loadMore) => {
    if(fetchMediaListTimeout) {
      clearTimeout(fetchMediaListTimeout)
    }
    fetchMediaListTimeout = setTimeout(function() {
      fetchMedia(true, loadMore)
      fetchMediaListTimeout = false
    }.bind(this), 1000)
  }

  const fetchUserList = async (fromTimeout) => {
    if(!fromTimeout) {
      scheduleFetchUserList()
      return
    }

    setLoading(true)
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({ id: userList.id, simple: true })
    setLastFilter(requestParams)
    api_client(access_token).get(`/api/v1/user_lists/${userList.id}`, { params: requestParams }).then(res => {
      setUserList(res.data.user_list)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchMedia = async (fromTimeout, loadMore) => {
    if(!fromTimeout) {
      scheduleFetchMedia(loadMore)
      return
    }

    var page = 1
    if(pagination.current_page && loadMore) {
      page = pagination.current_page + 1
    }
    setLoadingMedia(true)
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({selection: 'single_list', page: page, id: userList.id })
    api_client(access_token).get('/api/v2/media', { params: requestParams }).then(res => {
      setPagination(res.data.pagination)
      if(page == 1) {
        setMedia(res.data.media)
      } else if(page > 1) {
        setMedia([...media, ...res.data.media])
      }
      setLoadingMedia(false)
    }).catch((error) => {
      setLoadingMedia(false)
      errorHandler(error, dispatch)
    })
  }

  const { addParams, allStreaming, setAllStreaming, mediaType, setMediaType, setLastFilter } = useMediaFilter(fetchMedia)
  useHideMedium({fetchItems: fetchMedia, normalGetSet: [[media, setMedia]]})

  useEffect(() => {
    fetchUserList()
  }, [])

  useEffect(() => {
    if(user_lists_medium && user_lists_medium.removed_list_ids) {
      if(user_lists_medium.removed_list_ids.includes(userList.id)) {
        setMedia(media.filter(medium => medium.id !== user_lists_medium.medium.id))
      }
    }
  }, [user_lists_medium])


  const loadMore = () => {
    fetchMedia(false, true)
  }

  const addToMedia = (medium) => {
    const toAdd = {...medium, has_list: true}
    const merged = [...[toAdd], ...media]
    setMedia(merged)
  }

  const removeFromMedia = (medium) => {
    setMedia(media.filter(m => m.id !== medium.id))
  }

  return (
    <div className="tab-content list-show">
      <Container>
        <MediaBrowserFilter allStreaming={allStreaming} setAllStreaming={setAllStreaming} mediaType={mediaType} setMediaType={setMediaType} />
      </Container>
      {userList.can_edit && <Container className='search-container'>
        <Autocomplete userListId={userList.id} addToMedia={addToMedia} removeFromMedia={removeFromMedia} />
      </Container>}
      <Container>
        <div className={`loading-container ${loading ? '' : 'd-none'}`}>
          <PropagateLoader color="#f1af3b" loading={loading || loadingMedia} css={{display: 'block', margin: '0 auto'}} size={10} />
        </div>
        <CollaborateFlag userList={userList} />
        {userList.can_edit && <SortTitlesFlag sortTitles={sortTitles} setSortTitles={setSortTitles} />}
        {userList && userList.name && <ListTitle
          list={{...userList, count_media: pagination.total_count - media.filter(medium => medium.hidden === true ).length}}
          title={userList.name}
          loading={false}
          selection="my_lists"
        />}
        <Grid
          media={media}
          loadMore={loadMore}
          pagination={pagination}
          canEdit={userList.can_edit}
          isOwner={userList.is_owner}
          fetchMedia={fetchMedia}
          updateSort={updateSort}
          sortTitles={sortTitles}
          />
      </Container>
      <div style={{ clear: 'both'}} />
      <ListsModal />
      <RatingModal />
      <ShareModal />
    </div>
  )
}
