import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from '../../config/constants'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

export default function PasswordField({ password, onKeyPress, setPassword, placeholder }) {
  const [ showPassword, setShowPassword ] = useState(false)

  return (
    <div style={{ position: 'relative', height: '30px'}}>
      <Form.Control
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        value={password}
        onKeyPress={onKeyPress}
        maxLength={`${MAX_PASSWORD_LENGTH}`}
        onChange={(event) => { event.preventDefault(); setPassword(event.target.value) }}
        style={{position: 'absolute', fontSize: '1rem', width: '100%', height: 'auto', lineHeight: '1.5rem'}}
        />
      <div onClick={() => { setShowPassword(!showPassword)}} style={{ position: 'absolute', zIndex: 5, textAlign: 'right', right: '5px', paddingTop: '4px'}}>
        {showPassword && <AiFillEyeInvisible size={28} color="black" />}
        {!showPassword && <AiFillEye size={28} color="black" />}
      </div>
    </div>
  )
}