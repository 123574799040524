import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/vuniverse-logo-red.png'
import './TopNavigation.scss'
import { useSelector } from 'react-redux'
import { LAST_SIGNUP_STEP } from '../../config/constants'

export default function StartNavigation({ location, loggedIn }) {
  const user = useSelector(state => state.user)
  const [ canShowTopMenu, setCanShowTopMenu ] = React.useState(false)

  React.useEffect(() => {
    const notLoggedAndInHome = !loggedIn && location && location.pathname === '/'
    const loggedWithNoAccess = user.access_token && !user.trial_or_subscribed
    setCanShowTopMenu(
      !(user && user.trial_or_subscribed) && (!notLoggedAndInHome || loggedWithNoAccess)
    )
  }, [user, location])

  return(
    <div>
      {canShowTopMenu && <Navbar expand="lg" className='main-navbar'>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Link className='navbar-brand' to="/"><img src={logo} alt="VUniverse" /></Link>
            {!user || !user.access_token && <Link className='nav-link' to="/login">Login</Link>}
            {user && user.access_token && <Link className='nav-link' to="/logout">Logout</Link>}
            {!user || !user.access_token && <Link className='nav-link' to="/register">Register</Link>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>}
    </div>
  )
}
