import { useState, useEffect, useRef } from 'react'
import { Container, Card } from 'react-bootstrap'
import { Modal, Button, Form, FormControl } from 'react-bootstrap'
import { api_client } from '../../config/api-client'

export default function TagSelector({ selectedTags, onTagsChanged, onSelectorClosed }) {
  const [ tags, setTags ] = useState([])
  const [ newSelection, setNewSelection ] = useState(selectedTags)
  const [ query, setQuery ] = useState('')
  const searchInputRef = useRef(null)

  const fetchTags = () => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).get(`/api/v1/system_medium_tags`, { params: {
      query: query
    }}).then(res => {
      setTags(res.data.system_medium_tags)
    })
  }

  const changedSearch = (event) => {
    let fieldValue = event.target.value
    setQuery(fieldValue)
  }

  const isChecked = (tag) => {
    return newSelection.some(item => tag === item);
  }

  const onCheckedChanged = (event, tag) => {
    const newValue = event.target.checked
    if(newValue) {
      setNewSelection([ ...newSelection, tag ])
    } else {
      setNewSelection(newSelection.filter((item) => { 
        return item !== tag
      }))
    }
  }

  const confirmSelection = () => {
    onTagsChanged(newSelection)
    onSelectorClosed()
  }

  useEffect(() => {
    fetchTags()
    searchInputRef.current.focus()
  }, [])

  return (
    <>
      <Modal.Header>
        <span className="back-btn" onClick={onSelectorClosed}>Back</span>
        <Modal.Title>Select My Tags</Modal.Title>
        <span className="clear-tags" onClick={() => { setNewSelection([]) }}>Clear</span>
      </Modal.Header>

      <Modal.Body>
        <FormControl
          type="search"
          ref={searchInputRef}
          placeholder="Search"
          className="mr-2"
          aria-label="Search"
          defaultValue={query}
          onChange={changedSearch}
          onKeyPress={event => { if (event.key === "Enter") { fetchTags() } }}
        />
        <Container className="tags-list">
          {tags.map((tag, index) => <Form.Group key={tag.id} className="mb-3" controlId="formBasicCheckbox">
            <Form.Check checked={isChecked(tag.tag)} onChange={(event) => { onCheckedChanged(event, tag.tag)}} type="checkbox" label={tag.tag} />
          </Form.Group>)}
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="rating" onClick={confirmSelection}>Confirm</Button>
      </Modal.Footer>
    </>
  )
}
