import { Form } from "react-bootstrap"

export default function SortTitlesFlag({ sortTitles, setSortTitles }) {
  return (
    <div className="filter-row">
      <span className="check-label">Sort Titles</span>
      <Form.Check
        className="check-field custom-switch"
        type="switch"
        id="sort-titles-switch"
        checked={sortTitles}
        onChange={() => setSortTitles(!sortTitles)}
      />
    </div>
  )
}