import { useEffect } from 'react'
import { Tab, Tabs } from "react-bootstrap"
import './TabSettings.scss'
import Options from '../Settings/Options'
import Feedback from '../Settings/Feedback'

export default function TabSettings({
  initialSelection,
  setSelection,
  selection,
  match
}) {

  useEffect(() => {
    setSelection(initialSelection)
  }, [])

  return (
    <>
      <Tabs defaultActiveKey="options" id="uncontrolled-tab-example" className="mb-3 tab-settings">
        <Tab eventKey="options" title="Options">
          <Options />
        </Tab>
        <Tab eventKey="feedback" title="Feedback">
          <Feedback />
        </Tab>
      </Tabs>
    </>
  );
}
