import { Container, Col, Row } from 'react-bootstrap'
import './Pages.scss'

export default function GetTheApp() {
  return (
    <Container className="page-container">
      <Row>
        <h1>Get The App</h1>
      </Row>
      <Row style={{ marginTop: '40px'}}>
        <Col md={1}></Col>
        <Col md={10}>
          <a className="android-app-link" href="https://play.google.com/store/apps/details?id=com.vuniverse.VUniverse&amp;hl=en_US" target="_blank" rel="noopener" />
          <a className="ios-app-link"  href="https://apps.apple.com/us/app/vuniverse/id1480137466" target="_blank" rel="noopener" />
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  )
}