import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

export default function UserMediumGenreSelector({
  user_medium_genre, isSelected, changeSelected
}) {
  const [active, setActive] = useState(isSelected);

  const toggleUserMediumGenre = (event) => {
    setActive(event.target.checked)
    changeSelected(user_medium_genre.id, event.target.checked)
  }

  return (
    <Row className="medium-genre-select">
      <Col md={10} className="left-aligned">
        {user_medium_genre.medium_genre.title}
      </Col>
      <Col md={2} className="right-aligned">
        <input type="checkbox" name={`userMediumGenres[${user_medium_genre.id}]`} value="1" checked={active} onChange={toggleUserMediumGenre} />
      </Col>
    </Row>
  )
}
