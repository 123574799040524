import axios from "axios";
import { API_URL } from './constants'
import { setReloadUser } from '../actions/user'

export const api_client = (accessToken) => {
  if(accessToken) {
    return axios.create({
      baseURL: API_URL,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        'accepts': 'json',
        'Authentication': `Bearer ${accessToken}`
      }
    })
  } else {
    return axios.create({
      baseURL: API_URL,
      timeout: 30000
    });
  }
}

export const apiClientWithToken = () => {
  return api_client(localStorage.getItem('access_token'))
}

export const errorHandler = (error, dispatch) => {
  if(error && error.response && (error.response.status === 401 || error.response.status === 403)) {
    if(window.location.pathname !== '/') {
      localStorage.removeItem('access_token')
      window.location.href = "/"
    }
    dispatch(setReloadUser(true))
  }
}