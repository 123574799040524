import React, { useState, useEffect } from 'react'
import { setNotification } from '../../actions/notification'
import { setRatingModalData } from '../../actions/rating_modal_data'
import { useSelector, useDispatch } from 'react-redux'
import { api_client, errorHandler } from '../../config/api-client'
import { Modal, Button, Row, Col, Container, Form, Card } from 'react-bootstrap'
import './UserInteractionModal.scss'
import ShortMediumDetail from './ShortMediumDetail'
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,
         TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon,
         LinkedinShareButton, LinkedinIcon } from 'react-share'

export default function UserInteractionModal({}) {
  const dispatch = useDispatch()
  const notification = useSelector((state) => state.notification)
  const [ userInteraction, setUserInteraction ] = useState(false)
  const [ error, setError ] = useState(false)
  
  const [loading, setLoading] = useState(false)
  const [ visible, setVisible ] = useState(false)
  const [shareUrl, setShareUrl] = useState('')
  const [shareSubject, setShareSubject] = useState('')
  const [shareBody, setShareBody] = useState("")
  const [ sharing, setSharing ] = useState(false)

  useEffect(() => {
    if(notification.user_interaction) {
      setUserInteraction(false)
      fetchRecord()
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [notification])

  useEffect(() => {
    if(userInteraction && userInteraction.medium) {
      setShareUrl(window.location.protocol + '//' + window.location.hostname + `/discover/media/${userInteraction.medium_id}`)
      setShareSubject(`A suggestion for you to check out ${userInteraction.medium.title}`)
      setShareBody(`I would like to recommend this movie/tv show. You can check the streaming providers from VUniverse`)
    }
  }, [userInteraction])
  
  const handleClose = () => {
    dispatch(setNotification({}))
    setVisible(false)
  }

  const fetchRecord = () => {
    if(!loading) {
      setLoading(true)
    }
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).get(`/api/v1/user_interactions/${notification.id}`).then(res => {
      setUserInteraction(res.data.user_interaction)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const updateUserInteraction = (answerValue) => {
    if(!loading) {
      setLoading(true)
    }
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).put(`/api/v1/user_interactions/${userInteraction.id}`, {
      user_interaction: {
        provider_medium_id: userInteraction.provider_medium_id,
        answer: answerValue
      }}).then(res => {
        if(answerValue === 1) {
          userRating(userInteraction.medium)
        }
        setLoading(false)
        handleClose()
    }).catch((error) => {
      console.log(error)
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const shareIt = (medium) => {
    setSharing(true)
  }
  
  const userRating = (medium) => {
    dispatch(setRatingModalData({ visible: true, medium: medium }))
  }

  return (
    <>
      <Modal
        show={visible}
        onHide={() => updateUserInteraction(2)}
        backdrop="static"
        className="user-interaction-modal"
      >
        <Modal.Header closeButton>
          {userInteraction && <Modal.Title>How much did you like this title?</Modal.Title>}
        </Modal.Header>

        <Modal.Body>
        {userInteraction && <h4 style={{textAlign: 'center', marginBottom: '10px', color: 'white'}}>{userInteraction.medium.title}</h4>}
        {userInteraction && <ShortMediumDetail medium={userInteraction.medium} />}
        {error && <span style={{ color: 'red'}}>{error}</span>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => { updateUserInteraction(0) }}>didn’t watch it</Button>
          <Button variant="warning" onClick={() => { updateUserInteraction(1) }}>ready to rate it</Button>
          {!sharing && <Button variant="secondary" onClick={shareIt}>share it with others</Button>}
          <Button variant="primary" onClick={() => { updateUserInteraction(2) }}>do nothing</Button>
          {sharing && <Container>
            <Row>
              <Col md={7}></Col>
              <Col md={5} style={{ textAlign: 'right' }}>
                <span>Share options</span>
              </Col>
            </Row>
            <Row>
              <Col md={7}></Col>
              <Col md={5} style={{ marginTop: '10px', textAlign: 'right' }}>
                <EmailShareButton
                  subject={shareSubject}
                  url={shareUrl}
                  body={shareBody}
                  separator=" "
                  className="share-icon"
                  >
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <span>&nbsp;&nbsp;</span>
                <FacebookShareButton
                  url={shareUrl}
                  quote={shareBody}
                  separator=" "
                  className="share-icon"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <span>&nbsp;&nbsp;</span>
                <TwitterShareButton
                  url={shareUrl}
                  title={shareSubject}
                  className="share-icon"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <span>&nbsp;&nbsp;</span>
                <WhatsappShareButton
                  url={shareUrl}
                  title={shareSubject}
                  separator=":: "
                  className="share-icon"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <span>&nbsp;&nbsp;</span>
                <LinkedinShareButton
                  url={shareUrl}
                  className="share-icon">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Col>
            </Row>
          </Container>}
        </Modal.Footer>
      </Modal>
    </>
  )
}

