import { SET_USER_MEDIUM_RATING_SAVED } from "../config/constants.js"

const user_medium_rating = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_MEDIUM_RATING_SAVED:
      return action.user_medium_rating
    default:
      return state
  }
}

export default user_medium_rating
