import { SET_USER, SET_RELOAD_USER } from "../config/constants.js";

const user = (state = { reload: false }, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.user, reload: false }
    case SET_RELOAD_USER:
      return { ...state, reload: action.reload }
    default:
      return state
  }
}

export default user
