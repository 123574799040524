import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Button } from "react-bootstrap"
import MediaCarousel from './MediaCarousel'
import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader"
import InfiniteScroll from 'react-infinite-scroll-component'
const override = css`
  display: block;
  margin: 0 auto;
  height: 20px;
  margin-top: 15px;
`

export default function ListMediaBrowser({
  userLists,
  selection,
  location,
  mediaReload,
}) {
  const [ loading, setLoading ] = useState(false)
  const dispatch = useDispatch()

  const acceptList = (list) => {
    setLoading(true)
    api_client().get(`/accept_list/${list.code}`).then(res => {
      setLoading(false)
      mediaReload()
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const rejectList = (list) => {
    setLoading(true)
    api_client().get(`/reject_list/${list.code}`).then(res => {
      setLoading(false)
      mediaReload()
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const loadMore = () => {
    mediaReload(false, true)
  }

  return (
    <>
      {userLists && userLists.grouped_media && userLists.grouped_media.map((list, index) => {
        if(list.pending_accept && !list.staff_pick) {
          return (
            <div key={list.id}>The user <b>{list.owner_name}</b> wants to share his list <b>{list.name}</b> with you, do you want to <span onClick={() => acceptList(list)} style={{color: 'lightgreen', cursor: 'pointer'}}>accept</span> or <span onClick={() => rejectList(list)} style={{color: 'red', cursor: 'pointer'}}>reject</span>?</div>
          )
        } else {
          return <span key={0 - index}></span>
        }
      })}
      {userLists && userLists.grouped_media && <InfiniteScroll
        dataLength={userLists.grouped_media.length} //This is important field to render the next data
        next={loadMore}
        hasMore={userLists.has_more}
        loader={<PropagateLoader color="#f1af3b" loading={true} css={override} size={10} />}
        endMessage={
          <p>
          </p>
        }
      >
        {userLists.grouped_media.map((list, index) => {
          if((!list.media || list.media.length === 0) && (list.staff_pick || list.pending_accept)) {
            return <span key={0 - index}></span>
          } else {
            return <MediaCarousel
              selection={selection}
              key={list.id} list={list}
              media={list.media.filter(medium => medium.hidden !== true )}
              countHidden={list.media.filter(medium => medium.hidden === true ).length}
              location={location}
              mediaReload={mediaReload}
              title={`${list.name}${list.staff_pick ? ' (Staff Picked)' : ''}`}
            />
          }
        })}
      </InfiniteScroll>}
    </>
  )
}