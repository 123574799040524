import { Container, Col, Row, Button } from 'react-bootstrap'
import './Pages.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function About() {
  const user = useSelector(state => state.user)

  return (
    <Container className="page-container">
      <Row>
        <h1>About VUniverse</h1>
      </Row>
      <Row style={{ marginTop: '40px'}}>
        <Col md={1}></Col>
        <Col md={10} className="cards-about">
          <ul>
            <li>
              <h2>20</h2>
              <h3>MINUTES</h3>
              <p>The average time to find a movie or show to watch.</p>
            </li>
            <li>
              <h2>120</h2>
              <h3>DOLLARS</h3>
              <p>The average monthly spend on services &amp; rentals.</p>
            </li>
            <li>
              <h2>5+</h2>
              <h3>SERVICES</h3>
              <p>The average number of streaming services per household.</p>
            </li>
            <li>
              <h2>150</h2>
              <h3>OPTIONS</h3>
              <p>The number of streaming services (and growing).</p>
            </li>
          </ul>
        </Col>
        <Col md={1}></Col>
      </Row>
      <Row className="footer-summary">
          <div>
            <p>With a practically endless number of movies and TV shows available across streaming services, we created VU with one goal in mind: to answer the timeless question, what should I watch?</p>
            <p>Scrolling through every streaming service is exhausting. It’s frustrating and a waste of good time. VU’s algorithm works for you to recommend programming and where to find it.</p>
            <p>VU by VUniverse is a personalized movie and show recommendation platform to browse streaming services in one app—a channel guide for the streaming universe. VU creates a unique profile for every user and serves smart watchlists using mood, genre, and tags based on what you like. Users can also create custom watchlists to stay organized and keep track of what they want to watch. Not sure whether to keep or subscribe to a particular provider? Keep track in VU and make informed and well-timed decisions.</p>
          </div>
      </Row>
      <Row>
        {!user.access_token && <Link to="/register"><Button>Get Started</Button></Link>}
      </Row>
    </Container>
  )
}