import { ListGroup, Container, Row, Col, Dropdown, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import MediumProviderModal from './MediumProviderModal'
import GenreModal from './GenreModal'
import NotificationsModal from './NotificationsModal'
import { setLoggedInUser } from '../../actions/user'
import { useNavigate } from 'react-router-dom'
import './SettingTab.scss'
import PlanList from '../Payment/PlanList'
import ConfirmDialog from './ConfirmDialog'
import PasswordConfirmationDialog from './PasswordConfirmationDialog'
import AccountEditor from './AccountEditor'

export default function Options({}) {
  const MAX_PROVIDERS_TO_SHOW = 4
  const MAX_GENRES_TO_SHOW = 4
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [mediumProviders, setMediumProviders] = useState([])
  const [showingMediumProviders, setShowingMediumProviders] = useState(false)
  const [genres, setGenres] = useState([])
  const [showingGenres, setShowingGenres] = useState(false)
  const [showingNotifications, setShowingNotifications] = useState(false)
  const [criticalRatingSource, setCriticalRatingSource] = useState(user.critical_rating_source)
  const [criticalRatingSourceName, setCriticalRatingSourceName] = useState('')
  const [addTagsWhileRating, setAddTagsWhileRating] = useState(user.add_tags_while_rating)
  const [unavailableTitles, setUnavailableTitles] = useState(user.unavailable_titles)
  const [showingPlans, setShowingPlans] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false)
  const [editingAccount, setEditingAccount] = useState(false)
  const [showingConfirmation, setShowingConfirmation] = useState(false)
  const [requestingPassword, setRequestingPassword] = useState(false)
  const [showingDeleteAccount, setShowingDeleteAccount] = useState(false)
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(user.subscription_canceled)

  const fetchGenres = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).get('/api/v1/user_medium_genres').then((response) => {
      const userMediumGenres = response.data.user_medium_genres.sort((a, b) => a.medium_genre.title.localeCompare(b.medium_genre.title))
      const activeGenres = []
      var moreGenres = 0
      for(var i = 0; i < userMediumGenres.length; i++) {
        if(userMediumGenres[i].active) {
          if(activeGenres.length === MAX_GENRES_TO_SHOW) {
            moreGenres += 1
          } else {
            activeGenres.push(userMediumGenres[i].medium_genre.title)
          }
        }
      }
      if(moreGenres > 0) {
        activeGenres.push(`+${moreGenres} more`)
      }
      setGenres(activeGenres)
    })
  }

  const fetchMediumProviders = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).get('/api/v1/user_medium_providers').then((response) => {
      const selectedMediumProviders = []
      const mp = response.data.medium_providers
      const userMediumProviderIds = response.data.user_medium_provider_ids
      for(var i = 0; i < mp.length; i++) {
        if(userMediumProviderIds.some(id => id === mp[i].id)) {
          selectedMediumProviders.push(mp[i].name)
          if(selectedMediumProviders.length === MAX_PROVIDERS_TO_SHOW) {
            if(userMediumProviderIds.length > MAX_PROVIDERS_TO_SHOW) {
              const diff = userMediumProviderIds.length - MAX_PROVIDERS_TO_SHOW
              selectedMediumProviders.push(`+${diff} more`)
            }
            break
          }
        }
      }
      setMediumProviders(selectedMediumProviders)
    })
  }

  useEffect(() => {
    if(showingMediumProviders === false) {
      fetchMediumProviders()
    }
  }, [showingMediumProviders])

  useEffect(() => {
    if(showingGenres === false) {
      fetchGenres()
    }
  }, [showingGenres])

  useEffect(() => {
    switch(criticalRatingSource) {
      case 'metacritic':
        setCriticalRatingSourceName('Metacritic')
        break
      default:
        setCriticalRatingSourceName('None')
        break
    }

    if(user.critical_rating_source !== criticalRatingSource) {
      updateUser()
    }

  }, [criticalRatingSource])

  useEffect(() => {
    if(user.add_tags_while_rating !== addTagsWhileRating) {
      updateUser()
    }
  }, [addTagsWhileRating])

  useEffect(() => {
    if(user.unavailable_titles !== unavailableTitles) {
      updateUser()
    }
  }, [unavailableTitles])

  const updateUser = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).put('/api/v1/user.json', {
      user: {
        critical_rating_source: criticalRatingSource,
        add_tags_while_rating: addTagsWhileRating,
        unavailable_titles: unavailableTitles,
      }
    }).then((response) => {
      const responseUser = response.data.user
      dispatch(setLoggedInUser(responseUser, responseUser.access_token))
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to update user' })
      }
    })
  }

  const resetPassword = () => {
    setErrors({})
    setResetPasswordRequested(false)
    api_client().post('/users/password.json', {
      user: {
        email: user.email
      }
    }).then((response) => {
      if(response.status === 201) {
        setResetPasswordRequested(true)
      }
    }).catch((error) => {
      console.log(error.response.status)
      if(error.response) {
        if(error.response.status === 406) {
          setResetPasswordRequested(true)
        } else if(error.response.data.errors) {
          setErrors(error.response.data.errors)
        } else if(error.response.data.error) {
          setErrors({ user: error.response.data.message })
        } else {
          setErrors({ user: 'Unknown error trying to request password reset' })
        }
      } else {
        setErrors({ user: 'Unknown error trying to request password reset' })
      }
    })
  }

  const confirmDeleteAccount = () => {
    setShowingDeleteAccount(true)
  }

  const deleteAccount = () => {
    setErrors({})
    setShowingDeleteAccount(false)
    api_client(localStorage.getItem('access_token')).post('/api/v1/users/destroy.json').then((response) => {
      dispatch(setLoggedInUser({}, ''))
    }).catch((error) => {
      console.log(error)
      setErrors({ message: 'Unknown error trying to delete your account'})
    })
  }

  const confirmCancelSubscription = () => {
    if(!subscriptionCanceled) {
      setShowingConfirmation(true)
    }
  }

  const cancelSubscription = () => {
    setErrors({})
    setShowingConfirmation(false)
    api_client(localStorage.getItem('access_token')).post('/api/v1/plans/cancel.json').then((response) => {
      if(response.data.canceled) {
        setSubscriptionCanceled(true)
      } else {
        setErrors({ message: 'Unknown error trying to cancel subscription'})
      }
    }).catch((error) => {
      console.log(error)
      setErrors({ message: 'Unknown error trying to cancel subscription'})
    })
  }

  useEffect(() => {
    if((editingAccount || showingPlans) && resetPasswordRequested) {
      setResetPasswordRequested(false)
    }
  }, [editingAccount, showingPlans])

  return (
    <>
      <MediumProviderModal modalVisible={showingMediumProviders} setModalVisible={setShowingMediumProviders} />
      <GenreModal modalVisible={showingGenres} setModalVisible={setShowingGenres} />
      <NotificationsModal modalVisible={showingNotifications} setModalVisible={setShowingNotifications} />
      {editingAccount && <AccountEditor setEditingAccount={setEditingAccount} />}
      {resetPasswordRequested && <div style={{ color: '#55cc55', textAlign: 'right', marginTop: '-10px', marginBottom: '10px'}}>Reset password requested successfully, please check your e-mail inbox.</div>}
      <ConfirmDialog
        visible={showingConfirmation}
        setVisible={setShowingConfirmation}
        title={"Cancel Subscription"}
        prompt={(
          <span>
            Are you sure you want to cancel your subscription? You'll lose your rating history, lists and recommendations.
            If there's something we can do to keep you, please <a className="App-link" href="mailto:help@vuniverse.com?subject=Canceling my account">let us know</a> before you cancel.
          </span>)
        }
        onConfirm={cancelSubscription}
      />
      <ConfirmDialog
        visible={showingDeleteAccount}
        setVisible={setShowingDeleteAccount}
        title={"Delete Account"}
        prompt={(
          <span>
            Are you sure you want to completely delete your account (vs. canceling your subscription)? This will immediately delete your username, lists, rating history, and all other data associated with your VU account.<br />
            If you registered on a mobile app store, you must take an extra step to cancel your recurring subscription on your device's subscriptions page to stop the payment process.<br />
          </span>)
        }
        onConfirm={() => { setShowingDeleteAccount(false); setRequestingPassword(true) }}
        buttonLabel="I understand. Delete my account"
      />
      <PasswordConfirmationDialog
        visible={requestingPassword}
        setVisible={setRequestingPassword}
        onConfirm={deleteAccount}
      />
      {showingPlans && <PlanList hideLogo={true} onCancel={() => setShowingPlans(false)}/>}
      {!editingAccount && !showingPlans && <ListGroup>
        <ListGroup.Item>
          <Container>
            <Row>
              <Col>Streaming Services</Col>
              <Col className="right-col" onClick={() => { setShowingMediumProviders(true) }}>{mediumProviders.length > 0 && mediumProviders.reduce((result, item) => `${result}, ${item}`)}</Col>
            </Row>
          </Container>
        </ListGroup.Item>
        <ListGroup.Item>
          <Container>
            <Row>
              <Col>Genre Preference</Col>
              <Col className="right-col" onClick={() => { setShowingGenres(true) }}>{genres.length > 0 && genres.reduce((result, item) => `${result}, ${item}`)}</Col>
            </Row>
          </Container>
        </ListGroup.Item>
        <ListGroup.Item>
          <Container>
            <Row style={{ cursor: 'default' }} onClick={() => { setEditingAccount(true) }}>
              <Col>Edit Your Account</Col>
              <Col className="right-col">{`Display Name: ${user.display_name}`}</Col>
            </Row>
          </Container>
        </ListGroup.Item>
        <ListGroup.Item>
          <Container>
            {!user.plan_read_only && <Row style={{ cursor: 'default' }} onClick={() => setShowingPlans(true)}>
              <Col>Change Your Plan</Col>
              <Col className="right-col">{user.product_name}</Col>
            </Row>}
            {user.plan_read_only && <Row>
              <Col>Plan</Col>
              <Col className="right-col">{user.product_name}</Col>
            </Row>}
          </Container>
        </ListGroup.Item>
        <ListGroup.Item>
          <Container>
            <Row style={{ cursor: 'default' }} onClick={() => { setShowingNotifications(true) }}>
              <Col>Notifications</Col>
              <Col className="right-col"></Col>
            </Row>
          </Container>
        </ListGroup.Item>
        { false && <ListGroup.Item>
          <Container>
            <Row>
              <Col>Critical Rating Source</Col>
              <Col className="right-col">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {criticalRatingSourceName} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#none" onClick={() => { setCriticalRatingSource('none') }}>None</Dropdown.Item>
                    <Dropdown.Item href="#metacritic" onClick={() => { setCriticalRatingSource('metacritic') }}>Metacritic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>}
        { false && <ListGroup.Item>
          <Container>
            <Row>
              <Col>Add Tags While Rating</Col>
              <Col className="right-col">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={addTagsWhileRating}
                  onChange={() => { setAddTagsWhileRating(!addTagsWhileRating) }}
                />
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>}
        { false && <ListGroup.Item>
          <Container>
            <Row>
              <Col>Unavailable Titles</Col>
              <Col className="right-col">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={unavailableTitles}
                  onChange={() => { setUnavailableTitles(!unavailableTitles) }}
                />
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>}
        <ListGroup.Item>
          <Container>
            <Row style={{ cursor: 'default' }} onClick={() => { navigate('/hidden') }}>
              <Col>Hidden From Discover</Col>
              <Col className="right-col"></Col>
            </Row>
          </Container>
        </ListGroup.Item>

        {user.subscription_processor && <ListGroup.Item>
          {user.subscription_processor === 'stripe' && <Container>
            <Row style={{ cursor: 'default' }} onClick={confirmCancelSubscription}>
              {!subscriptionCanceled && <Col style={{color: '#cc8888'}}>Cancel Subscription</Col>}
              {subscriptionCanceled && <Col style={{color: 'red'}}>Your subscription is currently canceled</Col>}
              <Col className="right-col"></Col>
            </Row>
          </Container>}
          {user.subscription_processor !== 'stripe' && <Container>
            <Row style={{ cursor: 'default' }}>
              {user.subscription_processor ===  'apple' && <Col style={{color: '#cc8888'}}>Subscription managed by Apple Pay</Col>}
              {user.subscription_processor ===  'google' && <Col style={{color: '#cc8888'}}>Subscription managed by Google Pay</Col>}
              <Col className="right-col"></Col>
            </Row>
          </Container>}
        </ListGroup.Item>}
      </ListGroup>}
      <ListGroup.Item>
          <Container>
            <Row style={{ cursor: 'default' }} onClick={confirmDeleteAccount}>
              <Col style={{color: '#ff0000'}}>Delete Account</Col>
              <Col className="right-col"></Col>
            </Row>
          </Container>
        </ListGroup.Item>
      {errors && errors.message && <div className='error'>{errors.message}</div>}
      {!showingPlans && <div className="setting-button-container">
        <div className="setting-button"><a href="javascript: void(0)" onClick={resetPassword}>Reset Password</a></div>
      </div>}
    </>
  )
}
