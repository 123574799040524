import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Container, Form, Card } from 'react-bootstrap'
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,
         TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon,
         LinkedinShareButton, LinkedinIcon } from 'react-share'
import { useLocation } from 'react-router-dom'
import './ShareOptions.scss'

export default function ShareOptions({ medium, urlPath, userList }) {
  const [shareUrl, setShareUrl] = useState(urlPath ? window.location.protocol + '//' + window.location.hostname + urlPath : window.location.href)
  const [shareSubject, setShareSubject] = useState(medium ? medium.title : "")
  const [shareBody, setShareBody] = useState("")
  const location = useLocation()

  useEffect(() => {
    if(medium && medium.title) {
      setShareSubject(`A suggestion for you to check out ${medium.title}`)
      setShareBody(`I would like to recommend this movie/tv show. You can check the streaming providers from VUniverse`)
    }
  }, [medium])

  useEffect(() => {
    if(userList && userList.name) {
      setShareSubject(`I want to share my list ${userList.name} with you`)
      setShareBody(`I would like to share this list with you. You can check the movies/shows streaming providers from VUniverse`)
    }
  }, [userList])

  useEffect(() => {
    if(urlPath) {
      setShareUrl(window.location.protocol + '//' + window.location.hostname + urlPath)
    }
  }, [urlPath])

  useEffect(() => {
    if(!urlPath) {
      setShareUrl(window.location.href)
    }
  }, [location])

  return (
    <div className="share-options" style={{ marginTop: '10px'}}>
      <EmailShareButton
        subject={shareSubject}
        url={shareUrl}
        body={shareBody}
        separator=" "
        className="share-icon"
        style={{ marginLeft: '0px'}}
        >
        <EmailIcon size={32} round />
      </EmailShareButton>
      <FacebookShareButton
        url={shareUrl}
        quote={shareBody}
        separator=" "
        className="share-icon"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={shareSubject}
        className="share-icon"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <WhatsappShareButton
        url={shareUrl}
        title={shareSubject}
        separator=":: "
        className="share-icon"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <LinkedinShareButton
        url={shareUrl}
        className="share-icon"
        style={{ marginRight: '0px'}}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  )
}