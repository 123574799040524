import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"
import SubscriptionSuccess from '../Payment/SubscriptionSuccess'
import SubscriptionCanceled from '../Payment/SubscriptionCanceled'
import LoginForm from '../Login/LoginForm'
import Logout from '../Login/Logout'
import SignupForm from '../Signup/SignupForm'
import StartPage from './StartPage'
import PlanList from '../Payment/PlanList'
import { useSelector } from 'react-redux'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import ResetPasswordForm from '../Login/ResetPasswordForm'
import GetTheApp from '../Footer/GetTheApp'
import About from '../Footer/About'
import Faq from '../Footer/Faq'
import Contact from '../Footer/Contact'
import TermsPage from './TermsPage'
import PrivacyPage from './PrivacyPage'
import DeletionPage from './DeletionPage'
import DeleteAccountPage from './DeleteAccountPage'
import ConfirmDeleteAccountPage from './ConfirmDeleteAccountPage'
import AcceptRejectList from './AcceptRejectList'
import MemberRouteSaver from './MemberRouteSaver'

export default function GuestRoutes({ selection, setSelection }) {
  const user = useSelector(state => state.user)
  const [plansStep, setPlansStep] = useState(false)
  const [signupSteps, setSignupSteps] = useState(false)

  useEffect(() => {
    if(user && user.access_token) {
      if(user.signup_step <= LAST_SIGNUP_STEP) {
        setSignupSteps(true)
      } else {
        setPlansStep(true)
      }
    } else {
      setPlansStep(false)
      setSignupSteps(false)
    }
  }, [user])

  return (
    <Routes>
      <Route path="/get_the_app" element={<GetTheApp />} />
      <Route path="/about" element={<About />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/deletion" element={<DeletionPage />} />
      <Route path="/delete_account" element={<DeleteAccountPage />} />
      <Route path="/confirm_delete_account" element={<ConfirmDeleteAccountPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/users/password/forgot" element={<ResetPasswordForm forgot={true} />} />
      <Route path="/users/password/edit" element={<ResetPasswordForm />} />
      <Route path="/checkout_success" element={<SubscriptionSuccess />} />
      <Route path="/checkout_canceled" element={<SubscriptionCanceled />} />
      <Route path="/plans" element={<PlanList/>} />
      <Route path="/login" element={<LoginForm/>} />
      <Route path="/register" element={<SignupForm/>} />
      <Route path="/logout" element={<Logout/>} />
      <Route path="/accept_list/:code" element={<AcceptRejectList isAccept={true} />} />
      <Route path="/reject_list/:code" element={<AcceptRejectList isAccept={false} />} />
      <Route path="/my_lists" element={<MemberRouteSaver />} />

      <Route path="/discover/media/:mediaId" element={<MemberRouteSaver />} />
      <Route path="/my_lists/media/:mediaId" element={<MemberRouteSaver />} />
      <Route path="/my_lists/:id/media/:mediaId" element={ <MemberRouteSaver /> } />
      <Route path="/rating/media/:mediaId" element={<MemberRouteSaver />} />
      <Route path="/search/media/:mediaId" element={<MemberRouteSaver />} />
      <Route path="/settings" element={<MemberRouteSaver />} />
      <Route path="/people/:personId" element={<MemberRouteSaver />} />
      <Route path="/people/:personId/media/:mediaId" element={<MemberRouteSaver />} />
      <Route path="/my_lists/share/:listId" element={<MemberRouteSaver />} />

      <Route path="/" element={
        (!plansStep && !signupSteps) ? <StartPage />
        : (plansStep ? <PlanList /> : <SignupForm />)
      } />
    </Routes>
  )
}
