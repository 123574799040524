import { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import { setListModalData } from '../../actions/list_modal_data'
import ListModalUserList from './ListModalUserList'
import AddListForm from './AddListForm'
import { Modal, Button } from 'react-bootstrap'
import { setUserListsMediumSaved } from '../../actions/user_lists_medium'
import SuggestToShareListModal from './SuggestToShareListModal'

export default function ListsModal() {
  const list_modal_data = useSelector((state) => state.list_modal_data)
  const [userLists, setUserLists] = useState([])
  const [selectedLists, setSelectedLists] = useState([])
  const [shareLists, setShareLists] = useState([])
  const [addingList, setAddingList] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if(list_modal_data.medium && list_modal_data.medium.id) {
      fetchUserLists()
    }
  }, [list_modal_data])

  const fetchUserLists = () => {
    setAddingList(false)
    const access_token = localStorage.getItem('access_token')
    var params = { medium_id: list_modal_data.medium.id }
    api_client(access_token).get('/api/v1/user_lists', { params: params }).then(res => {
      setUserLists(res.data.user_lists)
      setSelectedLists(res.data.user_lists.filter(l => l.has_medium))
    })
  }

  const handleClose = () => {
    dispatch(setListModalData({ visible: false, medium: {} }))
  }

  const toggleUserList = (list) => {
    if (selectedLists.includes(list)) {
      setSelectedLists(selectedLists.filter(l => l.id !== list.id))
    } else {
      setSelectedLists([...selectedLists, list])
    }
  }

  const addToSelectedLists = () => {
    const access_token = localStorage.getItem('access_token')
    const medium = list_modal_data.medium
    const userListIds = selectedLists.map((list) => list.id)
    api_client(access_token).post(
      `/api/v1/user_lists/0/user_list_media/save_selection`,
      { user_list_medium: { medium_id: medium.id, user_list_ids: userListIds },
        will_prompt_to_share: true }
    ).then(res => {
      setShareLists(res.data.share_user_lists)
      dispatch(setUserListsMediumSaved(res.data))
    })
    handleClose()
  }

  return (
    <>
      <SuggestToShareListModal userLists={shareLists} setUserLists={setShareLists} />
      <Modal
        show={list_modal_data.visible}
        onHide={handleClose}
        backdrop="static"
        className="lists-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{list_modal_data.medium && list_modal_data.medium.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!addingList && <Container>
            {userLists.map((list, index) => <ListModalUserList
              list={list}
              key={list.id}
              toggleUserList={toggleUserList}
              checked={selectedLists.filter(l => l.id === list.id).length > 0}
              /> )}
          </Container>}
          {addingList && <AddListForm reloadLists={fetchUserLists} />}
        </Modal.Body>

        <Modal.Footer>
          {!addingList && <>
            <Button variant="secondary" onClick={() => { setAddingList(true) }}>New list</Button>
            <Button variant="primary" onClick={addToSelectedLists}>Save changes</Button>
          </>}
          {addingList && <>
            <Button variant="secondary" onClick={() => { setAddingList(false) }}>Cancel</Button>
          </>}
        </Modal.Footer>
      </Modal>
    </>
  )
}
