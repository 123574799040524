import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLoggedInUser } from '../../actions/user'
import { useEffect } from 'react'

export default function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      setLoggedInUser({}, false), navigate('/')
    )
  }, [])

  return (
    <></>
  )
}
