import { apiClientWithToken } from '../../../config/api-client'
import HideShowButtons from './HideShowButtons'

export default function ProvidersOverlay({ medium, mediaReload, selection, setCardVisible,
  overlayVisible }) {

  const navigateToStreaming = (url) => {
    window.open(url, '_blank')
  }

  const createInteraction = (medium, provider_medium) => {
    apiClientWithToken().post(`/api/v1/user_interactions.json`, {
      user_interaction: {
        medium_id: medium.id,
        provider_medium_id: provider_medium.id
      }
    }).then(_ => {
      navigateToStreaming(provider_medium.url)
    })
  }

  return (
    <div className={`overlay full-height ${overlayVisible ? 'd-block' : 'd-none'}`}>
      <HideShowButtons
        medium={medium}
        mediaReload={mediaReload}
        selection={selection}
        setCardVisible={setCardVisible} />

      {medium.no_providers && <p className='no-providers-text'>Streaming information<br />coming soon!...</p>}
      {medium.provider_medium && medium.provider_medium.length > 0 && <>
        <p>Now streaming on:</p>
        <img
          onClick={() => { createInteraction(medium, medium.provider_medium[0]) }}
          className="streaming-icon" style={{ marginTop: '0px'}}
          src={medium.provider_medium[0].medium_provider.image}
          alt={medium.provider_medium[0].medium_provider.name} />
        
        {medium.provider_medium[0].price > 0.0 && <div className='price'>${medium.provider_medium[0].price}</div>}
        
        {medium.provider_medium.length > 1 && <>
          <img
            onClick={() => { createInteraction(medium, medium.provider_medium[1]) }}
            className="streaming-icon"
            src={medium.provider_medium[1].medium_provider.image}
            alt={medium.provider_medium[1].medium_provider.name} />
          
          {medium.provider_medium[1].price > 0.0 && <div className='price'>${medium.provider_medium[1].price}</div>}
        </>}
      </>}
    </div>
  )
}