import React from 'react'
import './index.css'
import App from './components/App/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-multi-carousel/lib/styles.css"
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { BrowserRouter as Router } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

const VUniverseApp = (props) => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
    </Provider>
  )
}

export default VUniverseApp
