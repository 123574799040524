import { Modal, Button } from 'react-bootstrap'
import MediumProviders from './MediumProviders'
import './MediumProviderModal.scss'
import { useState } from 'react'
import { api_client } from '../../config/api-client'

export default function MediumProviderModal({ modalVisible, setModalVisible }) {
  const [selectedMediumProviderIds, setSelectedMediumProviderIds] = useState([])
  const [errors, setErrors] = useState({})

  const handleClose = () => {
    setModalVisible(false)
  }

  const updateSelectedMediumProviders = () => {
    api_client(localStorage.getItem('access_token')).post('/api/v1/user_medium_providers/update_all.json', {
      medium_providers_ids: selectedMediumProviderIds
    }).then((response) => {
      setModalVisible(false)
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to update providers' })
      }
    })
  }

  return (
    <>
      <Modal
        show={modalVisible}
        onHide={handleClose}
        backdrop="static"
        className="medium-provider-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Streaming Services</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {errors && errors.user && <div className='error'>{errors.user}</div>}
          <MediumProviders
            selectedMediumProviderIds={selectedMediumProviderIds}
            setSelectedMediumProviderIds={setSelectedMediumProviderIds} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={updateSelectedMediumProviders}>Save changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
