import { SET_LIST_MODAL_DATA } from "../config/constants.js"

const list_modal_data = (state = {}, action) => {
  switch (action.type) {
    case SET_LIST_MODAL_DATA:
      return action.list_modal_data
    default:
      return state
  }
}

export default list_modal_data
