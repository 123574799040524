import { SET_NOTIFICATION } from "../config/constants.js"

const notification = (state = {}, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return action.notification
    default:
      return state
  }
}

export default notification
