import { SET_USER, SET_RELOAD_USER } from '../config/constants'

export function setLoggedInUser(user, accessToken) {
  localStorage.setItem('access_token', accessToken)
  return { type: SET_USER, user: user }
}

export function setReloadUser(newValue) {
  return { type: SET_RELOAD_USER, reload: newValue}
}
