import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setSearchQuery } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'
import { Tab, Tabs } from "react-bootstrap"
import './TabRating.scss'
import RateList from '../MediaBrowser/RateList'
import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader"

const override = css`
  display: block;
  margin: 0 auto;
`
export default function TabRating({
  initialSelection,
  setSelection,
  selection,
  match
}) {
  const [allRating, setAllRating] = useState([])
  const [media, setMedia] = useState([])
  const [mediaByGenre, setMediaByGenre] = useState([])
  const [shouldUpdateHistory, setShouldUpdateHistory] = useState(false)
  const [loadedMedia, setLoadedMedia] = useState(false)
  const [loadedByGenre, setLoadedByGenre] = useState(false)
  const [loadedHistory, setLoadedHistory] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState('rate')
  const [canShowRate, setCanShowRate] = useState(tab === 'rate')
  const dispatch = useDispatch()
  const user_medium_rating = useSelector((state) => state.user_medium_rating)

  const fetchAllHistory = async () => {
    const access_token = localStorage.getItem('access_token')
    
    api_client(access_token).get(`/api/v1/media/rating_history`).then(res => {
      setAllRating(res.data.media)
      setLoadedHistory(true)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchMedia = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { selection: initialSelection }
    
    api_client(access_token).get(`/api/v1/media`, { params: requestParams }).then(res => {
      setMedia(res.data.media)
      setLoadedMedia(true)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const changeTab = (key) => {
    setTab(key)
    if(key === 'rate') {
      setCanShowRate(true)
    }
  }

  const fetchMediaByGenre = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { grouped_genres: true, selection: initialSelection }
    api_client(access_token).get(`/api/v1/media`, { params: requestParams }).then(res => {
      setLoadedByGenre(true)
      setMediaByGenre(res.data.by_genre)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const fetchHistory = () => {
    setShouldUpdateHistory(false)
    setLoading(true)
    setLoadedMedia(false)
    setLoadedByGenre(false)
    setLoadedHistory(false)
    fetchMedia()
    fetchMediaByGenre()
    fetchAllHistory()
  }


  useEffect(() => {
    dispatch(setSearchQuery(''))
    if(selection !== initialSelection) setSelection(initialSelection)
  }, [])

  useEffect(() => {
    if(loadedMedia && loadedByGenre && loadedHistory) {
      if(loading) setLoading(false)
    }
  }, [loadedMedia, loadedByGenre, loadedHistory])

  useEffect(() => {
    if(tab === 'history' && ((media.length < 1 && mediaByGenre.length < 1) || shouldUpdateHistory)) {
      fetchHistory()
    }
  }, [tab])

  useEffect(() => {
    setShouldUpdateHistory(true)
  }, [user_medium_rating])

  return (
      <div className="rating-tab">
        <Tabs activeKey={tab} onSelect={(k) => changeTab(k)} className="mb-3 rating-tabs">
          <Tab eventKey="rate" title="Rate">
            <div className={`loading-container ${loading ? '' : 'd-none'}`}>
              <PropagateLoader color="#f1af3b" loading={loading} css={override} size={10} />
            </div>
            <h5>Rate movies and shows you've seen to enhance vour recommendations.</h5>
            {canShowRate && <RateList loading={loading} setLoading={setLoading} />}
          </Tab>
          <Tab eventKey="history" title="History">
            {tab === 'history' && <MediaBrowser
              loading={loading}
              setSelection={setSelection}
              selection={selection}
              initialSelection={'rating'}
              media={media}
              mediaByGenre={mediaByGenre}
              rating={allRating}
            />}
          </Tab>
        </Tabs>
      </div>
  );
}
