import React, { useState, useEffect } from 'react'
import { setUserListsMediumSaved } from '../../actions/user_lists_medium'
import { useSelector, useDispatch } from 'react-redux'
import { api_client, errorHandler } from '../../config/api-client'
import { Modal, Button, Row, Col, Container, Form } from 'react-bootstrap'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdUndo } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
import ConfirmDialog from '../Settings/ConfirmDialog'


export default function ManageLists({reloadLists}) {
  const [userLists, setUserLists] = useState([])
  const dispatch = useDispatch()

  const [ error, setError ] = useState(false)
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)
  const [ showConfirmDiscard, setShowConfirmDiscard ] = useState(false)
  const [ showConfirmSave, setShowConfirmSave ] = useState(false)
  const [ hasChanges, setHasChanges ] = useState(false)
  const [ listName, setListName] = useState("")
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [addingList, setAddingList] = useState(false)
  const [ updatingList, setUpdatingList] = useState(false)
  const [ deletingList, setDeletingList] = useState(false)
  const [ modalVisible, setModalVisible ] = useState(false)

  useEffect(() => {
    if(modalVisible) {
      fetchUserLists()
    }
  }, [modalVisible])

  const fetchUserLists = () => {
    setAddingList(false)
    setLoading(true)
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).get('/api/v1/user_lists', { params: { to_manage: true, shared_included: true } }).then(res => {
      setLoading(false)
      setUserLists(res.data.user_lists.filter((item) => item.can_manage))
      setHasChanges(false)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const handleClose = () => {
    if(addingList || updatingList) {
      setAddingList(false)
      setUpdatingList(false)
    } else {
      if(hasChanges) {
        setShowConfirmDiscard(true)
      } else {
        confirmedClose()
      }
    }
  }

  const confirmedClose = () => {
    setShowConfirmDiscard(false)
    setModalVisible(false)
    setUserLists([])
    setListName("")
    setUpdatingList(false)
    setDeletingList(false)
    setError(false)
    setHasChanges(false)
  }

  const newListObject = (name) => {
    return {"id": 0, "name": name, "staff_pick": false, can_manage: true, is_owner: true }
  }

  const goUpdateList = (list) => {
    if(list.delete) {
      var toUpdate = [...userLists]
      for(var i = 0; i < toUpdate.length; i++) {
        if(list.name === toUpdate[i].name) {
          toUpdate[i].delete = false
        }
      }
      setUserLists(toUpdate)
    } else if (!list.is_owner) {
      setDeletingList(list)
      setShowConfirmDelete(true)
    } else {
      setUpdatingList(list)
      setListName(list.name)
    }
  }
  
  const promptDeleteList = () => {
    if(updatingList.id === 0) {
      var toUpdate = [...userLists.filter((item) => item.name !== updatingList.name)]
      setUserLists(toUpdate)
      setHasChanges(true)
      setListName('')
      setAddingList(false)
      setUpdatingList(false)
      setError(false)
      return
    }
    setDeletingList(updatingList)
    setShowConfirmDelete(true)
  }

  const deleteList = () => {
    var toUpdate = [...userLists]
    for(var i = 0; i < toUpdate.length; i++) {
      if(deletingList.name === toUpdate[i].name) {
        toUpdate[i].name = deletingList.name
        toUpdate[i].delete = true
      }
    }
    setUserLists(toUpdate)
    setHasChanges(true)
    setListName('')
    setAddingList(false)
    setUpdatingList(false)
    setError(false)
    setShowConfirmDelete(false)
  }

  const addOrUpdateList = () => {
    if(updatingList && updatingList.name === listName.trim()) {
      return
    }
    if(listName.trim().length < 1) {
      setError("Please type the list name.")
      return
    }
    if(userLists.filter((item) => item.name === listName.trim()).length > 0) {
      setError('You already have a list with this name.')
      return
    }
    if(updatingList) {
      var toUpdate = [...userLists]
      for(var i = 0; i < toUpdate.length; i++) {
        if(updatingList.name == toUpdate[i].name) {
          toUpdate[i].name = listName.trim()
          toUpdate[i].update = true
        }
      }
      setUserLists(toUpdate)
    } else {
      setUserLists([...userLists, ...[newListObject(listName.trim())]])
    }
    setHasChanges(true)
    setListName('')
    setAddingList(false)
    setUpdatingList(false)
    setError(false)
  }

  const saveChanges = () => {
    setShowConfirmSave(true)
  }

  const saveConfirmed = () => {
    setSaving(true)
    setShowConfirmSave(false)
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post('/api/v1/user_lists/batch_update', { user_lists: userLists.filter((e) => { return e.update || e.delete || e.id === 0}) }).then(res => {
      setModalVisible(false)
      setSaving(false)
      setUserLists([])
      reloadLists()
    }).catch((error) => {
      setSaving(false)
      errorHandler(error, dispatch)
    })
  }

  const sortedList = () => {
    return userLists.sort(function(a, b) {
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })
  }

  return (
    <>
      <h3 onClick={() => setModalVisible(true)} style={{ cursor: 'pointer'}}>Manage Lists</h3>
      <Modal
        show={modalVisible}
        onHide={handleClose}
        backdrop="static"
        className="lists-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Lists</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!addingList && !updatingList && <Container>
            {userLists.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((item, index) => <div key={`${item.name}_${item.delete}`} onClick={() => goUpdateList(item)}>
              {item.delete !== true && <Row style={{ cursor: 'pointer' }} className="list-modal-user-list">
                <Col md={10}>
                  {item.is_owner && <span>{item.name}</span>}
                  {!item.is_owner && <span>{item.name}<br /><span style={{ color: 'rgb(116, 116, 122)', fontSize: '.9em'}}>shared by {item.owner_name}</span></span>}
                </Col>
                {item.is_owner && <Col md={2} style={{ textAlign: 'right'}}><AiOutlineEdit /></Col>}
                {!item.is_owner && <Col md={2} style={{ textAlign: 'right'}}><HiOutlineTrash /></Col>}
              </Row>}
              {item.delete === true && <Row style={{ cursor: 'pointer' }} className="list-modal-user-list">
                <Col md={10}>
                  {item.is_owner && <span style={{ color: 'red', textDecoration: 'line-through'}}>{item.name}</span>}
                  {!item.is_owner && <span><span style={{ color: 'red', textDecoration: 'line-through'}}>{item.name}</span><br /><span style={{ color: 'rgb(116, 116, 122)', fontSize: '.9em'}}>shared by {item.owner_name}</span></span>}
                </Col>
                <Col md={2} style={{ textAlign: 'right'}}><MdUndo /></Col>
              </Row>}
            </div>)}
          </Container>}
          {(addingList || updatingList) && <>
            <Form>
              <Container>
                <Row>
                  <Col md={9}>
                    <Form.Control type="text" placeholder="Enter list name" value={listName} onChange={(e) => { setListName(e.target.value) }} />
                  </Col>
                  <Col md={3}>
                    <Button
                      disabled={listName.trim().length < 1}
                      variant="primary"
                      onClick={() => { addOrUpdateList() }}>{updatingList ? "Update" : "Add"}</Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </>}
          {error && <span style={{ color: 'red'}}>{error}</span>}
        </Modal.Body>

        <Modal.Footer>
          {updatingList && <>
            <Button
              variant="danger"
              onClick={promptDeleteList}>Delete list</Button>
          </>}
          {!(addingList || updatingList) && <>
            <Button variant="secondary" onClick={() => { setAddingList(true) }}>New list</Button>
            {hasChanges && <Button
              variant="primary"
              onClick={saveChanges}>Save changes</Button>}
          </>}
          {(addingList || updatingList) && <>
            <Button
              variant="secondary"
              onClick={() => { setAddingList(false); setListName(""); setUpdatingList(false); setError(false) }}
              >Cancel
            </Button>
          </>}
        </Modal.Footer>
      </Modal>
      {deletingList && <ConfirmDialog
        visible={showConfirmDelete}
        setVisible={setShowConfirmDelete}
        title={`Delete ${deletingList.name}`}
        buttonLabel="Yes, delete this list"
        prompt={(
          <div>
            {deletingList.is_owner && <span>{`This list has ${deletingList.count_media} titles on it, are you sure you want to delete it?`}</span>}
            {!deletingList.is_owner && <span>Are you sure you want to remove this shared list?<br />(it will not be deleted from the user who shared it)</span>}
          </div>
        )}
        onConfirm={deleteList}
      />}
      {hasChanges && <ConfirmDialog
        visible={showConfirmDiscard}
        setVisible={setShowConfirmDiscard}
        title="Cancel changes"
        buttonLabel="Yes, discard my changes"
        prompt={(
          <span>Are you sure you want to cancel your changes?</span>)
        }
        onConfirm={confirmedClose}
      />}
      {hasChanges && <ConfirmDialog
        visible={showConfirmSave}
        setVisible={setShowConfirmSave}
        title="Save changes"
        buttonLabel="Yes"
        prompt={(
          <span>Confirm saving changes?</span>)
        }
        onConfirm={saveConfirmed}
      />}
    </>
  )
}

