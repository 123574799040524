import { useEffect } from "react"
import { Container } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import MediaCarousel from './MediaCarousel'
import ListsModal from './ListsModal'
import RatingModal from '../Rating/RatingModal'
import ShareModal from './ShareModal'
import SearchBox from './SearchBox'
import './MediaBrowser.scss'
import { setMediaModalData } from '../../actions/media_modal_data'
import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader"
import ListMediaBrowser from './ListMediaBrowser'

const override = css`
  display: block;
  margin: 0 auto;
`

export default function MediaBrowser({
  initialSelection,
  setSelection,
  selection,
  match,
  media,
  mediaReload,
  mediaByGenre,
  recommendedMedia,
  userLists,
  people,
  person,
  loading,
  rating,
  recommendedLoaded
}) {

  const searchQuery = useSelector((state) => state.search)
  const media_modal_data = useSelector((state) => state.media_modal_data)
  const { mediaId } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if(mediaId) {
      dispatch(setMediaModalData({ visible: true, medium: { id: mediaId } }))
    } else if(media_modal_data.visible) {
      dispatch(setMediaModalData({ visible: false, medium: { } }))
    }
  }, [mediaId])

  return (
    <Container className="media-browser">
      {initialSelection === 'search' && <SearchBox />}
      <div className={`loading-container ${loading ? '' : 'd-none'}`}>
        <PropagateLoader color="#f1af3b" loading={loading} css={override} size={10} />
      </div>
      {initialSelection === 'search' && media.length === 0 && people.length === 0 && searchQuery.query.length > 0 && !loading && <div className="no-results">
        No results.
      </div>}
      {initialSelection === 'rating' && rating.length > 0 && <MediaCarousel media={rating} location={location} title="All Rating History" />}
      {initialSelection === 'search' && media.length > 0 && <MediaCarousel carouselKey={searchQuery ? searchQuery.query : "_"} media={media} mediaReload={mediaReload} location={location} title="Movies and Shows" />}
      {initialSelection === 'search' && people.length > 0 && <MediaCarousel media={people} location={location} isPeople={true} setSelection={setSelection} title="Cast &amp; Crew" />}
      {initialSelection !== 'search' && initialSelection !== 'rating' && initialSelection !== 'settings' && media && media.length > 0 && <MediaCarousel media={media} mediaReload={mediaReload} location={location} title="Recent Releases" />}
      {initialSelection !== 'search' && initialSelection !== 'my_lists' && recommendedMedia && recommendedMedia.length > 0 && <MediaCarousel loading={!recommendedLoaded} media={recommendedMedia} mediaReload={mediaReload} location={location} title="Recommended for you" />}
      {initialSelection === 'settings' && media && media.length > 0 && <MediaCarousel media={media} selection={selection} mediaReload={mediaReload} location={location} title="Sorted By Name" />}
      {userLists && <ListMediaBrowser mediaReload={mediaReload} userLists={userLists} selection={selection} location={location} />}
      {initialSelection !== 'search' && initialSelection !== 'my_lists' && mediaByGenre && mediaByGenre.length > 0 && mediaByGenre.map((item,i) => {
        return <MediaCarousel key={item.name} media={item.media} selection={selection} mediaReload={mediaReload} location={location} title={item.name} />
      })}
      <ListsModal />
      <RatingModal />
      <ShareModal />
    </Container>
  );
}
