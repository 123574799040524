import { useState } from 'react'
import { Card } from 'react-bootstrap'
import PropagateLoader from "react-spinners/PropagateLoader"

export default function LoadMoreCard({mediaReload}) {
  const [ loading, setLoading ] = useState(false)

  const reloadMedia = () => {
    if(!loading) {
      setLoading(true)
      mediaReload()
    }
  }

  return (
    <Card className="left-floated-card load-more-card">
      <div className="load-more-content" onClick={reloadMedia}>
        {!loading && <span>Load more</span>}
        {loading && <span>
          <PropagateLoader color="#f1af3b" loading={true}  size={5} />
        </span>}
      </div>
    </Card>
  )
}