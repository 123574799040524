import { useState, useEffect } from 'react'
import { apiClientWithToken } from '../../config/api-client'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Card, Col, Row } from 'react-bootstrap'
import { setRatingModalData } from '../../actions/rating_modal_data'
import { setUserMediumRatingSaved } from '../../actions/user_medium_rating'
import { Modal, Button } from 'react-bootstrap'
import './RatingModal.scss'
import { Rating } from 'react-simple-star-rating'
import TagSelector from '../MediaBrowser/TagSelector'
import ShareOptions from '../Media/ShareOptions'

export default function RatingModal() {
  const rating_modal_data = useSelector((state) => state.rating_modal_data)
  const [userMediumRating, setUserMediumRating] = useState([])
  const [selectingTags, setSelectingTags] = useState(false)
  const [saved, setSaved] = useState(false)
  const [medium, setMedium] = useState({})
  const dispatch = useDispatch()
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [tagsString, setTagsString] = useState('')

  useEffect(() => {
    setTagsString([...tags].reduce((acc, x) => acc === null ? [x] : [acc, ', ', x], null))
  }, [tags])

  useEffect(() => {
    if(rating_modal_data && rating_modal_data.medium) {
      setSaved(false)
      setMedium(rating_modal_data.medium)
      fetchUserMediumRating()
    }
  }, [rating_modal_data])

  useEffect(() => {
    setRating(userMediumRating.rating)
    if(!userMediumRating.tags) {
      setTags([])
    } else {
      setTags(userMediumRating.tags)
    }
  }, [userMediumRating])

  const handleRating = (rate) => {
    setRating(rate / 10)
  }

  const fetchUserMediumRating = () => {
    const medium = rating_modal_data.medium
    if(medium.id) {
      apiClientWithToken().get(`/api/v1/media/${medium.id}/user_medium_ratings`).then(res => {
        setUserMediumRating(res.data.user_medium_rating)
      })
    }
  }

  const saveMediumSetting = () => {
    apiClientWithToken().put(`/api/v1/media/${medium.id}/user_medium_settings/0`, {
      user_medium_setting: {
        not_suggest_sharing: true,
      }
    }).then(_ => {
      handleClose(true)
    })
  }

  const handleClose = () => {
    dispatch(setRatingModalData({ visible: false, medium: {} }))
  }

  const saveUserMediumRating = () => {
    apiClientWithToken().post(`/api/v1/media/${medium.id}/user_medium_ratings`, {
      user_medium_rating: {
        rating: rating,
        tag_list: tags
      }
    }).then(res => {
      setSaved(true)
      dispatch(setUserMediumRatingSaved(res.data.user_medium_rating))
      if(!userMediumRating.can_suggest_to_share) {
        handleClose()
      }
    })
  }

  return (
    <>
      <Modal
        show={rating_modal_data.visible}
        onHide={handleClose}
        backdrop="static"
        className="rating-modal"
      >
        {!selectingTags && <>
          <Modal.Header closeButton>
            <Modal.Title>Add My Rating</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Card className="center-card">
                <Card.Img variant="top" src={medium.poster_url} className="card-main-image" draggable={false}/>
                <Card.Body>
                  <Card.Title>{medium.title}</Card.Title>
                  <RatingShareOptions medium={medium} visible={saved && userMediumRating.can_suggest_to_share} />
                  {!saved && <Card className="rating-card">
                    <Rating transition={true} onClick={handleRating} ratingValue={rating * 10} allowHalfIcon={true} />
                  </Card>}
                  {!saved && <Card className="tags-card" onClick={() => { setSelectingTags(true) }}>
                    <Container>
                      <Row>
                        <Col md="auto" style={{ padding: '0px' }}>Select my tags</Col>
                        {tags.length < 1 && <Col className="tags-text-right">+ Add Tags</Col>}
                        {tags.length > 0 && <Col className="tags-text-right">
                          {tagsString}
                        </Col>}
                      </Row>
                    </Container>
                  </Card>}
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            {!saved && <Button variant="rating" onClick={saveUserMediumRating}>Save</Button>}
            {saved && userMediumRating.can_suggest_to_share && <Button variant="" onClick={saveMediumSetting}>I don't want to share</Button>}
            {saved && userMediumRating.can_suggest_to_share && <Button variant="" onClick={handleClose}>Maybe later</Button>}
          </Modal.Footer>
        </>}
        {selectingTags && <TagSelector selectedTags={tags} onTagsChanged={setTags} onSelectorClosed={() => { setSelectingTags(false) }} />}
      </Modal>
    </>
  )
}

const RatingShareOptions = ({medium, visible }) => {
  if(!visible) return <></>
  return (
    <Container>
      <div className='prompt-to-share'>Would you like to share with friends?</div>
      <div style={{ textAlign: 'center' }}>
        <ShareOptions medium={medium} urlPath={`/discover/media/${medium.id}`} />
      </div>
    </Container>
  )
}