import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Form, Container, Row, Col } from 'react-bootstrap'
import { api_client, errorHandler } from '../../config/api-client'
import { setNotification } from '../../actions/notification'

import './NotificationModal.scss'

export default function NotificationsModal({ modalVisible, setModalVisible }) {
  const LISTS_KEYS = [
    'shared_list', 'added_medium_to_list', 'removed_medium_from_list', 'deleted_list',
    'accepted_list'
  ]
  const OTHERS_KEYS = [
    'rate_movie_watched'
  ]
  const LISTS_TITLES = [
    'New invitations', 'New items added', 'Items removed', 'Lists deleted',
    'Invitations accepted'
  ]
  const OTHERS_TITLES = [
    'Requests for title ratings'
  ]
  const [ preferences, setPreferences ] = useState({})
  const [ reloadInteractions, setReloadInteractions ] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const updatePreference = (prefKey, prefValue) => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(
      '/api/v1/user_preferences/save',
      { user_preference: { key: prefKey, value: prefValue }}
    ).then(res => {
      setPreferences({...preferences, [prefKey]: prefValue})
      if(prefKey === 'rate_movie_watched') {
        setReloadInteractions(true)
      }
    }).catch((error) => {
      errorHandler(error, dispatch)
    })
  }

  const handleClose = () => {
    if(reloadInteractions) {
      api_client(localStorage.getItem('access_token')).get('/api/v1/user_interactions.json').then((response) => {
        if(response.data.user_interactions && response.data.user_interactions.length > 0) {
          const userInteraction = response.data.user_interactions[0]
          dispatch(setNotification({id: userInteraction.id, user_interaction: true}))
        } else {
          dispatch(setNotification({}))
        }
        setReloadInteractions(false)
        setModalVisible(false)

      }).catch((error) => {
        errorHandler(error, dispatch)
        setModalVisible(false)
      })
    } else {
      setModalVisible(false)
    }
  }

  const fetchPreferences = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).get('/api/v1/user_preferences').then((response) => {
      setPreferences(response.data)
    }).catch((error) => {
      errorHandler(error, dispatch)
    })
  }

  useEffect(() => {
    if(modalVisible) fetchPreferences()
  }, [modalVisible])

  return (
    <>
      <Modal
        show={modalVisible}
        onHide={handleClose}
        backdrop="static"
        className="notifications-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>

        <Modal.Body className="notification-form">
          {errors && errors.user && <div className='error'>{errors.user}</div>}
          <Form.Group controlId='notifications'>
            <Form.Label>What would you like to be notified about?</Form.Label>
            <Container fluid>
              <div><b>Shared Lists</b></div>
              {LISTS_KEYS.map((key, index) => 
                <NotificationSwitch
                  key={key}
                  title={LISTS_TITLES[index]}
                  value={preferences[key]}
                  setValue={() => updatePreference(key, !preferences[key])}
                />
              )}
              <div style={{ marginTop: '10px'}}><b>Others</b></div>
              {OTHERS_KEYS.map((key, index) => 
                <NotificationSwitch
                  key={key}
                  title={OTHERS_TITLES[index]}
                  value={preferences[key]}
                  setValue={() => updatePreference(key, !preferences[key])}
                />
              )}
            </Container>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  )
}

const NotificationSwitch = ({key, title, value, setValue}) => {
  return (
    <Row className="setting-switch-container">
      <Col md={10} className="left-aligned">
        {title}
      </Col>
      <Col md={2} className="right-aligned">
        <input type="checkbox" name={`notification[${key}]`} value="1" checked={value} onChange={setValue} />
      </Col>
    </Row>
  )
}
