import { Card } from 'react-bootstrap'
import './MediaCard/MediaCard.scss'
import { HiPhotograph } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

export default function PersonCard({
  person,
  setSelection
}) {
  const navigate = useNavigate()

  const showPerson = () => {
    navigate(`/people/${person.id}`)
    setSelection('from_person')
  }

  return(
    <>
      <Card className="left-floated-card" onClick={showPerson}>
        {person.image && <Card.Img variant="top" src={person.image} className="card-main-image" draggable={false}/>}
        {!person.image && <HiPhotograph className="card-main-image no-image"/>}
        <Card.Body>
          <Card.Title>{person.full_name}</Card.Title>
        </Card.Body>
      </Card>
    </>
  )
}
