import './LeftMenuItem.scss'
import { Link } from 'react-router-dom'

export default function LeftMenuItem({ icon, label, current, path, setSelection }) {

  return (
    <div className={`left-navigation-item ${current ? 'current' : ''}`}>
      <Link to={`/${path}`} onClick={() => setSelection(path)}>
        <div className="icon">{icon}</div>
        <div className="label">{label}</div>
      </Link>
    </div>
  )
}
