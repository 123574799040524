import { SET_USER_MEDIUM_HIDDEN_SAVED } from "../config/constants.js"

const user_medium_hidden = (state = { hide_count: 0 }, action) => {
  switch (action.type) {
    case SET_USER_MEDIUM_HIDDEN_SAVED:
      return action.user_medium_hidden
    default:
      return state
  }
}

export default user_medium_hidden
