import { useState, useEffect } from 'react'
import { Form, Container, Row } from 'react-bootstrap'
import { api_client } from '../../config/api-client'
import { useSelector, useDispatch } from 'react-redux'
import '../Signup/SignupForm.scss'
import MediumProviderSelector from '../Signup/MediumProviderSelector'

export default function MediumProviders({ selectedMediumProviderIds, setSelectedMediumProviderIds }) {
  const [mediumProviders, setMediumProviders] = useState([])
  const [mediumProvidersFeatured, setMediumProvidersFeatured] = useState([])

  const fetchMediumProviders = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).get('/api/v1/user_medium_providers').then((response) => {
      const mp = response.data.medium_providers.filter(item => !item.featured)
      const mpFeatured = response.data.medium_providers.filter(item => item.featured)
      const groupedColumns = []
      const groupedColumnsFeatured = []
      for(var i = 0; i < mp.length; i+=2) {
        const rowItems = []
        rowItems.push(mp[i])
        if(i + 1 < mp.length) {
          rowItems.push(mp[i + 1])
        }
        groupedColumns.push(rowItems)
      }
      for(var i = 0; i < mpFeatured.length; i+=2) {
        const rowItems = []
        rowItems.push(mpFeatured[i])
        if(i + 1 < mpFeatured.length) {
          rowItems.push(mpFeatured[i + 1])
        }
        groupedColumnsFeatured.push(rowItems)
      }
      setMediumProviders(groupedColumns)
      setMediumProvidersFeatured(groupedColumnsFeatured)
      setSelectedMediumProviderIds(response.data.user_medium_provider_ids)
    })
  }

  const mediumProviderIsSelected = (mediumProviderId) => {
    return selectedMediumProviderIds.some(item => mediumProviderId === item)
  }

  const changeMediumProviderSelected = (mediumProviderId, newValue) => {
    if(newValue) {
      const newArray = [ ...selectedMediumProviderIds, mediumProviderId]
      setSelectedMediumProviderIds(newArray)
    } else {
      const newArray = selectedMediumProviderIds.filter(function(selectedMediumProviderId) {
        return selectedMediumProviderId !== mediumProviderId
      })
      setSelectedMediumProviderIds(newArray)
    }
  }

  useEffect(() => {
    fetchMediumProviders()
  }, [])

  return (
    <div className="signup-form">

      <Form.Group controlId='streaming_providers' className="form-container">
        <p className="subtitle">Select your streaming services</p>
        <Container fluid>
          {mediumProvidersFeatured.length > 0 && mediumProvidersFeatured.map((medium_provider, index) => (
            <Row className="medium-provider-row">
              <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[0]} isSelected={mediumProviderIsSelected(medium_provider[0].id)} changeSelected={changeMediumProviderSelected}/>
              {medium_provider.length > 1 && <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[1]} isSelected={mediumProviderIsSelected(medium_provider[1].id)} changeSelected={changeMediumProviderSelected}/>}
            </Row>
            ))}
        </Container>
        <p className="subtitle" style={{ marginTop: '15px' }}>Additional Platforms</p>
        <Container fluid>
          {mediumProviders.length > 0 && mediumProviders.map((medium_provider, index) => (
            <Row className="medium-provider-row">
              <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[0]} isSelected={mediumProviderIsSelected(medium_provider[0].id)} changeSelected={changeMediumProviderSelected}/>
              {medium_provider.length > 1 && <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[1]} isSelected={mediumProviderIsSelected(medium_provider[1].id)} changeSelected={changeMediumProviderSelected}/>}
            </Row>
            ))}
        </Container>
      </Form.Group>
    </div>
  )
}
