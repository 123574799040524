import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import PropagateLoader from "react-spinners/PropagateLoader"

export default function ConfirmDialog({ visible, setVisible, title, prompt, onConfirm, buttonLabel, loading }) {

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Modal show={visible} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{prompt}</p>
        </Modal.Body>

        <Modal.Footer>
          {!loading && <Button variant="secondary" onClick={handleClose}>No, I'm not sure</Button>}
          {!loading && <Button variant="primary" onClick={onConfirm}>{buttonLabel ? buttonLabel : "Yes, please cancel my subscription"}</Button>}
          {loading && 
            <div style={{ width: '180px', paddingBottom: '10px', textAlign: 'center' }}>
              <PropagateLoader color="#f1af3b" loading={loading} css={{display: 'block', margin: '0 auto'}} size={7} />
            </div>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}
