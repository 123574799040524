export default function RateListItem({ item, index, showMedia }) {
  if(index === 0) {
    return (
      <div
        role="menuitem"
        tabIndex="0"
        onClick={showMedia}
        >
        <img
          src={item.poster_url}
          alt={item.title}
          style={{
            display: 'block',
            height: '270px',
            width: '180px',
            resizeMode: 'contain'
          }}
          data-action="http://andyyou.github.io/react-coverflow/"
        />
      </div>
    )
  } else {
    return (
      <img 
          src={item.poster_url}
          alt={item.title}
          data-action="http://andyyou.github.io/react-coverflow/"
          style={{ height: '270px', width: '180px', resizeMode: 'contain'}}
          onClick={showMedia}
          />
    )
  }
}