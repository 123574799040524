import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import './PublicPage.scss'

export default function PrivacyPage() {
  const user = useSelector(state => state.user)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    if (user.access_token && user.signup_step > LAST_SIGNUP_STEP) {
      if(!loggedIn) setLoggedIn(true)
    } else {
      if(loggedIn) setLoggedIn(false)
    }
  }, [user])

  return (
    <>
      <pre className={loggedIn ? "pre-logged-in" : "pre-not-logged"}>

      Privacy Policy of VUniverse, Inc.<br />
Effective date: June 8, 2022<br />
VUniverse Inc. ("us", "we", or "our") operates the https://www.vuniverse.com website and the VUniverse mobile application (hereinafter referred to as the "Service").<br />
This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.<br />
We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.<br />
Definitions<br />
Service<br />
Service means the http://www.vuniverse.com website and the VUniverse mobile application operated by VUniverse Inc.<br />
Personal Data<br />
Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).<br />
Usage Data<br />
Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).<br />
Cookies<br />
Cookies are small files stored on your device (computer or mobile device).<br />
Data Controller<br />
Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.<br />
For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.<br />
Data Processors (or Service Providers)<br />
Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.<br />
We may use the services of various Service Providers in order to process your data more effectively.<br />
Data Subject (or User)<br />
Data Subject is any living individual who is using our Service and is the subject of Personal Data.<br />
Information Collection and Use<br />
We collect several different types of information for various purposes to provide and improve our Service to you.<br />
Types of Data Collected<br />
Personal Data<br />
While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:<br />
• Email address<br />
• First name and last name<br />
• Birthday<br />
• Gender<br />
• Address, State, Province, ZIP/Postal code, City<br />
• Interests and Preferences<br />
• Cookies and Usage Data<br />
We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or the instructions provided in any email we send.<br />
Usage Data<br />
We may also collect non-personal information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").<br />
This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.<br />
When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.<br />
Location Data<br />
We do not use your location data.<br />
Tracking Cookies Data<br />
We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.<br />
Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.<br />
You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.<br />
Examples of Cookies we use:<br />
• Session Cookies. We use Session Cookies to operate our Service.<br />
• Preference Cookies. We use Preference Cookies to remember your preferences and various settings.<br />
• Security Cookies. We use Security Cookies for security purposes.<br />
<br />
Information We Get When You Use Our Services<br />
We collect non-personal information about the Services you or your household use and how they are used, such as when you watch a video, visit a third-party website or app that uses our Integrated Services, or interact with our content. This information may include:<br />
• Log Information: We may automatically collect standard log files when you use our Services, which include the time and date of access, pages or features viewed, IP address, the type of web browser you use, and the referral URL.<br />
• Device Information: We may collect information about the devices you use to interact with our Services. This device-specific information may include the hardware model and brand, operating system, device identifiers, resource usage, and error logs. We may also discover when your Smart TV is available to connect with a mobile device, which can, for example, be used to make pairing your devices easier.<br />
• Content Viewing Information:  We may receive non-personal information about the content you view and interact with through a Smart TV that integrates our Smart TV Services or through Smart TV Apps (“Content Viewing Information”). Content Viewing Information includes things like the title of the content, actions taken while viewing the content, and the length of time you viewed it. We receive this information in two principal ways:<br />
   ◦ Smart TV Apps: We may receive such viewing information when you use our Smart TV Apps or third-party Smart TV apps that use our Integrated Services.<br />
• Information Collected by Cookies and Other Technologies:  We use various technologies to collect information when you interact with our Services. These technologies include cookies, local storage and web beacons. Cookies and local storage are small data files that are stored on your device when you visit a website. Cookies and local storage enable us and our customers to provide features and functionality (such as keeping you logged in), measure the popularity of our Services, and monitor their performance. Web beacons (or pixel tags) are electronic images that may be used in our Services or emails to help deliver cookies, count website visits, and determine the effectiveness of email marketing campaigns.<br />
• Information from Integrated Services:  We collect non-personal information when you visit or use third-party websites and apps that use our Integrated Services, including through the cookies and other technologies described above. For example, when you elect to pair a broadcaster’s app to your TV, that broadcaster may be able to use our technology to synchronize your experience on their mobile apps as you watch a show on your TV. Also, third-party apps and websites may be able to use our services to better understand usage of their services and enable the delivery of interest-based advertisements. The information we collect includes information about your use of those websites and apps, as well as information those apps or websites enable us to collect from their services. For example, a streaming video provider may enable us to receive information about the content you watch so that our recommendation service can suggest additional content that you might like. <br />
<br />
Use of Information<br />
We may use the information we collect to:<br />
• Provide, maintain and improve our Services (for instance, we use information we collect to refine the user experience, fix service malfunctions, test our security systems and increase our technical performance);<br />
• Monitor and analyze usage of our Services;<br />
• Notify you about changes to our Services;<br />
• Personalize our Services and deliver recommendations, content and features that match your interests;<br />
• To allow you to participate in interactive features of our Service when you choose to do so;<br />
• <br />
• Communicate with you about products, services, offers and events offered by VUniverse or others, and provide content we think you will find interesting;<br />
• Respond to your comments and questions and provide customer service; and,<br />
• Detect, investigate and prevent fraudulent and other illegal activities, and protect the rights, safety and property of VUniverse and others.<br />
<br />
Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)<br />
The Vuniverse Inc. legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.<br />
Vuniverse Inc. may process your Personal Data because:<br />
• We need to perform a contract with you<br />
• You have given us permission to do so<br />
• The processing is in our legitimate interests and it is not overridden by your rights<br />
• For payment processing purposes<br />
• To comply with the law<br />
Sharing of Information You Share<br />
When you submit a post to any interactive areas of our websites or apps, users of those services may be able to see the posts, your username and when you submitted the post.<br />
The Services may offer social sharing features and other integrated tools — such as the Facebook “Like” button — which let you share actions you take on our Services with other media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the company, and are subject to the terms of use and privacy policies of those companies.<br />
Information We Share<br />
We may share the information we collect about you with third parties in the following circumstances:<br />
• With Your Consent or at Your Direction:  At your direction or if we have your consent to do so, we will share personal information with companies, organizations or individuals outside of Vuniverse. When you authorize us to share personal information about you with other companies, you should review their privacy policies to understand how they may use and treat your information.<br />
• With Vendors, Consultants, and Data Processors:   We may share personal information with vendors, consultants and data processors, service providers and other trusted businesses who work on our behalf..<br />
• For Legal Reasons: We may share personal information with companies, organizations, or individuals outside of Vuniverse: in response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, legal process or national security requirements; if we believe your actions are inconsistent with our user agreements; or, to protect the rights, property or safety of Vuniverse or others.<br />
• With Our Corporate Family:  We may share personal information between and among Vuniverse’s current and future parents, affiliates, subsidiaries and other companies under our common control and ownership. We rely on our legitimate interests to do so in order to administer and deliver our Services as described in this Policy.  We may also share information in connection with any merger, sale of assets, financing or acquisition of all or a portion of our business by another company.<br />
• When You are not Directly Identified:   We may share certain non-personal information, such as Content Viewing Information, that does not, on its own, directly identify you as an individual. We may share this anonymized information with our partners and customers (including for advertising and analytics purposes), and the public. We may, for instance, publish aggregated reports regarding how many people viewed a particular Streaming/TV show. To clarify, the non-personal information that we share will not directly identify you, but may indirectly identify you through, for example, your device or other means. The types of non-personal information we may share include device IDs, advertising IDs, IP addresses, hashed/encrypted versions of your email address or other identifier, or pseudonymous IDs.<br />
<br />
<br />
<br />
<br />
International Transfers<br />
We are based in the United States. Vuniverse will eventually make our services available globally. In the future Vuniverse may also establish additional offices/affiliates and subsidiaries in other countries.<br />
To facilitate our operations and to help deliver our Services globally, we may transfer personal information to the U.S. and any other country where our affiliates, subsidiaries, vendors, consultants and service providers operate. For example, we may transfer your personal information to Vuniverse’s servers in the U.S. to provide you with relevant recommendations on Vuniverse Mobile.<br />
The countries where we may transfer your personal information might have laws which are different, and potentially not as protective, as the laws of your country of residence. When we transfer your personal information abroad, we will take appropriate safeguards to protect the information in accordance with this Privacy Policy and seek to ensure that overseas recipients comply with applicable privacy laws.<br />
Retention of Data<br />
VUniverse Inc. will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.<br />
VUniverse Inc. will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.<br />
Transfer of Data<br />
Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.<br />
If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.<br />
Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.<br />
VUniverse Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.<br />
Disclosure of Data<br />
Business Transaction<br />
If VUniverse Inc. is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.<br />
Legal Requirements<br />
VUniverse Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:<br />
• To comply with a legal obligation<br />
• To protect and defend the rights or property of VUniverse Inc.<br />
• To prevent or investigate possible wrongdoing in connection with the Service<br />
• To protect the personal safety of users of the Service or the public<br />
• To protect against legal liability<br />
Security of Data<br />
The security of your data is of paramount importance to us and we will not knowingly transfer that data for any purposes other than those outlined in this privacy policy. We will never sell personal data to advertisers or other organizations interested in learning your specific interests, opinions or usage. Users should remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially reasonable means to protect your Personal Data, we cannot guarantee its absolute security through means outside our control. <br />
Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)<br />
Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.<br />
You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser. The VU application does not have specific functionality related to "Do Not Track," but may in the future.<br />
Your Data Protection Rights under the General Data Protection Regulation (GDPR)<br />
If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Vuniverse Inc. aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.<br />
If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us at help@vuniverse.com.<br />
In certain circumstances, you have the following data protection rights:<br />
The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.<br />
The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.<br />
The right to object. You have the right to object to our processing of your Personal Data.<br />
The right of restriction. You have the right to request that we restrict the processing of your personal information.<br />
The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format. There may be a charge associated with the preparation of this report.<br />
The right to withdraw consent. You also have the right to withdraw your consent at any time where Vuniverse Inc. relied on your consent to process your personal information.<br />
Please note that we may ask you to verify your identity before responding to such requests.<br />
You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).<br />
Service Providers<br />
We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.<br />
These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.<br />
Analytics<br />
We may use third-party Service Providers to monitor and analyze the anonomized, aggregated use of our Service such as Google Analytics. This aggregated data may be used by Google to contextualize and personalize the ads of its own advertising network. <br />
For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en <br />
Advertising<br />
VUniverse is a subscription-only service that does not offer advertisers the opportunity to access users' data. Therefore, your data will not be used by third-party Service Providers to show advertisements to you.  <br />
Payments<br />
We provide paid services and/or products within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).<br />
We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.<br />
The payment processors we work with are Stripe, Apple iOS store and Google Play store. Each has their own privacy policies found on their respective platforms.<br />
Links to Other Sites<br />
Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br />
We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.<br />
Children's Privacy<br />
Our Service can be used by children between the ages of 13 to 18 ("Children" or a “Child”). If you are a parent or guardian (collectively, “Parent”), prior to a Child setting up an account or using our Service, it is incumbent on you to monitor your minor's use of the system. If you are aware that your Child has provided us with Personal Data, please contact us at help@vuniverse.com. Parents always have the ability to review and control how we collect their Child’s information. If we become aware that we have collected Personal Data from Children, we take steps to remove that information from our servers.<br />
Cookies and Local Storage<br />
If you prefer, you can usually choose to set your browser to remove or reject browser cookies or to clear local storage. Please note that if you choose to remove or reject cookies or clear local storage, this could affect the availability and functionality of our Services.<br />
Location Information<br />
The VUniverse App does not use or store your location. You can turn location-based services off by adjusting the settings of your mobile device or web browser.<br />
Account Information and Requests<br />
In accordance with applicable law, you may have the right to access, update or delete personal information we hold about you, including deleting Content Viewing Information. This means you may have the right to 1) be informed of the personal information we collect, how we use it, what personal information we have shared with third parties, and to be provided with a copy of that information; and 2) have corrections made, or have the information deleted if it is not accurate or was processed in violation of applicable laws. You may be able to exercise these rights by logging into your online account within the Vuniverse products and services that you use. Otherwise, you may submit a written request using the details provided in the Contact Us section below.<br />
Further, if you are from certain territories, you may have the right to exercise additional rights available to you under applicable laws, including broader rights to access and erasure of your personal information, the right to object to or restrict processing of your personal information, or the right to data portability in certain circumstances. If you would like to make such a request, please contact us at help@vuniverse.com or at the address provided below in the Contact Us section.<br />
We will consider all requests in accordance with applicable laws. To obtain access to your personal information, we may require that you provide additional information to confirm your identity. This confirming information will only be used to process your request. To correct any information we have about you, please send a correction request following the same instructions as above.<br />
Remember that in certain jurisdictions you also have the right to complain to a data protection authority (“DPA”) about our collection and use of your personal information. For more information, please contact your local DPA and Information Commissioner.<br />
Retention of Information<br />
We will generally retain your personal information for as long as is needed to fulfil the purposes outlined in this Privacy Policy. However, we may retain certain information as required by the applicable law (such as tax, accounting or other legal requirements), or for legitimate business purposes. When we have no basis to continue processing your personal information, we will either delete or anonymize it in accordance with applicable laws (though we may need to retain cached or archived copies for a certain period until deletion is possible).<br />
We may store Content Viewership Information so long as you continue to have a relationship with our Services. Content Viewing Information may be stored up to ten (10) years from the last time our Services encounter a particular device unless you ask us to delete that information by following the instructions above.<br />
Promotional Communications<br />
From time to time, we may send you marketing communications about our products and services, in accordance with your preferences. You may opt out of receiving promotional messages from us at any time by following the instructions in those messages (often it will be a notice or link at the bottom of the message). If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.<br />
Mobile Push Notifications<br />
With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.<br />
Withdrawal of Consent<br />
Where we rely on your consent to process your personal information, you have the right to withdraw your consent at any time. This may impact your ability to use our services and/or products.<br />
Changes<br />
We may change this policy from time to time. If we make changes, we will revise the date at the top of the policy and provide notice. Such notice may take various forms, such as updating the date on the top of the policy, adding a statement to our website, sending you an email, or providing an on-screen notification on your devices.<br />
Contact Us<br />
If you have any questions or complaints regarding this policy or about Vuniverse’s privacy practices, please contact us at info@vuniverse.com or at:<br />
Vuniverse, Inc.<br />
(Attn: Privacy)<br />
225 W Center Street<br />
Kinsport, TN 37660<br />
<br />
USA

      </pre>
    </>
  )
}
