import { Container, Col, Row, Button, Form } from 'react-bootstrap'
import './Pages.scss'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { api_client } from '../../config/api-client'

export default function Contact() {
  const user = useSelector(state => state.user)
  const [email, setEmail] = useState(user && user.email ? user.email : '')
  const [validEmail, setValidEmail] = useState(false)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [messageSent, setMessageSent] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(true)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})

  const sendMessage = () => {
    if(message.length < 5 || !validEmail || subject.length < 1) {
      setShowErrors(true)
      return
    }
    const accessToken = localStorage.getItem('access_token')
    setSaveEnabled(false)
    api_client(accessToken).post('/api/v1/contact.json', {
      contact: {
        subject: subject,
        message: message,
        email: email,
      }
    }).then((response) => {
      setShowErrors(false)
      setSaveEnabled(true)
      setMessageSent(true)
    }).catch((error) => {
      setSaveEnabled(true)
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to send contact message' })
      }
    })
  }

  useEffect(() => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(regex.test(email) === false){
      if(validEmail) {
        setValidEmail(false)
      }
    } else {
      if(!validEmail) {
        setValidEmail(true)
      }
    }
  }, [email])

  useEffect(() => {
    if(!messageSent) {
      if(message !== '') setMessage('')
      if(subject !== '') setSubject('')
    }
  }, [messageSent])

  return (
    <>
      {!messageSent && <Container className="page-container">
        <Row>
          <h1>Contact</h1>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8" style={{ marginTop: '10px'}}>
            <Form.Control placeholder="Email *" rows={4} onChange={(e) => { setEmail(e.target.value) }} value={email} />
            {showErrors && !validEmail && <span className="field-error">Email is required!</span>}
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8" style={{ marginTop: '10px'}}>
            <Form.Control placeholder="Subject *" rows={4} onChange={(e) => { setSubject(e.target.value) }} value={subject} />
            {showErrors && subject.length < 1 && <span className="field-error">Subject is required!</span>}
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8" style={{ marginTop: '10px'}}>
            <Form.Control as="textarea" placeholder="Insert message here *" rows={4} onChange={(e) => { setMessage(e.target.value) }} value={message} />
            {showErrors && message.length < 5 && <span className="field-error">Type your message, at least 5 characters.</span>}
          </Col>
          <Col md="2"></Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8" style={{ textAlign: 'center' }}>
            <button disabled={!saveEnabled} className="btn-setting" type="button" onClick={sendMessage}>Send</button>
          </Col>
          <Col md="2"></Col>
        </Row>
      </Container>}

      {messageSent && <Container className="page-container">
        <Row>
          <h1>Contact</h1>
        </Row>
        <Row>
          <Col>Your contact message was successfully sent!</Col>
        </Row>
        <Row>
          <Col>Thank you for contacting us.</Col>
        </Row>
        <Row>
          <Col className="setting-link"><a href="javascript: void(0)" onClick={() => { setMessageSent(false) }}>New contact message</a></Col>
        </Row>
      </Container>}
    </>
  )
}
