import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setSearchQuery } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'
import { setLoggedInUser } from '../../actions/user'
import MediaBrowserFilter from '../MediaBrowser/MediaBrowserFilter'
import { Container } from "react-bootstrap"
import ManageLists from '../MediaBrowser/ManageLists'
import useHideMedium from './hooks/useHideMedium'
 
var tabMyListsTimeout = false
export default function TabMyLists({
  initialSelection,
  setSelection,
  selection,
  match,
}) {

  const user = useSelector(state => state.user)
  const [userLists, setUserLists] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const user_lists_medium = useSelector((state) => state.user_lists_medium)
  const user_medium_rating = useSelector((state) => state.user_medium_rating)

  const [ allStreaming, setAllStreaming ] = useState(user.preferences.filter_all_streaming_services === 'true')
  const [ mediaType, setMediaType ] = useState(user.preferences.filter_media_type)
  const [ lastFilter, setLastFilter ] = useState({})

  const scheduleFetchUserLists = (loadMore) => {
    if(tabMyListsTimeout) {
      clearTimeout(tabMyListsTimeout)
    }
    tabMyListsTimeout = setTimeout(function() {
      fetchUserLists(true, loadMore)
      tabMyListsTimeout = false
    }.bind(this), 1000)
  }

  const fetchUserLists = async (fromTimeout, loadMore) => {
    if(!fromTimeout) {
      scheduleFetchUserLists(loadMore)
      return
    }

    var page = 1
    if(userLists.current_page && loadMore) {
      page = userLists.current_page + 1
    }
    setLoading(true)
    const access_token = localStorage.getItem('access_token')
    var requestParams = addParams({selection: 'my_lists', page: page })
    setLastFilter(requestParams)
    
    api_client(access_token).get('/api/v2/media', { params: requestParams }).then(res => {
      const formatted = {...res.data.pagination, grouped_media: res.data.grouped_media }
      if(page == 1) {
        setUserLists(formatted)
      } else if(page > 1) {
        setUserLists({...formatted, grouped_media: [...userLists.grouped_media, ...res.data.grouped_media]})
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  useHideMedium({fetchItems: fetchUserLists, groupedGetSet: [[userLists, setUserLists]]})
  
  const addParams = (p) => {
    return {...p, all_streaming: allStreaming, media_type: mediaType}
  }

  const updateAllStreaming = () => {
    updatePreference('filter_all_streaming_services', allStreaming)
  }

  const updateMediaType = () => {
    updatePreference('filter_media_type', mediaType)
  }

  const updatePreference = (prefKey, prefValue) => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(
      '/api/v1/user_preferences/save',
      { user_preference: { key: prefKey, value: prefValue }}
    ).then(res => {
      const updatedUser = {...user, preferences: {...user.preferences, [prefKey]: prefValue.toString()}}
      dispatch(setLoggedInUser(updatedUser, access_token))
    }).catch((error) => {
      errorHandler(error, dispatch)
    })
  }

  useEffect(() => {
    dispatch(setSearchQuery(''))
    if(selection !== initialSelection) setSelection(initialSelection)
  }, [])

  useEffect(() => {
    fetchUserLists()
  }, [user_lists_medium, user_medium_rating])


  useEffect(() => {
    if(userLists.length < 1 || lastFilter !== addParams({})) {
      fetchUserLists()
    }
    dispatch(setSearchQuery(''))
    if(selection !== initialSelection) setSelection(initialSelection)
    const user_all_streaming = user.preferences.filter_all_streaming_services === 'true'
    if(user_all_streaming !== allStreaming) {
      updateAllStreaming()
    }
    if(user.preferences.filter_media_type !== mediaType) {
      updateMediaType()
    }
  }, [allStreaming, mediaType])

  useEffect(() => {
    var newValue = user.preferences.filter_all_streaming_services === 'true'
    if(allStreaming !== newValue) {
      setAllStreaming(newValue)
    }
    newValue = user.preferences.filter_media_type
    if(mediaType !== newValue) {
      setMediaType(newValue)
    }
  },[user])

  return (
    <div className="tab-content">
      <Container>
        <MediaBrowserFilter allStreaming={allStreaming} setAllStreaming={setAllStreaming} mediaType={mediaType} setMediaType={setMediaType} />
      </Container>
      <ManageLists reloadLists={fetchUserLists} />
      <MediaBrowser mediaReload={fetchUserLists} loading={loading} setSelection={setSelection} selection={selection} initialSelection={'my_lists'} userLists={userLists} />
    </div>
  );
}
