import { Container, Col, Row } from 'react-bootstrap'
import './Pages.scss'
import FaqItem from './FaqItem'
import { DEFAULT_EMAIL } from '../../config/constants'

export default function Faq() {
  const content = [
    ['What is VU by VUniverse?', 'VU is a personalized movie & TV show recommendation platform that serves as a channel guide across your streaming services. Using artificial intelligence, VU creates a unique taste profile for every user and serves up smart lists of curated titles using mood, genre, and user-generated tags, all based on content from the user’s existing subscription services. Users will also be able to create custom watchlists and share them with family and friends.'],
    ['Is VUniverse a streaming service?', 'No. We’re not a streaming service. We act as a companion to your existing services, making it faster and easier to find content that you’ll love, taking you directly to the titles you want to watch. From there you can either watch on your mobile device or cast to your TV.'],
    ['Can I watch movies and shows on VU?', 'In order to watch a movie or show that you discover through our platform, you’ll still need to have at least one subscription to a streaming service (Netflix, Prime, Hulu, HBO, etc.) but we’ll never ask for your login information.'],
    ['Is VU free?', 'No, it\'s a subscription Software as a Service (SaaS) that ensures your data is protected and the movies and shows being suggested result from an unadulterated algorithm free from ad-related influence.  There’s a saying that “if you’re not paying for the service, YOU are the service.” Our survey respondents have indicated they’re tired of their information (even email addresses) being shared without their knowledge, so you can rest assured that your information stays with us. Additionally, because VU offers unbiased real-time streaming information, if a movie or show is hosted for free on one of your services and you thought you had to pay for it on another service, you’ve saved at least the monthly subscription fee in one shot. That’s not to mention the time wasted hunting for which of your services has that movie or show. '],
    ['Where can I use VU?', 'The web version of VU2.0 has launched to https://vuniverse.com . It’s coming soon to mobile app stores, but we encourage users to create accounts on the website so you can easily log in on your mobile app when that deploys. You can use VU from your mobile browser by going to the weblink on Safari, Chrome, etc., however, it’s not designed for mobile apps, so the user interface is not as pleasing as it is on a computer screen. Be patient! The functionality is solid and you can still build your recommendations and Watch Lists before mobile deploys. Give it a try! Future versions will be integrated with set top boxes, like Roku, but for now, after clicking a link to a show on VU from your browser, you can cast to your TV.'],
    ['Why am I seeing titles that are on services other than my selected streaming providers?', 'Because so much new exciting content is released in theaters and on streaming services, we launched VU 2.0 showing it all. Your selected streaming provider logos are in bold vs. the others showing as faded. We have upped the priority of a feature that allows you to choose whether you want to see it all or would rather the results be limited to your selected services. Coming within the next few weeks. Stay tuned!'],
    ['How do I get started?', 'It’s easy! You just need to follow the REGISTER steps. Everyone gets a free 90-days trial before the subscription kicks in, so you can jump in and get started finding what you want to watch based on genres and where it’s streaming. If you’d like personalized recommendations, you’ll need to rate movies and shows from the Rating page or that you just decide to rate. Tagging is an added bonus and will result in more refined recommendations.'],
    ['How can I influence future versions of VU?', 'We’re a startup with a dedicated team working as quickly as we can to provide this much-needed service. That said, we’ve still got a little ways to go until the platform is as full-featured as our vision. We are always looking for feedback on what features are most important to you and welcome any suggested improvements. We appreciate your patience with us as we grow. To send us feedback or features you’d like to see incorporated, drop us a note at help@vuniverse.com'],
    ['What happened to the waitlist?', 'During development, we opened the waitlist for early adopters to test our beta application. Their feedback was invaluable in creating VU2.0. After the early adopter phase closed, the waitlist allowed interested subscribers to receive up-to-date notifications on development and release of the product. These fans received invitations to sign up before we announced it to the general public along with other tokens of our appreciation. Now that we’ve launched to the public, this functionality is no longer required, but we sure do appreciate those who jumped in and stayed with us during the rollercoaster ride of the pandemic.'],
    ['Why don’t you have every streaming service on here?', 'We have the most popular streaming providers and are working on agreements to add more. If there are specific streaming providers you’d like us to add, let us know at help@vuniverse.com'],
    ['Is VU only available in the US?', 'Yes, for now.'],
    ['How can I provide feedback on the product?', `There’s a feedback form on your Settings page which you can access at any time, or you can email  ${DEFAULT_EMAIL}. We appreciate all our early adopters kicking the tires and we’re responding to feedback as fast as we can with enhancements being released just about every week.`],
    ['I have more questions, how can I contact you?', `For inquiries about our company or VU by Vuniverse, email ${DEFAULT_EMAIL}.`],
    ['Can I go now?', 'Yes!'],
  ]
  return (
    <Container className="page-container">
      <Row>
        <h1 style={{marginBottom: '40px'}}>Frequently Asked Questions</h1>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Container className="faq">
            {content.map((item) => {
              return <FaqItem item={item} />
            })}
          </Container>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  )
}