import React, { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import { Row, Col } from 'react-bootstrap'
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.css"
import { useSelector } from 'react-redux'
import './PlanListItem.scss'
import ConfirmDialog from '../Settings/ConfirmDialog'

export default function PlanListItem({ item }) {
  const current_user = useSelector((state) => state.user)
  const [showingConfirmation, setShowingConfirmation] = useState(false)
  const [priceId, setPriceId] = useState(false)
  const [inOtherPlan, setInOtherPlan] = useState(false)
  const [isCurrentPlan, setIsCurrentPlan] = useState(false)
  const [ canSelect, setCanSelect ] = useState(false)
  const isSinglePayment = !item.pricings[0].is_recurring

  const confirmChangePlan = () => {
    setShowingConfirmation(true)
  }

  const managePlan = () => {
    window.open('https://billing.stripe.com/p/login/cN2288cAZ7RP1KUaEE', '_blank')
  }

  const changePlan = () => {
    const basePath = window.location.protocol + '//' + window.location.host
    const successUrl = basePath + '/checkout_success/?session_id={CHECKOUT_SESSION_ID}'
    const cancelUrl = basePath + '/checkout_canceled'
    api_client(localStorage.getItem('access_token')).post('/api/v1/plans/change.json', {
      price_id: priceId,
      success_url: successUrl,
      cancel_url: cancelUrl,
      plan_id: item.plan_id,
    }).then((response) => {
      const redirectUrl = response.data.redirect_url
      window.location.href = redirectUrl
    }).catch((error) => {
      if(error.response && error.response.data.error) {
        alert(error.response.data.message)
      } else {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    var firstPrice = false
    for(var i = 0; i < item.pricings.length; i++) {
      let pricing = item.pricings[i]
      firstPrice = pricing
      break
    }
    setIsCurrentPlan(current_user.product_id === item.id)
    if(current_user.product_id && current_user.product_id.length > 1 && current_user.product_id !== item.id) {
      setInOtherPlan(true)
    }
    var currentPrice = null
    if(firstPrice) {
      if(firstPrice.id === current_user.price_id) {
        currentPrice = firstPrice
      }
      setPriceId(firstPrice.id)
    }
  }, [])

  useEffect(() => {
    setCanSelect((!isCurrentPlan || (current_user.subscription_canceled && !current_user.subscribed)))
  }, [isCurrentPlan, current_user])

  return(
    <>
      <ConfirmDialog
        visible={showingConfirmation}
        setVisible={setShowingConfirmation}
        title={"Subscribe"}
        prompt={(
          <>
            {!inOtherPlan && <span>Are you sure you want to subscribe to <b>{item.name}</b>?</span>}
            {inOtherPlan && <span>Are you sure you want to switch from <b>{current_user.product_name}</b> to <b>{item.name}</b>?</span>}
          </>
        )}
        onConfirm={changePlan}
        buttonLabel="Yes, I am sure"
      />
      {priceId && <Row className={isCurrentPlan ? 'plan current-plan' : 'plan'}  onClick={() => { !canSelect ? console.log('') : confirmChangePlan() }}>
        <Col md={7} className="left-aligned">
          <div className="plan-name">{item.name}</div>
          {isSinglePayment && <div className="plan-price"><br />{item.description}</div>}
          {!isSinglePayment && <div className="plan-price">{item.description}</div>}
        </Col>
        {isCurrentPlan && current_user.subscription_status === 'past_due' && <button type="button" className="btn" style={{ backgroundColor: '#f1af3b', marginTop: '15px'}} onClick={managePlan}>Manage</button>}
        {canSelect && <Col md={5} className="right-aligned" style={{paddingLeft: '0px'}}>
          {isSinglePayment && <div>${item.pricings[0].price_in_cents / 100}/One Time</div>}
          <Button variant="primary">Select</Button>
        </Col>}
      </Row>}
    </>
  )
}
