import { api_client } from '../../config/api-client'
import { useState, useEffect } from "react"
import { Container, Row, Col, Button, Card } from "react-bootstrap"
import { BiMoviePlay } from 'react-icons/bi'
import './Media.scss'
import { HiStar, HiOutlineStar } from 'react-icons/hi'
import { MdOutlineLibraryAdd, MdLibraryAdd } from 'react-icons/md'
import { setListModalData } from '../../actions/list_modal_data'
import { setRatingModalData } from '../../actions/rating_modal_data'
import { useDispatch } from 'react-redux'
import ShareOptions from './ShareOptions'
import { useNavigate } from 'react-router-dom'
import { setMediaModalData } from '../../actions/media_modal_data'
import favoriteRateAndListHandler from './favoriteRateAndListHandler'

var mediaModalTimeout = false

export default function Media({ medium, handleClose }) {
  const [ media, setMedia ] = useState(medium)
  const [isFavorite, hasList, rating] = favoriteRateAndListHandler(media)
  const [ showingTrailer, setShowingTrailer ] = useState(false)
  const [ mediaLoaded, setMediaLoaded ] = useState(false)
  const [ videoURL, setVideoURL ] = useState(false)
  const access_token = localStorage.getItem('access_token')
  const notLogged = !access_token || access_token === false || access_token === 'false' || access_token === undefined || access_token.length === 0
  const isLoggedIn = !notLogged
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const scheduleFetchMedia = () => {
    if(mediaModalTimeout) {
      clearTimeout(mediaModalTimeout)
    }
    mediaModalTimeout = setTimeout(function() {
      fetchMedia()
      mediaModalTimeout = false
    }.bind(this), 500)
  }

  const fetchMedia = async () => {
    if(media.id === medium.id && media.providers) {
      return
    }
    const apiReq = notLogged ? api_client() : api_client(access_token)
    const response = await apiReq.get(`/api/v1/media/${medium.id}`,
      { headers: {
        Authorization: 'reactrescde#1d4234kadflakdfqzz'
      }}
    )
    setMediaLoaded(true)
    setMedia(response.data.media)
  }

  useEffect(() => {
    scheduleFetchMedia()
  }, [])

  useEffect(() => {
    if(media.video_url) {
      setShowingTrailer(true)
      setVideoURL(media.video_url)
    } else {
      setVideoURL(false)
    }
  }, [media])

  const userRating = (id) => {
    dispatch(setRatingModalData({ visible: true, medium: medium }))
  }

  const addToList = (medium) => {
    dispatch(setListModalData({ visible: true, medium: medium }))
  }

  const navigateToStreaming = (url, target) => {
    window.open(url, target)
  }

  const createInteraction = (provider_medium) => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(`/api/v1/user_interactions.json`, {
      user_interaction: {
        medium_id: medium.id,
        provider_medium_id: provider_medium.id
      }
    }).then(res => {
      navigateToStreaming(provider_medium.url, provider_medium.name)
    })
  }

  const showPerson = (person_id) => {
    dispatch(setMediaModalData({ visible: false, medium: {} }))
    navigate(`/people/${person_id}`)
  }

  return (
    <Container className="media-container">
      <Row>
        <Col md={4} className="left-column">
          <div className="media-title">{media.title}</div>
          <span className="media-type">{media.type}</span>
          <div className='clear' />
          <Card.Img src={media.poster_url} className="media-main-image" draggable={false} />
          <div className='clear' />
          {isLoggedIn && <span onClick={() => { userRating(medium.id) }} className="favorite-button"><div className="star-container">{isFavorite ? <HiStar /> : <HiOutlineStar />}</div><div className="star-rating-value" style={{ cursor: 'default' }}>{rating}</div></span>}
          {isLoggedIn && <span onClick={() => { addToList(medium) }} className="add-to-list-button">{hasList ? <MdLibraryAdd title="Add or Edit Lists" /> : <MdOutlineLibraryAdd title="Add or Edit Lists" />}</span>}
          <div className='clear' />
          <div className="details-container">
            <span className="label">Details: </span>
            <span className="label-value">{media.release_year}</span><br/>
            {media.genres && <>
              <span className="label">Genres: </span>
              <span className="label-value">{media.genres}</span>
            <br/></>}
            {media.directors && media.directors.length > 0 && <>
              <span className="label">Director(s): </span>
              <span className="label-value">{media.directors.map((person, index) => (
                <span>
                  <a href="javascript: void(0)" onClick={() => showPerson(person.person_id)}>{person.name}</a>
                  {index < media.directors.length - 1 && <span>{", "}</span>}
                </span>
              ))}</span>
            <br/></>}
            {media.cast && media.cast.length > 0 && <>
              <span className="label">Cast: </span>
              <span className="label-value">{media.cast.map((person, index) => (
                <span>
                  <a href="javascript: void(0)" onClick={() => showPerson(person.person_id)}>{person.name}</a>
                  {index < media.cast.length - 1 && <span>{", "}</span>}
                </span>
              ))}</span>
            <br/></>}
          </div>
        </Col>
        <Col md={8}>
          {!showingTrailer && !media.backdrop_url && <div className="no-preview">{mediaLoaded ? "no preview available" : "loading preview..."}</div>}
          {!showingTrailer && media.backdrop_url && <Card.Img variant="top" src={media.backdrop_url} className="backdrop-image" draggable={false} />}
          {!showingTrailer && videoURL && <div className="trailer-button-container">
            <span className="trailer-button"><BiMoviePlay onClick={() => { setShowingTrailer(true) }}/></span>
          </div>}
          {showingTrailer && <div className="video-responsive">
            <iframe
              type="text/html"
              width="853"
              height="480"
              src={videoURL}
              frameBorder="0"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allow-navigation"
              allowFullScreen
              webkitallowfullscreen
              mozallowfullscreen
              title={media.title}
            />
          </div>}
          {isLoggedIn && <div className='streaming-providers'>
            { media.providers && media.providers.map(provider => (
              <div className={`provider-container ${provider.selected ? 'selected-provider' : 'unselected-provider'}`}>
                <a onClick={() => createInteraction(provider) } href='javascript:void(0);'>
                  <img className="streaming-icon" src={provider.image} alt={provider.name} />
                </a>
                <div className={provider.price > 0.0 ? 'with-price' : 'no-price'}>{provider.price > 0.0 ? `$${provider.price}` : ''}</div>
              </div>
            ))}
            {(!media.providers || media.providers.length == 0) && <span>Streaming info... coming soon!</span>}
          </div>}
          {!isLoggedIn && <div className='streaming-providers'>
            <div onClick={handleClose} style={{ marginTop: '8px', color: 'cyan', textAlign: 'center', cursor: 'pointer', textDecoration: 'underline'}}>Log in or sign up to see streaming provider(s) information.</div>
          </div>}
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ marginTop: '10px'}}>
          {media.overview && <>
            <span className="label">Synopsis: </span>
            <span className="label-value">{media.overview}</span>
          </>}
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ marginTop: '10px'}}>
          <ShareOptions medium={media} />
        </Col>
      </Row>
    </Container>
  );
}
