import { Container, Row, Col, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import './SettingTab.scss'

export default function Feedback({}) {
  const user = useSelector((state) => state.user)
  const [content, setContent] = useState('')
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [errors, setErrors] = useState({})

  const sendFeedback = () => {
    const accessToken = localStorage.getItem('access_token')
    setSaveEnabled(false)
    api_client(accessToken).post('/api/v1/feedback.json', {
      feedback: {
        content: content
      }
    }).then((response) => {
      setSaveEnabled(true)
      setFeedbackSent(true)
    }).catch((error) => {
      setSaveEnabled(true)
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to send feedback' })
      }
    })
  }

  useEffect(() => {
    if(content.length > 4) {
      if(!saveEnabled) setSaveEnabled(true)
    } else {
      if(saveEnabled) setSaveEnabled(false)
    }
  }, [content])

  useEffect(() => {
    if(!feedbackSent) {
      if(content !== '') setContent('')
    }
  }, [feedbackSent])

  return (
    <>
      {!feedbackSent && <Container className="feedback-container">
        <Row>
          <Col md="2"></Col>
          <Col md="10">Tell us how we're doing</Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="10" style={{ fontSize: '.8rem', marginTop: '10px', color: '#cecece' }}>Have a suggestion? Missing a poster? Find a glitch? We're still growing and would love to hear your questions, comments, concerns, and/or ideas.</Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="10" style={{ marginTop: '10px'}}>
            <Form.Control as="textarea" placeholder="Enter your feedback" rows={4} onChange={(e) => { setContent(e.target.value) }} value={content} />
          </Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="10" style={{ textAlign: 'center' }}>
            <button disabled={!saveEnabled} className="btn-setting" type="button" onClick={sendFeedback}>Submit</button>
          </Col>
        </Row>
      </Container>}
      {feedbackSent && <Container>
        <Row>
          <Col md="2"></Col>
          <Col md="10">Your feedback was successfully sent!</Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="10">Thank you for sending us your feedback.</Col>
        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="10" className="setting-link"><a href="javascript: void(0)" onClick={() => { setFeedbackSent(false) }}>New feedback</a></Col>
        </Row>
      </Container>}
    </>
  )
}
