import { Modal, Button } from 'react-bootstrap'
import { Form, Container } from 'react-bootstrap'
import './MediumProviderModal.scss'
import { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import UserMediumGenreSelector from '../Signup/UserMediumGenreSelector'
import './GenreModal.scss'

export default function GenreModal({ modalVisible, setModalVisible }) {
  const [userMediumGenres, setUserMediumGenres] = useState([])
  const [selectedGenresIds, setSelectedGenresIds] = useState([])
  const [errors, setErrors] = useState({})

  const handleClose = () => {
    setModalVisible(false)
  }

  const fetchGenres = () => {
    const accessToken = localStorage.getItem('access_token')
    api_client(accessToken).get('/api/v1/user_medium_genres').then((response) => {
      const userMediumGenres = response.data.user_medium_genres.sort((a, b) => `${(a.active ? '0' : '1')}${a.medium_genre.title}`.localeCompare(`${(b.active ? '0' : '1')}${b.medium_genre.title}`))
      const activeGenres = []
      for(var i = 0; i < userMediumGenres.length; i++) {
        if(userMediumGenres[i].active) {
          activeGenres.push(userMediumGenres[i].id)
        }
      }
      setSelectedGenresIds(activeGenres)
      const c = userMediumGenres[0]
      console.log(`${(c.active ? '0' : '1')}${c.medium_genre.title}`)
      setUserMediumGenres(userMediumGenres)
    })
  }

  const genreIsSelected = (genreId) => {
    return selectedGenresIds.some(item => genreId === item)
  }

  const changeGenreSelected = (genreId, newValue) => {
    if(newValue) {
      const newArray = [ ...selectedGenresIds, genreId]
      setSelectedGenresIds(newArray)
    } else {
      const newArray = selectedGenresIds.filter(function(selectedGenreId) {
        return selectedGenreId !== genreId
      })
      setSelectedGenresIds(newArray)
    }
  }

  const updateSelectedGenres = () => {
    api_client(localStorage.getItem('access_token')).post('/api/v1/user_medium_genres/update_all.json', {
      genres_ids: selectedGenresIds
    }).then((response) => {
      setModalVisible(false)
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to update genres' })
      }
    })
  }

  useEffect(() => {
    if(modalVisible) fetchGenres()
  }, [modalVisible])

  return (
    <>
      <Modal
        show={modalVisible}
        onHide={handleClose}
        backdrop="static"
        className="genre-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Genre Preference</Modal.Title>
        </Modal.Header>

        <Modal.Body className="genre-form">
          {errors && errors.user && <div className='error'>{errors.user}</div>}
          <Form.Group controlId='medium_genres'>
            <Form.Label>What genres do you prefer?</Form.Label>
            <Container fluid>
              {userMediumGenres.length > 0 && userMediumGenres.map((user_medium_genre, index) => <UserMediumGenreSelector key={user_medium_genre.id} user_medium_genre={user_medium_genre} isSelected={genreIsSelected(user_medium_genre.id)} changeSelected={changeGenreSelected}/> )}
            </Container>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={updateSelectedGenres}>Save changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
