import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMediaModalData } from '../../../actions/media_modal_data'
import { setUserMediumHiddenSaved } from '../../../actions/user_medium_hidden'
import './MediaCard.scss'
import { useNavigate } from 'react-router-dom'
import favoriteRateAndListHandler from '../../Media/favoriteRateAndListHandler'
import ImageWithOverlays from './ImageWithOverlays'
import LoadMoreCard from './LoadMoreCard'
 
export default function MediaCard({ medium, location, mediaReload, selection, sortTitles}) {
  if(medium.is_reload) {
    return <LoadMoreCard mediaReload={mediaReload} />
  }
  const user_medium_hidden = useSelector((state) => state.user_medium_hidden)
  const [overlayVisible, setOverlayVisible] = useState(false)
  const [cardVisible, setCardVisible] = useState(true)
  const [isFavorite, hasList, rating] = favoriteRateAndListHandler(medium)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if(!cardVisible) {
      const timer = setTimeout(() => {
        dispatch(setUserMediumHiddenSaved({
          hide_count: user_medium_hidden.hide_count + 1,
          medium_id: medium.id,
          is_hidden: selection !== 'settings'
        }))
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [cardVisible])

  const showMedia = () => {
    var path = `${location.pathname}`
    if(path === '/') {
      path = `/discover/media/${medium.id}${location.search}`
    } else {
      path = `${path}/media/${medium.id}${location.search}`
    }
    navigate(path)
    dispatch(setMediaModalData({ visible: true, medium: medium }))
  }

  const showOverlay = () => {
    if(!overlayVisible) {
      setOverlayVisible(true)
    }
  }

  const hideOverlay = () => {
    if(overlayVisible) {
      setOverlayVisible(false)
    }
  }

  const indicatorClass = () => {
    if(!medium.likelyhood) {
      return ""
    }
    if(medium.likelyhood < 4) {
      return "red-indicator"
    } else if (medium.likelyhood < 8) {
      return "yellow-indicator"
    } else {
      return "green-indicator"
    }
  }

  return(
    <div className={'media-card-container' + (!overlayVisible && medium.no_providers ? " no-providers" : '')} onMouseEnter={showOverlay} onMouseLeave={hideOverlay} style={(cardVisible ? {} : {transition: "opacity 1s ease-in-out", opacity: 0})}>
      <ImageWithOverlays 
        mediaReload={mediaReload}
        selection={selection}
        setCardVisible={setCardVisible}
        medium={medium}
        showMedia={showMedia}
        overlayVisible={overlayVisible && !sortTitles}
        isFavorite={isFavorite}
        rating={rating}
        dispatch={dispatch}
        hasList={hasList}
        sortTitles={sortTitles}
      />
      <div className='title-container' onClick={showMedia}>
        <div className='media-card-title'>{medium.title}</div>
      </div>
      <div className='bottom-with-border'>
        <div className={indicatorClass()}>&nbsp;</div>
      </div>
    </div>
  )
}