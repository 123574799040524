
export default function AutocompleteItem({ medium }) {
  return (
    <div className={`autocomplete-item ${medium.already_added ? 'autocomplete-item-selected' : ''}`}>
      <img src={medium.poster_url} />
      <div className="details-container">
        <div className="item-title">{medium.title}</div>
        <div>{medium.release_year}</div>
        <div className="item-overview">{medium.overview}</div>
      </div>
      <div style={{ clear: 'both'}} />
    </div>
  )
}