import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { api_client } from '../../config/api-client'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from '../../config/constants'

export default function ResetPasswordForm({ forgot }) {
  const user = useSelector((state) => state.user)
  const [email, setEmail] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [errors, setErrors] = useState('')
  const [passwordReseted, setPasswordReseted] = useState(false)
  const [requestedNewPassword, setRequestedNewPassword] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const reset_password_token = searchParams.get('reset_password_token')

  const submit = () => {
    setErrors('')
    api_client().patch('/users/password.json', {
      user: {
        reset_password_token: reset_password_token,
        password_confirmation: passwordConfirmation,
        password: password
      }
    }).then((response) => {
      if(response.status === 204) {
        setPasswordReseted(true)
      }
    }).catch((error) => {
      console.log(error)
      if(error.reponse) {
        if(error.response.data.reset_password_token) {
          setErrors('Reset password token ' + error.response.data.reset_password_token)
        }
      } else {
        setErrors('Unknown error trying to reset password')
      }
    })
  }

  const requestToResetPassword = () => {
    setErrors('')
    setRequestedNewPassword(false)
    api_client().post('/users/password.json', {
      user: {
        email: email
      }
    }).then((response) => {
      if(response.status === 201) {
        setRequestedNewPassword(true)
      } 
    }).catch((error) => {
      console.log(error.response)
      if(error.response) {
        if(error.response.status === 406) {
          setRequestedNewPassword(true)
        } else if(error.response.status === 422) {
          setErrors('Email not found')
        } else {
          setErrors('Unknown error trying to request password reset' )
        }
      } else {
        setErrors('Unknown error trying to request password reset')
      }
    })
  }

  useEffect(() => {
    if(password.length >= MIN_PASSWORD_LENGTH && password.length <= MAX_PASSWORD_LENGTH && /\d/.test(password)) {
      if(!validPassword) setValidPassword(true)
    } else {
      if(validPassword) setValidPassword(false)
    }
  }, [password])

  return (
    <>
      {forgot && <div className="reset-password-form">
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <div className="shadowed-box">
                <h2>Forgot Password</h2>
                {!requestedNewPassword && <>
                  {errors && <div className='error'>{errors}</div>}
                  <div style={{ textAlign: 'left' }}>
                    Enter your email address and we will send to you a link to reset your password.
                  </div>
                  <Form.Group controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={(event) => { setEmail(event.target.value) }}/>
                  </Form.Group>
                  <div className='centered'>
                    <Button className="btn-success top-margin-button top-20" onClick={requestToResetPassword}>Send</Button>
                  </div>
                </>}
                {requestedNewPassword && <>
                  <div style={{ color: '#55cc55', marginTop: '10px', marginBottom: '10px'}}>Email sent! Please check your email inbox.</div>
                </>}
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>}
      {reset_password_token && <div className="reset-password-form">
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <div className="shadowed-box">
                <h2>Reset Password</h2>
                {!passwordReseted && <>
                  {errors && <div className='error'>{errors}</div>}
                  <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <div style={{ marginBottom: '10px', color: (validPassword ? 'green' : 'red')}}>(at least {MIN_PASSWORD_LENGTH} characters and 1 number)</div>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(event) => { setPassword(event.target.value) }}
                      maxLength={`${MAX_PASSWORD_LENGTH}`}
                      />
                  </Form.Group>
                  <Form.Group controlId='passwordConfirmation'>
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control
                      type="password"
                      value={passwordConfirmation}
                      onChange={(event) => { setPasswordConfirmation(event.target.value) }}
                      maxLength={`${MAX_PASSWORD_LENGTH}`}
                      />
                  </Form.Group>
                  <div className='centered'>
                    {passwordConfirmation.length > 0 && password !== passwordConfirmation && <div style={{ marginTop: '10px', color: 'red'}}>Password must match with confirmation</div>}
                    <Button disabled={!validPassword || password !== passwordConfirmation} className="btn-success top-margin-button top-20" onClick={submit}>Reset Password</Button>
                  </div>
                </>}
                {passwordReseted && <>
                  <div style={{ color: '#55cc55', marginTop: '10px', marginBottom: '10px'}}>Password was reset successfully.</div>
                </>}
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>}
    </>
  )
}
