import './StartPage.scss';
import './call-out.css';
import './findfilms.css';
import './footer.css';
import './montage.css';
import './privacy.css';
// import './reviews.css';
import './subscribe.css';
import './trial.css';
// import './reviews';

import logo from '../../assets/images/vuniverse-logo-red.png'
import bowlCrop from '../../assets/images/bowl-crop.jpeg'
import bowl from '../../assets/images/bowl.jpeg'
import popcornCrop from '../../assets/images/popcorn-crop.jpeg'
import popcorn from '../../assets/images/popcorn.jpg'
import remote from '../../assets/images/remote.jpeg'
import facebook from '../../assets/images/Facebook copy.png'
import twitter from '../../assets/images/Twitter copy.png'
import instagram from '../../assets/images/Instagram copy.png'
import android from '../../assets/images/android_en_us.webp'
import ios from '../../assets/images/ios_en_us.svg'

import Carousel from "./KudosCarosel";

export default function StartPage() {
  return (
    <div className="start-page">
      <div className="header-bg">
        <div className="row-flex header" style={{ width: '100vw' }}>
          <div>
              <a href="/" className="logo">
                  <img src={logo} alt="VUniverse" />
              </a>
          </div>
          <div>
              <a className="login-btn" href="/login">Login</a>
              <a className="btn signup-btn" href="/register" role="button">Sign Up</a>
          </div>
        </div>
        <div style={{flex: '3', width: '100vw'}}>
              <div className="row-flex call">
                  <div style={{flex: '1'}}></div>
                  <div className="text-block">
                      <span className="action-call">It's time to stream smarter</span>
                      <div className="action-call-sub">
                          <p className="watch-p">What you watch is your business.</p>
                          <p style={{marginTop: '5px', marginBottom: '1em' }}>Helping you find it is ours.</p>
                      </div>
                      <div className="action-call-sub">
                        <a className="btn signup-free-btn" style={{fontWeight: '600' }} href="/register">Sign Up For Free!</a>
                      </div>
                      <div className="action-call-sub" style={{marginTop: '5px'}}>
                        <a href="https://play.google.com/store/apps/details?id=com.vuniverse.VU"><img src={android} alt="Download on Google Play" height="40" width="135"/></a>
                      </div>
                      <div className="action-call-sub" style={{marginTop: '5px'}}>
                        <a href="https://apps.apple.com/us/app/vuniverse/id1480137466?itsct=apps_box_badge&amp;itscg=30200"><img src={ios} alt="Download on the App Store" height="40" width="135"/></a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="kudos">
              <blockquote className="kudos-blockquote">
                  My husband went looking for a movie we wanted to watch and came back a half and hour later and told me he couldn't find it on any of our services. I went on my VU app and found it in less than 10 seconds.
                  <cite className="kudos-cite">J. Elting (Cincinnati, OH)</cite>
              </blockquote>
          </div>
      </div>
      <div className="montage-bg">
            <div className="row-flex-wrap">

                <div className="homeCard">
                    <picture>
                        <source srcSet={bowlCrop}
            media="(max-width: 500px)"/>
                    <img src={bowl} />
                </picture>
                    <h3>Discover</h3>
                    <p>Get personalized recommendations that are right for you.</p>
                </div>
                <div className="homeCard">
                    <picture>
                        <source srcSet={popcornCrop}
            media="(max-width: 500px)"/>
                    <img src={popcorn} />
                </picture>
                    <h3>Create</h3>
                    <p>Create watchlists or view curated lists based on genre, mood, and more.</p>
                </div>
                <div className="homeCard">
                    <img src={remote} height="200px" width="200px" />
                    <h3>Search</h3>
                    <p>Find where movies and shows are streaming right now.</p>
                </div>
            </div>
        </div>
        <div className="privacy-bg">
            <div>
                <div className="privacy-text">
                    <div className="matters-text">
                        <h3 style={{marginBottom: '0'}}>Privacy. It matters.</h3>
                    </div>
                    <div className="noads-text">
                        <h3>No ads, No spam, No robotexts,&nbsp;</h3>
                        <h3>No compromises.</h3>
                    </div>
                </div>
                <a className="btn signup-free-trial-btn-2" href="/register" role="button">Start Your Free Trial Now</a>
                <p className="privacy-cancel-text">Cancel at any time!</p>
            </div>
        </div>
      <div className="friends-bg">
        <div className="img-content">
            <div className="img-header">
            </div>
        </div>
        <div className="friends-text-block">
            <div>
                <p style={{marginTop: '0'}}>With a practically endless number of movies and TV shows available across streaming services, we created VUniverse with one goal in mind:</p>
                <h3>We answer the timeless question, what should I watch?</h3>
                <p>Scrolling through every streaming service is exhausting. It's frustrating and a waste of good time. VU's algorithm works <span style={{fontWeight: 'bold'}}>for you</span> to recommend programming and <span style={{fontWeight: 'bold'}}>where to find it</span>.</p>
                <a className="btn find-films-btn" href="/register" role="button">Start Finding Films Today</a>
            </div>
        </div>
      </div>
      <div className="multi-kudos-bg">
            <div className="multi-kudos">
                {/* <div id="review" className="review">
                    <div className="wrap">
                        <ul id="review-dots" className="dots" style={{paddingLeft: '0'}}>
                            <li className="dot active"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                            <li className="dot"></li>
                        </ul>
                        <div id="review-content" className="cont">
                            <div className="active">
                                <blockquote>I was on a Zoom call this morning and the main speaker mentioned “Hacksaw Ridge” and it was a movie I had meant to see YEARS ago but somehow it got off my list .. instead of writing it down (and forgetting about it again), I had my VU app open on my computer – I went in, found it and added it to my ‘War Movies I Must Watch List’
                                    <cite>M. Warner (Franklin, TN)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>Not only did it save me time, I got to take FULL advantage of my Hulu subscription just by making a “Things to watch on Hulu List” ... it was so simple to use!
                                    <cite>Carl H. (Scottsdale, AZ)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>I ended up saving the 8.99 Amazon was going to charge me because VU informed me I had it free on STARZ
                                    <cite>Hali W. (Atlanta, GA)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>I don’t know what I appreciate more: saving time, the organization or the saving money? But if I had to pick one, it would be saving time! Thank you VU!
                                    <cite>Wende R. (Charleston, SC)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>My streaming services were so out of control, I kind of stopped watching content for awhile but now I feel back in control.
                                    <cite>Darryl K. (Huntsville, AL)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>The recommendation engine recommended me an obscure indie film that I had wanted to see when it was out in theaters ... how did it know that about me? Pretty Cool.
                                    <cite>Charles S. (LA, CA)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>VU is fun. I get to organize all this great content and see what I should watch next
                                    <cite>Jason P. (Long Island, NY)</cite>
                                </blockquote>
                            </div>

                            <div>
                                <blockquote>It’s like the chocolate and peanut butter ... The Golden Age of Content meets VU – perfect together. Where I was overwhelmed before, I now am not.
                                    <cite>George E. (New Orleans, LA)</cite>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{margin: '0 10vw', paddingTop: '10vw'}}>
                <Carousel />
                </div>
            </div>
        </div>
        <div className="trial-bg">
            <div>
                <h3 className="trial-head">NOW! For a limited time only!</h3>
                <h3 className="trial-head">30 day free trial then just $3.99/month.</h3>
                <a className="btn signup-free-trial-btn" href="/register">Start Your Free Trial Now</a>
                {/* <p className="trial-text">It takes less than 5 minutes to sign up and start searching for films.  No credit card needed to sign up.</p> */}
                <p className="trial-text">It takes less than 5 minutes to sign up and start searching for films.</p>
            </div>
        </div>
      <footer style={{alignItems: 'center', textAlign: 'center', color: '#fff', height: '32vh'}}>
            <div className="socials">
                <a href="https://www.facebook.com/VUniverseApp" target="_blank" rel="noopener">
                    <img src={facebook}/>
                </a>
                <a href="https://www.twitter.com/VUniverseApp" target="_blank" rel="noopener">
                    <img style={{margin: '0 25px'}} src={twitter}/>
                </a>
                <a href="https://www.instagram.com/VUniverseApp" target="_blank" rel="noopener">
                    <img src={instagram}/>
                </a>
            </div>
            <div className="footer-nav footer-nav-large">
                <a href="/about">About</a>
                <a href="/faq">FAQ</a>
                <a href="https://angel.co/company/vuniverseapp/" target="vuniverse_careers">Careers</a>
                <a href="/contact">Contact Us</a>
                <a href="https://vuniverse-api.herokuapp.com/VUniverse_Form_C-AR_Annual_Report_v4s.pdf" target="_blank">2021 C-AR</a>
                <a href="/privacy">Privacy Policy</a>
                <a href="/terms">Terms of Service</a>
            </div>
            <div className="footer-nav footer-nav-medium">
                <div>
                    <a href="/about">About</a>
                    <a href="/faq">FAQ</a>
                    <a href="https://angel.co/company/vuniverseapp/" target="vuniverse_careers">Careers</a>
                    <a href="/contact">Contact Us</a>
                </div>
                <div style={{marginTop: '10px'}}>
                    <a href="https://vuniverse-api.herokuapp.com/VUniverse_Form_C-AR_Annual_Report_v4s.pdf" target="_blank">2021 C-AR</a>
                    <a href="/privacy">Privacy Policy</a>
                    <a href="/terms">Terms of Service</a>
                </div>
            </div>
            <div style={{marginTop: '20px'}}>
                <p className="copyright">© 2023 VUniverse, Inc All Rights Reserved.</p>
            </div>
        </footer>

    </div>

  )
}
