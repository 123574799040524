import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"

export default function ScrollToTop({ history }) {
  const location = useLocation()
  const [ ignoreNextPath, setIgnoreNextPath ] = useState(false)

  useEffect(() => {
    const pathParts = location.pathname.split('/')
    const showingMedia = pathParts.length >= 4 && (pathParts[2] === 'media' || pathParts[3] === 'media')
    if(showingMedia) {
      setIgnoreNextPath(true)
    } else if(!ignoreNextPath) {
      window.scrollTo(0, 0)
    } else if(ignoreNextPath) {
      setIgnoreNextPath(false)
    }
  }, [location])

  return (null)
}