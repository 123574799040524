import { Card } from 'react-bootstrap'
import CardActions from './CardActions'
import ProvidersOverlay from './ProvidersOverlay'

export default function ImageWithOverlays({ medium, showMedia, mediaReload, selection, setCardVisible,
  overlayVisible, isFavorite, rating, dispatch, hasList, sortTitles }) {

  return (
    <div className="child-container">
      <Card.Img
        variant="top"
        src={medium.poster_url}
        className="image"
        draggable={false}
        onClick={showMedia}
      />
      {!sortTitles && <CardActions
        overlayVisible={overlayVisible}
        isFavorite={isFavorite}
        rating={rating}
        medium={medium}
        dispatch={dispatch}
        showMedia={showMedia}
        hasList={hasList}
      />}
      {!sortTitles && <ProvidersOverlay
        mediaReload={mediaReload}
        selection={selection}
        setCardVisible={setCardVisible}
        overlayVisible={overlayVisible}
        medium={medium} />}
    </div>
  )
}