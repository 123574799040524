import React, { useState } from 'react'
import ReactCookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent"
import { Link } from 'react-router-dom'
import CookieConsentModal from './CookieConsentModal'

const CookieConsent = () => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <>
      <ReactCookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="cookieConsent"
        style={{ background: "#536d63" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
        We use cookies to ensure that we give you the best experience on our website. By continuing to use our website you are accepting our use of cookies.&nbsp;
        <a href="javascript: void(0)" style={{ color: '#ffffff' }} onClick={() => setModalVisible(true)}>Read more</a>
      </ReactCookieConsent>
      <CookieConsentModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}

export default CookieConsent