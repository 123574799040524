import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function favoriteRateAndListHandler(medium) {
  const user_medium_rating = useSelector((state) => state.user_medium_rating)
  const user_lists_medium = useSelector((state) => state.user_lists_medium)
  const [isFavorite, setIsFavorite] = useState(false)
  const [hasList, setHasList] = useState(false)
  const [rating, setRating] = useState(medium.rating || "")

  useEffect(() => {
    if (medium.favorited != isFavorite) {
      setIsFavorite(medium.favorited)
    }
    if(rating !== medium.rating) {
      setRating(medium.rating)
    }
    if(medium.has_list != hasList) {
      setHasList(medium.has_list)
    }
  }, [medium.favorited, medium.has_list, medium.rating])

  useEffect(() => {
    if (user_medium_rating.medium_id === medium.id) {
      if(!isFavorite) {
        setIsFavorite(true)
      }
      setRating(Math.floor(user_medium_rating.rating / 2))
    }
  }, [user_medium_rating])

  useEffect(() => {
    if (user_lists_medium && user_lists_medium.medium && user_lists_medium.medium.id === medium.id) {
      if(user_lists_medium.medium.has_list != hasList) {
        setHasList(user_lists_medium.medium.has_list)
      }
    }
  }, [user_lists_medium])

  return [isFavorite, hasList, rating]
}