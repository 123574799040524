import { api_client, errorHandler } from '../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedInUser } from '../actions/user'

const useMediaFilter = (refreshFunction) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [ allStreaming, setAllStreaming ] = useState(user.preferences.filter_all_streaming_services === 'true')
  const [ mediaType, setMediaType ] = useState(user.preferences.filter_media_type)
  const [ lastFilter, setLastFilter ] = useState({})

  const addParams = (p) => {
    return {...p, all_streaming: allStreaming, media_type: mediaType}
  }

  const updateAllStreaming = () => {
    updatePreference('filter_all_streaming_services', allStreaming)
  }

  const updateMediaType = () => {
    updatePreference('filter_media_type', mediaType)
  }

  const updatePreference = (prefKey, prefValue) => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(
      '/api/v1/user_preferences/save',
      { user_preference: { key: prefKey, value: prefValue }}
    ).then(res => {
      const updatedUser = {...user, preferences: {...user.preferences, [prefKey]: prefValue.toString()}}
      dispatch(setLoggedInUser(updatedUser, access_token))
    }).catch((error) => {
      errorHandler(error, dispatch)
    })
  }

  useEffect(() => {
    if(lastFilter !== addParams({})) {
      refreshFunction()
    }
    //dispatch(setSearchQuery(''))
    //if(selection !== initialSelection) setSelection(initialSelection)
    const user_all_streaming = user.preferences.filter_all_streaming_services === 'true'
    if(user_all_streaming !== allStreaming) {
      updateAllStreaming()
    }
    if(user.preferences.filter_media_type !== mediaType) {
      updateMediaType()
    }
  }, [allStreaming, mediaType])

  useEffect(() => {
    var newValue = user.preferences.filter_all_streaming_services === 'true'
    if(allStreaming !== newValue) {
      setAllStreaming(newValue)
    }
    newValue = user.preferences.filter_media_type
    if(mediaType !== newValue) {
      setMediaType(newValue)
    }
  },[user])

  return { addParams, allStreaming, setAllStreaming, mediaType, setMediaType, setLastFilter }
}

export default useMediaFilter