import { SET_USER_LISTS_MEDIUM_SAVED } from "../config/constants.js"

const user_lists_medium = (state = [], action) => {
  switch (action.type) {
    case SET_USER_LISTS_MEDIUM_SAVED:
      return action.user_lists_medium
    default:
      return state
  }
}

export default user_lists_medium
