import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { api_client, errorHandler } from '../../config/api-client'
import { Modal, Button, Row, Col, Container, Form } from 'react-bootstrap'
import './ReferralSourceModal.scss'
import { setLoggedInUser } from '../../actions/user'

export default function ReferralSourceModal({}) {
  const OPTIONS = [
    [1, 'Facebook'],
    [2, 'Instagram'],
    [3, 'Google'],
    [4, 'TikTok'],
    [5, 'SnapChat'],
    [6, 'Weekly Mailing List'],
    [7, 'Friend/Family'],
    [8, 'Other'],
  ]

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [ error, setError ] = useState(false)
  
  const [loading, setLoading] = useState(false)
  const [referralSource, setReferralSource] = useState()
  const [details, setDetails] = useState('')
  const [canSend, setCanSend] = useState(false)

  useEffect(() => {
    setCanSend(
      referralSource && referralSource > 0 &&
      ((details && details.trim().length > 3) || referralSource !== 8)
    )
  }, [details, referralSource])

  const saveReferralSource = (refSource, detailsText) => {
    if(!loading) {
      setLoading(true)
    }
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post(`/api/v1/referral_sources.json`, {
      referral_source: {
        referral_source: refSource,
        details: detailsText
      }}).then(res => {
        dispatch(setLoggedInUser({...user, pending_referral_source: false}, access_token))
        setLoading(false)
    }).catch((error) => {
      if(error.response && error.response.data) {
        setError(error.response.data.message)
      } else {
        setError('Unknown error while trying to send your response.')
      }
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        className="referral-source-modal"
      >
        <Modal.Header>
          <Modal.Title>How did you hear about us?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            {OPTIONS.map((item) => (
              <Row>
                <Col>
                  <Form.Check 
                    type={'radio'}
                    id={`opt_${item[0]}`}
                    label={item[1]}
                    name="radio_group"
                    onClick={() => setReferralSource(item[0])}
                  />
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                {referralSource === 8 && <Form.Control
                  type="text"
                  disabled={referralSource !== 8}
                  placeholder="how did you hear about us?"
                  maxLength={255}
                  onChange={(e) => { setDetails(e.target.value) }} value={details} 
                />}
              </Col>
            </Row>
            <Row>
              <Col>
                {error && <span style={{ color: 'red'}}>{error}</span>}
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" disabled={!canSend} onClick={() => { saveReferralSource(referralSource, details) }}>Send</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

