import { useState, useEffect } from 'react'
import { api_client, errorHandler } from '../../config/api-client'
import Coverflow from 'react-coverflow'
import RateListItem from './RateListItem'
import { Container, Row, Col } from 'react-bootstrap'
import RatingModal from '../Rating/RatingModal'
import { setRatingModalData } from '../../actions/rating_modal_data'
import { useDispatch, useSelector } from 'react-redux'
import './RateList.scss'
import { setMediaModalData } from '../../actions/media_modal_data'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

export default function RateList({ loading, setLoading }) {
  const [media, setMedia] = useState([])
  const [currentIndex, setCurrentIndex] = useState(1)
  const dispatch = useDispatch()
  const { mediaId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const user_medium_rating = useSelector((state) => state.user_medium_rating)
  const user_lists_medium = useSelector((state) => state.user_lists_medium)

  const fetchMedia = () => {
    const access_token = localStorage.getItem('access_token')
    if(!loading) {
      setLoading(true)
    }
    api_client(access_token).get(`/api/v1/media/to_rate`).then(res => {
      setMedia(res.data.media)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const userRating = (medium) => {
    dispatch(setRatingModalData({ visible: true, medium: medium }))
  }

  const addToList = (listType) => {
    const access_token = localStorage.getItem('access_token')
    var params = { user_list_medium: { medium_id: media[currentIndex].id } }
    if(listType === 'watch_later') {
      params = {...params, to_watch_later: true }
    } else {
      params = {...params, to_havent_watched: true }
    }
    api_client(access_token).post(
      `/api/v1/user_lists/0/user_list_media`,
      params
    ).then(res => {
      nextItem()
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const showMedia = () => {
    const medium = media[currentIndex]
    var path = `${location.pathname}`
    if(path === '/') {
      path = `/discover/media/${medium.id}${location.search}`
    } else {
      path = `${path}/media/${medium.id}${location.search}`
    }
    navigate(path)
    dispatch(setMediaModalData({ visible: true, medium: media[currentIndex] }))
  }

  useEffect(() => {
    if(mediaId) {
      dispatch(setMediaModalData({ visible: true, medium: { id: mediaId } }))
    }
    fetchMedia()
  }, [])

  useEffect(() => {
    if (media[currentIndex] && user_medium_rating && user_medium_rating.medium_id === media[currentIndex].id) {
      nextItem()
    }
  }, [user_medium_rating])

  useEffect(() => {
    if(user_lists_medium) {
      var found = false
      user_lists_medium.forEach(user_list_medium => {
        if (media[currentIndex] && user_list_medium &&
            user_list_medium.medium_id === media[currentIndex].id &&
            user_list_medium.user_list && user_list_medium.user_list.list_type) {
          found = true
        }
      })
      if(found) {
        nextItem()
      }
    }
  }, [user_lists_medium])

  useEffect(() => {
    if(media.length > 0) {
      setTimeout(() => {
        setCurrentIndex(0)
      }, 500)
    }
  }, [media])

  const nextItem = () => {
    if(currentIndex + 1 === media.length) {
      fetchMedia()
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <>
      {media.length > 0 && <Coverflow
        width="100%"
        classes={{background: 'rgb(233, 23, 23)'}}
        displayQuantityOfSide={4}
        navigation={false}
        enableScroll={false}
        clickable={false}
        active={currentIndex}
        currentFigureScale={1}
      >
        {media.map((item, index) =>
          <RateListItem key={item.id} item={item} index={index} showMedia={showMedia} />
        )}
      </Coverflow>}
      {media.length > 0 && <Container className="rate-actions">
        <Row>
          <Col md={3}></Col>
          <Col md={2}><button type="button" className="btn" onClick={() => addToList('havent_watched')}>Haven't Watched</button></Col>
          <Col md={2}><button type="button" className="btn" onClick={() => userRating(media[currentIndex])}>Rate</button></Col>
          <Col md={2}><button type="button" className="btn" onClick={() => addToList('watch_later')}>Watch Later</button></Col>
          <Col md={3}></Col>
        </Row>
      </Container>}
      <RatingModal />
    </>
  )
}