import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const CookieConsentModal = ({ visible, setVisible}) => {
  return (
    <>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        backdrop="static"
        className="cookie-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cookies Consent</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Like most websites, Vuniverse.com uses cookies. In order to deliver a personalized, responsive service and to improve the site, we remember and store information about how you use it. This is done using simple text files called cookies which sit on your computer. These cookies are completely safe and secure and will never contain any sensitive information.
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CookieConsentModal
