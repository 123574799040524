import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css'
import MediaCard from "./MediaCard/MediaCard"
import PersonCard from "./PersonCard"
import { Row, Col } from 'react-bootstrap'
import ListTitle from '../List/ListTitle'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};

export default function MediaCarousel({
  title,
  media,
  mediaReload,
  isPeople,
  selection,
  setSelection,
  location,
  list,
  carouselKey,
  loading,
  countHidden
}) {
  var listValue = list
  if(list && list.count_media && countHidden) {
    listValue = {...list, count_media: list.count_media - countHidden}
  }

  return (
    <>
      <ListTitle
        list={listValue}
        title={title}
        loading={loading}
        selection={selection}
        linkEnabled={true}
        />
      <Row>
        <Col>
          {(!media || media.length === 0) && <div className="no-items-text">No items present in this list</div>}
          {media && media.length > 0 && <Carousel
            ssr
            partialVisibile
            itemClass="image-item"
            responsive={responsive}
            sliderClass={media.length < 4 ? 'align-left-not-scroll' : ''}
            autoPlay={false}
            autoPlaySpeed={99999}
            key={carouselKey}
          >
            {!isPeople && media.filter(medium => medium.hidden !== true ).map(medium => (
              <MediaCard key={`media_${medium.id}`} isSharedList={list && list.id && !list.is_owner} selection={selection} medium={medium} mediaReload={mediaReload} location={location} />
            ))}
            {isPeople && media.map(person => (
              <PersonCard key={`person_${person.id}`} person={person} setSelection={setSelection} />
            ))}
          </Carousel>}
        </Col>
      </Row>
    </>
  );
};
