import { GoDash } from 'react-icons/go'
import { LAST_SIGNUP_STEP } from '../../config/constants'

export default function StepMeter({step}) {

  return (
    <div className="step-meter">
      <GoDash className={step >= 1 ? 'step-on' : 'step-off'}/>
      <GoDash className={step >= 2 ? 'step-on' : 'step-off'}/>
      <GoDash className={step >= 3 ? 'step-on' : 'step-off'}/>
      {LAST_SIGNUP_STEP >= 4 && <GoDash className={step >= 4 ? 'step-on' : 'step-off'}/>}
      {LAST_SIGNUP_STEP >= 5 && <GoDash className={step >= 5 ? 'step-on' : 'step-off'}/>}
    </div>
  )

}
