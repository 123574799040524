import { Container, Col, Row, Collapse } from 'react-bootstrap'
import { useState } from 'react'

export default function FaqItem({item}) {
  const [open, setOpen] = useState(false)

  return (
    <Row>
      <div className="faq-title">{item[0]}</div>
      <div className="wrap">
        <button className={open ? 'opened' : ''} onClick={() => setOpen(!open)}>
          <span></span>
          <span></span>
        </button>
      </div>
      <Collapse in={open}>
        <div className="faq-content">{item[1]}</div>
      </Collapse>
    </Row>
  )
}