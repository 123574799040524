import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { api_client } from '../../config/api-client'
import { useState } from 'react'

export default function AddListForm({
  reloadLists
}) {

  const [listName, setListName] = useState('')

  const createList  = () => {
    const access_token = localStorage.getItem('access_token')
    api_client(access_token).post('/api/v1/user_lists', { name: listName }).then(res => {
      reloadLists()
    })
  }

  return (
    <Form>
      <Container>
        <Row>
          <Col md={9}>
            <Form.Control type="text" placeholder="Enter list name" value={listName} onChange={(e) => { setListName(e.target.value) }} />
          </Col>
          <Col md={3}>
            <Button variant="primary" onClick={() => { createList() }}>Add</Button>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}
