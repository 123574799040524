import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { api_client } from '../../config/api-client'
import { setLoggedInUser } from '../../actions/user'
import './PublicPage.scss'

export default function ConfirmDeleteAccountPage() {
  const [ deleted, setDeleted ] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ code, setCode ] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setCode(urlParams.get('code'))
    setEmail(urlParams.get('email'))
  }, []);

  const confirmDeletion = async (e) => {
    e.preventDefault()
    setError(false)
    setLoading(true)
    api_client().post('/api/v1/user/confirm_delete_account.json', {
      user: {
        email: email
      },
      code: code,
    }).then(() => {
      dispatch(setLoggedInUser({}, ''))
      setLoading(false)
      setDeleted(true)
    }).catch((error) => {
      setLoading(false)
      if(error.response && error.response.data) {
        if(error.response.data.message) {
          setError(error.response.data.message)
        } else {
          setError(error.response.data.error)
        }
      } else {
        console.log(error.response)
      }
    })
  }

  return (
    <div className='public-page' style={{ marginTop: '3%', padding: '20px'}}>
      <h1>Confirm Account Deletion</h1>
      {!deleted && <div style={{ display: 'inline-block', maxWidth: '600px'}}>
        <p>
          Are you sure you want to delete your VUniverse account? This operation can't be undone!
        </p>
        <form onSubmit={confirmDeletion}>
          {error && <div style={{color: 'red'}}>{error}</div>}
          <button type="submit" style={{marginTop: '10px'}} disabled={loading}>I am sure, delete my account</button>
        </form>
      </div>}
      {deleted && <div>
        <div style={{color: 'lightgreen'}}>Account deleted successfully!</div>
      </div>}
    </div>
  )
}