import { ListGroup, Container, Row, Col, Dropdown, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import { setLoggedInUser } from '../../actions/user'
import { Link } from 'react-router-dom'
import './SettingTab.scss'

export default function AccountEditor({ setEditingAccount }) {
  const user = useSelector((state) => state.user)
  const [firstName, setFirstName] = useState(user.first_name ? user.first_name : '')
  const [lastName, setLastName] = useState(user.last_name ? user.last_name : '')
  const [email, setEmail] = useState(user.email)
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number)
  const [gender, setGender] = useState(user.gender)
  const [genderName, setGenderName] = useState('')
  const [birthdate, setBirthdate] = useState(user.birthdate)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    switch(gender) {
      case 'male':
        setGenderName('Male')
        break
      default:
        setGenderName('Female')
        break
    }
  }, [gender])

  const updateUser = () => {
    const accessToken = localStorage.getItem('access_token')
    setErrors({})
    api_client(accessToken).put('/api/v1/user.json', {
      user: {
        gender: gender,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email,
        phone_number: phoneNumber,
        birthdate: birthdate
      }
    }).then((response) => {
      const responseUser = response.data.user
      dispatch(setLoggedInUser(responseUser, responseUser.access_token))
      setEditingAccount(false)
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else if(error.response && error.response.data.error) {
        setErrors({ user: error.response.data.message })
      } else {
        setErrors({ user: 'Unknown error while trying to update user' })
      }
    })
  }

  const cancelEditAccount = () => {
    setEditingAccount(false)
    setFirstName(user.first_name ? user.first_name : '')
    setLastName(user.last_name ? user.last_name : '')
    setEmail(user.email)
    setPhoneNumber(user.phone_number)
    setGender(user.gender)
    setBirthdate(user.birthdate)
  }

  useEffect(() => {
    if(firstName.trim() !== user.first_name || lastName.trim() !== user.last_name || gender !== user.gender ||
    email !== user.email || phoneNumber !== user.phone_number || birthdate !== user.birthdate) {
      if(!saveEnabled) setSaveEnabled(true)
    } else {
      if(saveEnabled) setSaveEnabled(false)
    }
  }, [firstName, lastName, email, phoneNumber, user, gender, birthdate])

  return (
    <>
      <Container className="inputs-container">
        <Row>
          <Form.Control disabled={true} value={`Display Name: ${user.display_name}`} className="read-only-field" />
        </Row>
        <Row>
          <Form.Control placeholder="Enter first name" onChange={(e) => { setFirstName(e.target.value) }} value={firstName} />
        </Row>
        <Row>
          <Form.Control placeholder="Enter last name" onChange={(e) => { setLastName(e.target.value) }} value={lastName} />
        </Row>
        <Row>
          <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setEmail(e.target.value) }} value={email} />
        </Row>
        <Row>
          <Form.Control type="number" maxLength="10" placeholder="Enter phone number" onChange={(e) => { setPhoneNumber(e.target.value) }} value={phoneNumber} />
        </Row>
      </Container>
      <ListGroup>
        <ListGroup.Item>
          <Container>
            <Row>
              <Col>Gender</Col>
              <Col className="right-col">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {genderName} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#male" onClick={() => { setGender('male') }}>Male</Dropdown.Item>
                    <Dropdown.Item href="#female" onClick={() => { setGender('female') }}>Female</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
        <ListGroup.Item>
          <Container>
            <Row>
              <Col className="left-col">Birthdate</Col>
              <Col className="right-col">
                <Form.Control type="date" name='date_of_birth' className="birthday" value={birthdate} onChange={(e) => { setBirthdate(e.target.value) }} />
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
      </ListGroup>
      {errors && errors.user && <div className='error'>{errors.user}</div>}
      <button className="btn-setting-cancel" type="button" onClick={cancelEditAccount}>Cancel</button>
      <button disabled={!saveEnabled} className="btn-setting" type="button" onClick={updateUser}>Save</button>
    </>
  )
}
