import { useState, useEffect } from 'react'
import { Container, Card, Col, Row, FloatingLabel, Form } from 'react-bootstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import { MdOutlineExpandMore, MdOutlineExpandLess, MdUndo } from 'react-icons/md'
import { AiOutlineEdit } from 'react-icons/ai'

export default function ShareModalItem({ item, key, deleted, toggleDelete, changeShareAllowed, is_owner }) {
  const [ showingForm, setShowingForm ] = useState(false)
  return (
    <Col className="list-item">
      <Row onClick={() => setShowingForm(!showingForm)}>
        <Col md={11} className={`item-title ${deleted ? 'item-deleted' : ''}`}>{item.share_allowed && is_owner && <span className="sharing">*</span>}{item.display_name}</Col>
        <Col md={1}>
          {is_owner && !showingForm && <MdOutlineExpandMore />}
          {is_owner && showingForm && <MdOutlineExpandLess />}
          {!is_owner && <DeleteAction toggleDelete={toggleDelete} item={item} deleted={deleted} />}
        </Col>
      </Row>
      {is_owner && <Row className={showingForm ? "item-form-container" : "hidden"}>
        <Col md={11}>
          <input type="checkbox" onChange={(e) => { changeShareAllowed(item) } } checked={item.share_allowed} />
          <span className="checkbox-label">Allow to share your list</span>
        </Col>
        <DeleteAction toggleDelete={toggleDelete} item={item} deleted={deleted} />
      </Row>}
    </Col>
  )
}

function DeleteAction({ toggleDelete, item, deleted }) {
  return (
    <Col md={1} onClick={(e) => { toggleDelete(item, !deleted) }}>
      {!deleted && <HiOutlineTrash />}
      {deleted && <MdUndo />}
    </Col>
  )
}