import InfiniteScroll from 'react-infinite-scroll-component'
import MediaCard from "../MediaBrowser/MediaCard/MediaCard"
import PropagateLoader from "react-spinners/PropagateLoader"
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
          arr.push(undefined)
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export default function Grid({ media, loadMore, pagination, isOwner, canEdit, fetchMedia, updateSort, sortTitles }) {
  
  const onSortEnd = ({oldIndex, newIndex}) => {
    const m = [...media]
    updateSort(arrayMove(m, oldIndex, newIndex))
  }

  const shouldCancelStart = (e) => {
    if(sortTitles) {
      return false
    }
    const targetElementClasses = e.target.classList
    const isMoreText = targetElementClasses.contains('more-text')
    const isAddToList = e.target.tagName === 'path'
    const isRating = e.target.tagName === 'svg'
    const isStreamingLink = targetElementClasses.contains('streaming-icon')
    const isMediaTitle = targetElementClasses.contains('media-card-title')
    return isMoreText || isRating || isAddToList || isStreamingLink || isMediaTitle
  }

  return (
    <div style={{ width: '100%'}}>
      {media && media.length > 0 && <InfiniteScroll
        dataLength={media.filter(medium => medium.hidden !== true )} //This is important field to render the next data
        next={loadMore}
        hasMore={pagination.current_page < pagination.page_count}
        loader={<PropagateLoader color="#f1af3b" loading={true} css={{display: 'block', margin: '0 auto'}} size={10} />}
        endMessage={
          <p>
          </p>
        }
      >
        {media && media.length > 0 && canEdit && <SortableList
          items={media.filter(medium => medium.hidden !== true )}
          isOwner={isOwner}
          fetchMedia={fetchMedia}
          location={location}
          onSortEnd={onSortEnd}
          axis="xy"
          shouldCancelStart={shouldCancelStart}
          sortTitles={sortTitles}
        />}
        {media && media.length > 0 && !canEdit && media.filter(medium => medium.hidden !== true ).map((m) => (
          <div key={m.id} className="medium-container">
            <MediaCard
              isSharedList={!isOwner}
              selection={'my_lists'}
              medium={m}
              mediaReload={fetchMedia}
              location={location} />
          </div>
        ))}
      </InfiniteScroll>}
    </div>
  )
}

const SortableItem = SortableElement(({ value, isOwner, fetchMedia, location, sortTitles }) => 
  <li className='sortable-item'>
    <MediaCard
      isSharedList={!isOwner}
      selection={'my_lists'}
      medium={value}
      mediaReload={fetchMedia}
      location={location}
      sortTitles={sortTitles}
      />
  </li>
)

const SortableList = SortableContainer(({items, isOwner, fetchMedia, location, sortTitles }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          isOwner={isOwner}
          fetchMedia={fetchMedia}
          location={location}
          sortTitles={sortTitles}
        />
      ))}
    </ul>
  );
});