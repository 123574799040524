import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Footer.scss'

export default function Footer({ loggedIn }) {
  return (
    <Container className="content-footer" style={loggedIn ? {} : { backgroundColor: 'transparent'}}>
      <Row style={{paddingBottom: '15px'}}>
        <span>
          <a href="https://www.instagram.com/VUniverseApp" target="_blank" rel="noopener">
            <img src="/logos/instagram.png"/>
          </a>
          <a href="https://www.twitter.com/VUniverseApp" target="_blank" rel="noopener">
            <img src="/logos/twitter.png"/>
          </a>
          <a href="https://www.facebook.com/VUniverseApp" target="_blank" rel="noopener">
            <img src="/logos/facebook.png"/>
          </a>
        </span>
      </Row>
      <Row style={{paddingBottom: '15px'}}>
        <div className="footer-link">
          {/*<Link to="/get_the_app">Get the App</Link> | */}
          <Link to="/about">About</Link> |
          <Link to="/faq">FAQ</Link> |
          <a href="https://angel.co/company/vuniverseapp/" target="vuniverse_careers">Careers</a> |
          <Link to="/contact">Contact Us</Link> |
          <a href="https://vuniverse-api.herokuapp.com/VUniverse_Form_C-AR_Annual_Report_v4s.pdf" target="_blank">2021 C-AR</a> |
          <Link to="/privacy">Privacy Policy</Link> |
          <Link to="/terms">Terms of Service</Link>
        </div>
      </Row>
      <Row>
        <div className="copyright">&copy; 2023 VUniverse, Inc All Rights Reserved.</div>
      </Row>
    </Container>
  )
}
