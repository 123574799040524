import { api_client, errorHandler } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { setSearchQuery, setSearchType } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'

export default function TabSearch({
  initialSelection,
  setSelection,
  selection,
  match
}) {

  const [media, setMedia] = useState([])
  const [people, setPeople] = useState([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchQuery = useSelector((state) => state.search)
  const dispatch = useDispatch()

  const fetchMedia = async (query, searchType) => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { query: query, search_type: searchType, selection: initialSelection }
    setLoading(true)
    const url = ( searchType === 'media' ? '/api/v2/media' : '/api/v1/people')
    api_client(access_token).get(url, { params: requestParams }).then(res => {
      setLoading(false)
      if(res.data.media) {
        setMedia(res.data.media)
        setPeople([])
      } else {
        setPeople(res.data.people)
        setMedia([])
      }
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    var query = parsed.query
    var searchType = parsed.search_type

    if(!query) {
      query = ''
    }

    if(!searchType) {
      searchType = 'media'
    }

    if(query !== '') {
      fetchMedia(query, searchType)
    }

    if(searchQuery.query !== query) {
      dispatch(setSearchQuery(query))
    }

    if(searchQuery.search_type !== searchType) {
      dispatch(setSearchType(searchType))
    }

    if(selection !== initialSelection) {
      setSelection(initialSelection)
    }
  }, [location.search])

  return (
    <MediaBrowser loading={loading} setSelection={setSelection} selection={selection} initialSelection={'search'} media={media} people={people} />
  );
}
