import React from "react";
import { Carousel } from "react-responsive-carousel";
import './reviews.css';

export default () => (
  <Carousel autoPlay showThumbs={false} showArrows={false} infiniteLoop={true} showStatus={false}>
    <div className="kudosDiv">
        <blockquote>I was on a Zoom call this morning and the main speaker mentioned “Hacksaw Ridge” and it was a movie I had meant to see YEARS ago but somehow it got off my list .. instead of writing it down (and forgetting about it again), I had my VU app open on my computer – I went in, found it and added it to my ‘War Movies I Must Watch List’ 
            <cite>M. Warner (Franklin, TN)</cite>
        </blockquote>                    
    </div>
    <div className="kudosDiv">
        <blockquote>Not only did it save me time, I got to take FULL advantage of my Hulu subscription just by making a “Things to watch on Hulu List” ... it was so simple to use! 
            <cite>Carl H. (Scottsdale, AZ)</cite>
        </blockquote>                    
    </div>
    <div className="kudosDiv">
        <blockquote>I ended up saving the 8.99 Amazon was going to charge me because VU informed me I had it free on STARZ
            <cite>Hali W. (Atlanta, GA)</cite>
        </blockquote>                    
    </div>
    <div className="kudosDiv">
        <blockquote>I don’t know what I appreciate more: saving time, the organization or the saving money? But if I had to pick one, it would be saving time! Thank you VU!
            <cite>Wende R. (Charleston, SC)</cite>
        </blockquote>                    
    </div>  
    
    <div className="kudosDiv">
        <blockquote>My streaming services were so out of control, I kind of stopped watching content for awhile but now I feel back in control. 
            <cite>Darryl K. (Huntsville, AL)</cite>
        </blockquote>                    
    </div>  

    <div className="kudosDiv">
        <blockquote>The recommendation engine recommended me an obscure indie film that I had wanted to see when it was out in theaters ... how did it know that about me? Pretty Cool.
            <cite>Charles S. (LA, CA)</cite>
        </blockquote>                    
    </div>  

    <div className="kudosDiv">
        <blockquote>VU is fun. I get to organize all this great content and see what I should watch next
            <cite>Jason P. (Long Island, NY)</cite>
        </blockquote>                    
    </div> 

    <div className="kudosDiv">
        <blockquote>It’s like the chocolate and peanut butter ... The Golden Age of Content meets VU – perfect together. Where I was overwhelmed before, I now am not.
            <cite>George E. (New Orleans, LA)</cite>
        </blockquote>                    
    </div>
  </Carousel>
);
