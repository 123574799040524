import { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import './Autocomplete.scss'
import { api_client, errorHandler } from '../../config/api-client'
import { useDispatch } from 'react-redux'
import ConfirmDialog from '../Settings/ConfirmDialog'
import AutocompleteItem from './AutocompleteItem'
import MediumShortInfo from './MediumShortInfo'
import PropagateLoader from "react-spinners/PropagateLoader"

var autoCompleteTimeout = false
export default function Autocomplete({ userListId, addToMedia, removeFromMedia }) {
  const [ loading, setLoading ] = useState(false)
  const [ value, setValue ] = useState('')
  const [ items, setItems ] = useState([])
  const [ lastValue, setLastValue ] = useState(false)
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  const [ confirmAdd, setConfirmAdd ] = useState(false)
  const [ medium, setMedium ] = useState(false)
  const dispatch = useDispatch()


  const scheduleSearch = (query) => {
    if(autoCompleteTimeout) {
      clearTimeout(autoCompleteTimeout)
    }
    autoCompleteTimeout = setTimeout(function() {
      search(query, true)
      autoCompleteTimeout = false
    }.bind(this), 1000)
  }

  const search = (query, fromTimeout) => {
    if(!fromTimeout) {
      scheduleSearch(query)
      return
    }
    const access_token = localStorage.getItem('access_token')
    const searchType = 'media'
    const initialSelection = 'search'
    var requestParams = { query: query, search_type: searchType, selection: initialSelection, for_list: userListId }
    setLoading(true)
    const url = ( searchType === 'media' ? '/api/v2/media' : '/api/v1/people')
    api_client(access_token).get(url, { params: requestParams }).then(res => {
      setLoading(false)
      setLastValue(query)
      if(res.data.media) {
        setItems(res.data.media)
      } else {
        setItems([])
      }
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const deleteConfirmed = () => {
    const access_token = localStorage.getItem('access_token')
    setConfirmDelete(false)
    api_client(access_token).delete(`/api/v1/user_lists/${userListId}/user_list_media/0`, {
      params: { medium_id: medium.id }
    }).then(res => {
      setLoading(false)
      removeFromMedia(medium)
      setValue('')
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const addConfirmed = () => {
    const access_token = localStorage.getItem('access_token')
    setConfirmAdd(false)
    api_client(access_token).post(`/api/v1/user_lists/${userListId}/user_list_media`, {
      user_list_medium: {
        medium_id: medium.id,
        user_list_id: userListId,
      } 
    }).then(res => {
      setLoading(false)
      addToMedia(medium)
      setValue('')
    }).catch((error) => {
      setLoading(false)
      errorHandler(error, dispatch)
    })
  }

  const onSelect = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    setLoading(true)
    setMedium(suggestion)
    if(suggestion.already_added) {
      setConfirmDelete(true)
    } else {
      setConfirmAdd(true)
    }
  }

  return (
    <div className='autocomplete-container'>
      <Autosuggest
        suggestions={items}
        onSuggestionsFetchRequested={({ value }) => search(value)}
        onSuggestionsClearRequested={() => setItems([])}
        getSuggestionValue={(item) => item.title}
        onSuggestionSelected={onSelect}
        renderSuggestion={(item) => (
          <AutocompleteItem medium={item} />
        )}
        inputProps={{
          placeholder: 'search to add or remove from list',
          value,
          onChange: (event, { newValue }) => setValue(newValue)
        }}
      />

      <div className={`loading-container ${loading ? '' : 'd-none'}`}>
        <PropagateLoader color="#f1af3b" loading={loading} css={{display: 'block', margin: '0 auto'}} size={10} />
      </div>

      {confirmDelete && <ConfirmDialog
        visible={true}
        setVisible={setConfirmDelete}
        title="Remove From List"
        buttonLabel="Yes, remove it"
        prompt={(
          <div>
            <MediumShortInfo medium={medium} />
            <div style={{marginTop: '10px', fontSize: '1.1rem'}}>Are you sure you want to remove from this list?</div>
          </div>
        )}
        onConfirm={deleteConfirmed}
      />}
      {confirmAdd && <ConfirmDialog
        visible={true}
        setVisible={setConfirmAdd}
        title="Add To List"
        buttonLabel="Yes, add it"
        prompt={(
          <div>
            <MediumShortInfo medium={medium} />
            <div style={{marginTop: '10px', fontSize: '1.1rem'}}>Are you sure you want to add to this list?</div>
          </div>
        )}
        onConfirm={addConfirmed}
      />}
    </div>
  )
}