import { setRatingModalData } from '../../../actions/rating_modal_data'
import { setListModalData } from '../../../actions/list_modal_data'
import { HiStar, HiOutlineStar } from 'react-icons/hi'
import { MdOutlineLibraryAdd, MdLibraryAdd } from 'react-icons/md'

export default function CardActions({ overlayVisible, isFavorite, rating, medium, dispatch,
  showMedia, hasList }) {

  const userRating = () => {
    dispatch(setRatingModalData({ visible: true, medium: medium }))
  }

  const addToList = (medium) => {
    dispatch(setListModalData({ visible: true, medium: medium }))
  }

  return (
    <div className="overlay" style={{ backgroundColor: 'rgba(50,50,50,0.4)'}}>
      <div
        className="actions favorite-button"
        onClick={() => { userRating(medium.id) }}
      >
        <div className={`star-container ${isFavorite ? 'bigger' : 'normal'}`}>
          {isFavorite ? <HiStar /> : <HiOutlineStar />}
        </div>
        <div className="star-rating-value">{rating}</div>
      </div>
      <div className={`actions more-text ${overlayVisible ? 'd-block' : 'd-none'}`}
        onClick={showMedia}>More...</div>
      <div
        className="actions add-to-list-button"
        onClick={() => { addToList(medium) }}
      >
        {hasList ? <MdLibraryAdd title="Add or Edit Lists" /> : <MdOutlineLibraryAdd title="Add or Edit Lists" />}
      </div>
    </div>
  )
}