import React, { useEffect, useState } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setLoggedInUser } from '../../actions/user'
import { api_client } from '../../config/api-client'
import { RECAPTCHA_TOKEN } from '../../config/constants'
import useReCaptcha from '../../hooks/useReCaptcha'
import './LoginForm.scss'
import { setMediaModalData } from '../../actions/media_modal_data'
import { useParams, useLocation } from 'react-router-dom'
import logo from '../../assets/images/vuniverse-logo-red.png'
import PasswordField from './PasswordField'

export default function LoginForm() {
  const user = useSelector((state) => state.user)
  const media_modal_data = useSelector((state) => state.media_modal_data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
  const { mediaId } = useParams()
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha()

  useEffect(() => {
    if(user.id) {
      navigate('/')
    }
  })

  useEffect(() => {
    if(mediaId && (!media_modal_data || !media_modal_data.visible || !media_modal_data.medium || media_modal_data.medium.id !== mediaId)) {
      dispatch(setMediaModalData({ visible: true, medium: { id: mediaId } }))
    }
  }, [])

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      submit()
    }
  }

  const submit = async () => {
    await generateReCaptchaToken('login')
    setErrors('')
    api_client().post('/users/sign_in.json', {
      user: {
        email: email,
        password: password
      },
      recaptcha: localStorage.getItem(RECAPTCHA_TOKEN),
    }).then((response) => {
      const user = response.data.user
      dispatch(setLoggedInUser(user, user.access_token))
    }).catch((error) => {
      if(error.response && error.response.data) {
        if(error.response.data.message) {
          setErrors(error.response.data.message)
        } else {
          setErrors(error.response.data.error)
        }
      } else {
        console.log(error.response)
      }
    })
  }

  return (
    <>
      {!user.id && <div className="login-form">
        <div style={{ marginTop: '15px'}}>
          <img src={logo} alt="VUniverse" style={{ width: '100px', height: '100px'}} />
        </div>
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <div className="shadowed-box">
                <h2>Login</h2>
                <Form.Group controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={email} onChange={(event) => { setEmail(event.target.value) }}/>
                </Form.Group>
                <Form.Group controlId='password'>
                  <Form.Label>Password</Form.Label>
                  <PasswordField 
                    value={password}
                    onKeyPress={onKeyPress}
                    setPassword={setPassword}
                    />
                </Form.Group>
                <div className='centered' style={{ marginTop: '15px'}}>
                  {errors && <div className='error'>{errors}</div>}
                  <Button disabled={!reCaptchaLoaded} className="btn-success top-margin-button top-20" onClick={submit}>Login</Button>
                  <div className="top-20">
                    No account yet? <Link to="/register" className="simple-link">Sign up</Link>
                  </div>
                  <div className="top-20">
                    <Link to="/users/password/forgot" className="simple-link">Forgot password?</Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>}
    </>
  )
}
