import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import { Container, Row, Col } from 'react-bootstrap'
import './PublicPage.scss'

export default function TermsPage() {
  const user = useSelector(state => state.user)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    if (user.access_token && user.signup_step > LAST_SIGNUP_STEP) {
      if(!loggedIn) setLoggedIn(true)
    } else {
      if(loggedIn) setLoggedIn(false)
    }
  }, [user])

  return (
    <>
      <pre className={loggedIn ? "pre-logged-in" : "pre-not-logged"}>
      
      VUniverse, Inc. Terms of Service<br />
Updated: August 29th, 2019<br />
Please carefully read this Terms of Service (“TOS” or “Agreement”). The TOS constitutes a legal agreement between you the user (“you”) and VUniverse Inc. (“VUniverse”) The TOS governs your use of VUniverse’s products and services, and affects your related legal rights.<br />
<br />
The TOS includes an agreement, to the extent permitted under law, to resolve any disputes that may arise between you and Vunvierse by arbitration on an individual basis, instead of by class actions or jury trials. Please review sections 17 (Arbitration Agreement) and 18 (Class Action Waiver) regarding those dispute resolution terms.<br />
<br />
For ease of reference, you may also access this Agreement (or print a copy) from our website at https://vuniverse.com/terms.<br />
<br />
1. INTRODUCTION<br />
1.1 OVERVIEW OF TOS<br />
This TOS sets forth the terms that govern your use of VUniverse, VUniverse Inc. services, as well as the use of our website and mobile apps (“VUniverse Services” or the “Services”). VUniverse Services also include integrated services — products and services that may be integrated into third-party websites, mobile apps and Smart TV apps.<br />
<br />
This TOS is a legal agreement between you and VUniverse. By choosing to accept this TOS, you will be able to use VUniverse Services on devices such as a TV, tablet and mobile device, subject to the terms set forth in this Agreement.<br />
<br />
THIS AGREEMENT CONTAINS WARRANTY AND LIABILITY DISCLAIMERS. BY USING OR SUBSCRIBING TO VUNIVERSE SERVICES, YOU ACCEPT AND AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IN ORDER FOR YOU TO HAVE ACCESS TO VUNIVERSE SERVICES, THIS AGREEMENT MUST BE ACCEPTED BY YOU WITHOUT MODIFICATIONS, ADDITIONS, OR DELETIONS. IF YOU DO NOT AGREE TO THE TERMS CONTAINED IN THIS AGREEMENT, YOU ARE NOT AUTHORIZED TO USE VUNIVERSE SERVICES. YOU MAY BE DENIED ACCESS TO VUNIVERSE SERVICES, WITH OR WITHOUT PRIOR NOTICE TO YOU, FOR NONCOMPLIANCE WITH ANY PROVISION OF THIS AGREEMENT.<br />
<br />
DO NOT USE VUNIVERSE SERVICES IF YOU DO NOT AGREE TO THE TERMS OF SERVICE. YOUR CONTINUING USE OF VUNIVERSE SERVICES MEANS YOU AGREE TO BE BOUND BY THIS AGREEMENT.<br />
<br />
1.2 DESCRIPTION OF VUNIVERSE SERVICES<br />
VUniverse provides home entertainment services for viewing, receiving recommendations, and interacting with, videos, images, artwork and other content (“Content”) through consumer electronics — such as an Internet-capable or Smart TV, Blu-Ray disc player, Set Top Box, phone, tablet, PC, watch, or other device — with Internet connectivity (the “Equipment”). Since the Equipment is manufactured by a third-party, please consult the user manual or user guide provided by the manufacturer for instructions on proper use of the Equipment.<br />
<br />
Should you have any questions or require technical support for VUniverse Services, please contract VUniverse by visiting https://vuniverse.com/contact.<br />
<br />
1.3 PARENTAL ADVISORY<br />
VUNIVERSE SERVICES ARE NOT INTENDED FOR INDIVIDUALS UNDER THE AGE OF 18.<br />
<br />
WE DO NOT INTENTIONALLY COLLECT PERSONAL INFORMATION FROM CHILDREN ON OUR SITE OR THROUGH OUR SERVICES.<br />
<br />
BECAUSE VUNIVERSE MAY PROVIDE ACCESS TO CONTENT THAT IS EXPLICIT — INCLUDING STRONG LANGUAGE OR DEPICTIONS OF VIOLENCE, SEX OR SUBSTANCE ABUSE — PARENTAL DISCRETION IS ADVISED.<br />
<br />
1.4 CHANGES TO TOS<br />
OCCASIONALLY WE MAY, IN OUR DISCRETION, MAKE CHANGES TO THE TERMS OF SERVICE. YOU SHOULD PERIODICALLY VISIT VUNIVERSE’S WEBSITE AT HTTPS://VUNIVERSE.COM/TERMS SO YOU ARE AWARE OF THE TERMS AND CONDITIONS THAT GOVERN YOUR USE OF VUNIVERSE SERVICES. IN SOME CASES, WE WILL NOTIFY YOU IN ADVANCE OF CHANGES TO THE TOS. IN SUCH CASES YOUR CONTINUED USE OF VUNIVERSE SERVICES WILL CONSTITUTE YOUR ACCEPTANCE OF THOSE CHANGES. THEREFORE, PLEASE MAKE SURE YOU READ ANY SUCH NOTICE CAREFULLY.<br />
<br />
IF YOU DO NOT AGREE TO A MODIFIED VERSION OF THE TOS, YOU SHOULD DISCONTINUE USE OF VUNIVERSE SERVICES.<br />
<br />
2. AUTHORIZED USERS<br />
2.1 AUTHORITY TO ENTER THIS AGREEMENT<br />
You represent that 1) you are at least 18 years old, 2) you have read and understand this Agreement and VUniverse’s Privacy Policy, and 3) that you agree to be bound by this Agreement and subject to our Privacy Policy. If you do not agree or cannot comply with all of the terms and conditions of this Agreement, or if you do not agree that VUniverse can use your personal information in accordance with our Privacy Policy, please do not attempt to access, enable or use any VUniverse Services.<br />
<br />
2.2 REGISTRATION<br />
You may be required to register to use certain VUniverse Services, and provide certain information, such as a member or user name, a password and a valid email address (the “Account Information”). If so required, you agree to provide accurate Account Information and to update your Account Information as necessary to keep it accurate. VUniverse will use your Account Information in accordance with our Privacy Policy available at https://vuniverse.com/privacy/. By providing VUniverse your email address, you consent to our using the email address to send you notices, including, among other things, notices required by law in lieu of postal mail. You may not opt out of receiving such e-mails. We may also use your email address — or for subscribers to mobile Services, your mobile telephone number — to send you other messages, including information about VUniverse, VUniverse Services and special offers. You may opt-out of such email messages through the link in the message or by changing your account settings.<br />
<br />
Note that opting out may prevent you from receiving important messages regarding VUniverse Services or special offers.<br />
<br />
2.3 ACCOUNT CONFIDENTIALITY<br />
You agree that you will not allow others to use any aspect of your Account Information. You have responsibility for taking steps to maintain the confidentiality and security of your account. You agree to notify us immediately of any unauthorized use of your account or password. We will not be responsible for any losses arising out of the unauthorized use of your account, member name or password. You further agree to indemnify and hold us harmless, along with our partners, parents, subsidiaries, agents, affiliates and licensors, as applicable, for any improper, unauthorized or illegal uses of your account, member name or password.<br />
<br />
3. LICENSE TO USE VUNIVERSE INC.<br />
VUniverse grants you a limited, non-exclusive, non-transferable license to access and use VUniverse Services for personal purposes only. Any violation by you of the license provisions contained in this Section 3 may result in the immediate termination of your right to use VUniverse Services. VUniverse reserves all rights, titles and interests not expressly granted under this license to the fullest extent possible under applicable laws. ANY USE OF VUNIVERSE SERVICES NOT SPECIFICALLY PERMITTED UNDER THIS AGREEMENT IS STRICTLY PROHIBITED.<br />
<br />
4. UPDATES<br />
VUniverse may, from time to time, make available updates to all users of VUniverse Services at no cost or subject to additional fees in our sole discretion. “Updates” means any updates, upgrades or error corrections to VUniverse Services that we make available generally to users. Notwithstanding anything else contained in this Agreement, we have no obligation to continue producing or releasing new versions of VUniverse Services or any Updates thereto.<br />
<br />
5. CONTENT & RESTRICTIONS<br />
You understand and agree that Content and any metadata may be licensed by VUniverse from content providers (“Content Providers”). All Content is licensed — not sold, transferred or assigned — to you for personal, non-commercial use only via VUniverse Services and on VUniverse-enabled Equipment.<br />
<br />
You will not, and will not allow anyone else to open; tamper with; reverse engineer; decompile; or disassemble your Equipment or any software contained within, including VUniverse Services. Furthermore, you will not, and will not allow anyone else to make any modifications, additions, attachments, or alterations to the Equipment, or any software contained within, including VUniverse Services. Additionally, you agree not to, or attempt to, disclose or circumvent any security measures utilized as part of VUniverse Services, including the Equipment or software contained within. You understand and acknowledge that VUniverse Services and Equipment, as well as the software contained within, incorporate and utilize propriety technologies, materials and Content that is protected by copyright, patent, trade secret and other applicable laws. As a result, any use or disclosure of the VUniverse Services, Content, or Equipment or the software within, contrary to this Agreement — or any copying of the materials or Content residing in the Equipment — may cause substantial damages to us, or our licensors, and may subject you to both civil liabilities or criminal penalties.<br />
<br />
You agree that you will not:<br />
<br />
• Use VUniverse Services to reproduce copyrighted materials for which you do not own the copyrights;<br />
• Unless expressly and specifically enabled and authorized to do so by a VUniverse Service, copy, store, edit, change, prepare any derivative work of or alter in any way any Content provided through VUniverse Services;<br />
• Provide your password to any other person;<br />
• Translate, reverse engineer, decompile, disassemble, modify or create derivative works based on VUniverse Services or any portion of them;<br />
• Circumvent any technology used by VUniverse or its licensors or partners to protect content accessible via VUniverse Services;<br />
• Rent, lease or sublicense any of the VUniverse Services; or<br />
• Use VUniverse Services in any way that violates the terms of this Agreement.<br />
<br />
Without limiting the generality of the foregoing, unless specifically enabled and authorized to do so by VUniverse, you may not duplicate, reproduce, transfer, record, or create copies of Content or any portion thereof, including, without limitation, by “burning,” P2P file-sharing, posting, uploading or downloading onto any physical medium, memory or device, including without limitation, CDs, DVDs, VCDs, portable media devices, computers or other hardware or any other medium now owned or hereinafter devised. You may not attempt to circumvent, avoid, bypass, remove, deactivate, impair or otherwise defeat any encryption, rights signaling or copy protection technology in which the Content is wrapped or otherwise associated. You may not decompile, copy, reproduce, reverse engineer, disassemble or otherwise reduce the computer file in which the Content is stored to a human-readable form. Any unauthorized copying or other activities that infringe upon the intellectual property rights of owners of the Content are prohibited, and you expressly agree to the automatic termination of VUniverse Services if you engage in any infringing activities. Upon any such termination, Content may be deleted from your Equipment.<br />
<br />
6. COPYRIGHTS & THE DIGITAL MILLENNIUM COPYRIGHT ACT<br />
VUniverse Inc. uses the collected data for various purposes:<br />
<br />
As between you and VUniverse, you acknowledge that we own or have a license to all title and copyrights in and to the VUniverse Services we have created. All title and intellectual property rights in and to the Content in VUniverse Services is the property of the respective Content owner(s) or provider(s), and may be protected by applicable copyright or other intellectual property laws and treaties, and subject to use restrictions under such laws or treaties. No right, title or interest in Content is transferred to you.<br />
<br />
If you send us any submissions – such as emails, letters, text messages, postings to chat rooms, boards, or contests, feedback, creative suggestions, reviews, ideas, notes, drawings, concepts, or other information — they will be deemed to be our property and we will not be liable for any use or disclosure of such submissions. Without limitation of the foregoing, we shall exclusively own all now-known or hereafter existing rights to the submissions of every kind and nature throughout the universe, and shall be entitled to unrestricted use of the submissions for any purpose whatsoever, commercial or otherwise, without compensation to the provider of the submissions. You assign such rights to us absolutely, so that all such rights vest in us immediately and automatically at the time the submissions are sent to us. You hereby irrevocably and unconditionally waive all moral rights and consent to any acts or omissions in respect of each submission which may otherwise constitute an infringement of your moral rights, including: any use of the submission that does not identify you; falsely attributing the authorship of the submission or any content of the submission to another person; materially altering the style, format, colors, content or layout of the submission; and, handling or dealing in any way with the altered submission.<br />
<br />
The content of any submissions must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights or otherwise injurious to third-parties or otherwise objectionable; and, may not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of “spam.” You may not use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of the content. We reserve the right, but have no obligation, to remove or edit such content. Note we do not regularly review posted content. You may be held liable for the unauthorized disclosure of third-party content in the Services.<br />
<br />
If you are a copyright owner, or an agent thereof, and believe that any Content, user submission, or other content infringes upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent with the following information in writing (see 17 U.S.C. § 512(c)(3) for further detail):<br />
<br />
• A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;<br />
• Identification of the copyrighted work claimed to have been infringed;<br />
• Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit us to locate the material;<br />
• Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an email address;<br />
• A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law; and,<br />
• A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br />
<br />
Our designated Copyright Agent to receive notifications of claimed infringement is legal@vuniverse.com, or:<br />
<br />
VUniverse Inc.<br />
Attn: Copyright Agent<br />
575 Fifth Avenue<br />
New York, NY 10017<br />
<br />
Only DMCA notices should go to the Copyright Agent. Any other feedback, comments, requests for technical support, and other communications should be submitted by visiting https://VUniverse.com/contact. You acknowledge that if you fail to comply with all of the requirements of this Section 6, your DMCA notice may not be valid.<br />
<br />
If you believe that Content or a submission of yours that was removed, or to which access was disabled, is not infringing — or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use such content or submission — you may send a counter-notice containing the following information to the Copyright Agent:<br />
<br />
• Your physical or electronic signature;<br />
• Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;<br />
• A statement that you have a good faith belief that the content was removed or disabled as a result of a mistake or a misidentification of the content; and<br />
• Your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court in New York County, New York, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.<br />
<br />
If a counter-notice is received by the Copyright Agent, we may send a copy of the counter-notice to the original complaining party informing that person that the removed content may be restored or no longer disabled in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, within 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion, the removed content may be replaced or access to it restored.<br />
<br />
7. VUNIVERSE TRADEMARKS & THIRD-PARTY TRADEMARKS<br />
The following are registered trademarks or trademarks of VUniverse, VUniverse Inc., and VUniverse’s design logo; as well as certain other trademarks, service marks, graphics and logos (“VUniverse Trademarks”) used in connection with VUniverse Services. VUniverse Services may contain third-party trademarks, service marks, graphics and logos. You are not granted any right or license with respect to VUniverse Trademarks or the trademarks of any third-party.<br />
<br />
8. SECURITY<br />
To prevent unauthorized access, to maintain data accuracy, and to ensure the proper use of Account Information, VUniverse uses appropriate physical, technical and organizational security measures to safeguard the information we collect. You acknowledge that, from time to time, VUniverse may modify or discontinue using such technology. Security modifications made by VUniverse may from time to time include required updates to VUniverse Services.<br />
<br />
We use Secure Sockets Layer (“SSL”) encryption when collecting or transferring sensitive data such as credit card information. SSL encryption is designed to make the information unreadable by anyone but us. Credit card numbers are used only for processing payments and are not used for other purposes.<br />
<br />
IF YOU ATTEMPT TO VIOLATE OR CIRCUMVENT ANY SYSTEM OR NETWORK SECURITY COMPONENTS OR TECHNOLOGY, YOU MAY BE SUBJECT TO CIVIL OR CRIMINAL LIABILITY.<br />
<br />
9. PRIVACY POLICY<br />
VUniverse cares about your privacy. By agreeing to this TOS, you acknowledge you have read and understand VUniverse’s Privacy Policy.<br />
<br />
It is important that you remain updated with the most recent terms of VUniverse’s Privacy Policy located at https://vuniverse.com/privacy, as it may be updated from time to time. If we make changes, we will revise the date at the top of the policy and provide notice in accordance with the applicable law. As set forth more fully therein, VUniverse may cooperate with and disclose your personal information (including your Account Information) to any authority, government official or third-party (“Authority”), without giving any notice to you, if 1) the Authority duly makes a request to VUniverse in accordance with the applicable law, and 2) VUniverse has an adequate legal basis for complying with the request. VUniverse may also disclose your personal information if we believe your actions are inconsistent with our user agreements, or to protect the rights, property or safety of VUniverse or others.<br />
<br />
You are responsible for complying with any applicable data protection laws with respect to your use of the VUniverse Services. You must ensure that you have obtained all necessary authorizations and consents from all users in your household to allow us to collect and use data, including content viewing information, through the Services, as outlined in the Privacy Policy.<br />
<br />
10. AGREEMENT TO PAY<br />
When you opt in to VUniverse Interactive TV or other Smart TV Services, there is no cost or obligation to pay. If you elect to purchase certain other VUniverse Services, you agree to pay all charges associated with such purchase. All such charges (including any taxes, as applicable) will be charged on your credit card. If you purchase any VUniverse Services, you will maintain valid credit card information in your Account Information.<br />
<br />
11. TERM<br />
This Agreement will remain effective until terminated by you or VUniverse.<br />
<br />
You may terminate this Agreement by discontinuing use of VUniverse Services.<br />
<br />
VUniverse will provide you notice of termination as required by the applicable law. Otherwise, VUniverse may terminate this Agreement or suspend your access to VUniverse Services at any time without notice.<br />
<br />
This Agreement will immediately terminate if you are in a material breach of any of its provisions.<br />
<br />
Sections 1, 5 through 8, 11 through 22, as well as any other sections of this Agreement that, either explicitly or by their nature, must remain in effect even after termination of the Agreement, will survive any such termination and continue in effect.<br />
<br />
12. WARRANTY & DISCLAIMERS<br />
12.1 DISCLAIMER OF EXPRESS & IMPLIED WARRANTIES<br />
VUNIVERSE SERVICES (INCLUDING ANY SOFTWARE CONTAINED THEREIN) AND ANY UPGRADES OR PLUG-INS AND ANY CONTENT ARE LICENSED TO YOU “AS IS.” ANY USE OF VUNIVERSE SERVICES WILL BE AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VUNIVERSE DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. HOWEVER, TO THE EXTENT THAT LAWS OF SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN THE EVENT THAT THE SERVICES PROVE DEFECTIVE, BUT DO PERMIT VUNIVERSE TO LIMIT THE CONSEQUENCES OF SUCH WARRANTIES, AT OUR ELECTION, WE LIMIT OUR LIABILITY TO THE RESUPPLY OF SUCH SERVICES OR PAYMENT OF THE COST OF RESUPPLY OF SUCH SERVICES.<br />
<br />
12.2 DISCLAIMER OF GUARANTEES<br />
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VUNIVERSE MAKES NO REPRESENTATIONS OR GUARANTEES THAT VUNIVERSE SERVICES WILL BE FREE FROM LOSS, DESTRUCTION, DAMAGE, CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND VUNIVERSE DISCLAIMS ANY LIABILITY RELATING THERETO.<br />
<br />
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VUNIVERSE MAKES NO GUARANTEES, REPRESENTATIONS, OR WARRANTIES THAT USE OR RESULTS OF THE USE OF VUNIVERSE SERVICES WILL BE ACCURATE, RELIABLE, CURRENT, UNINTERRUPTED OR WITHOUT ERRORS. WITHOUT PRIOR NOTICE, VUNIVERSE MAY MODIFY, SUSPEND, OR DISCONTINUE VUNIVERSE SERVICES (INCLUDING ANY CONTENT PROVIDED THEREBY) OR YOUR USE OF THEM. WHENEVER VUNIVERSE ELECTS TO MODIFY, SUSPEND, OR DISCONTINUE VUNIVERSE SERVICES, IT WILL NOT BE LIABLE TO YOU OR ANY THIRD-PARTY.<br />
<br />
12.3 DISCLAIMER REGARDING LOSS OR LIABILITY<br />
YOU ACKNOWLEDGE THAT YOUR SUBMISSION OF ANY INFORMATION OR DATA TO US IS AT YOUR OWN RISK. VUNIVERSE DOES NOT ASSUME ANY LIABILITY TO YOU WITH REGARD TO ANY LOSS OR LIABILITY RELATING TO SUCH DATA OR INFORMATION IN ANY WAY.<br />
<br />
12.4 Disclaimer Regarding Offensive Content<br />
YOU UNDERSTAND THAT YOU MAY ENCOUNTER OFFENSIVE, INDECENT OR OTHER OBJECTIONABLE CONTENT WHEN USING VUNIVERSE SERVICES. VUNIVERSE HAS NO LIABILITY TO YOU FOR ANY SUCH CONTENT.<br />
<br />
12.5 DISCLAIMER REGARDING THIRD-PARTY CONTENT<br />
SOME OF THE CONTENT, PRODUCTS, AND SERVICES AVAILABLE THROUGH VUNIVERSE SERVICES MAY INCLUDE MATERIALS THAT BELONG TO THIRD-PARTIES. YOU ACKNOWLEDGE THAT VUNIVERSE ASSUMES NO RESPONSIBILITY FOR SUCH CONTENT, PRODUCTS, OR SERVICES.<br />
<br />
13. LIMITATIONS OF LIABILITY<br />
REGARDLESS OF WHETHER VUNIVERSE HAS BEEN ADVISED OF THE POSSIBILITY, IN NO EVENT WILL VUNIVERSE (OR ITS LICENSORS) BE LIABLE TO YOU FOR INDIRECT, GENERAL, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES, INCLUDING, WITHOUT LIMITATION: DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, CORRUPTION OF FILES, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS. NO ORAL OR WRITTEN INFORMATION, OR ADVICE GIVEN BY VUNIVERSE OR OTHERS, WILL CREATE A WARRANTY; AND, NEITHER YOU NOR ANY THIRD-PARTY MAY RELY ON ANY SUCH INFORMATION OR ADVICE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS AND LIMITATIONS MAY OR MAY NOT APPLY TO YOU.<br />
<br />
SUBJECT TO ANY RIGHTS PROVIDED UNDER APPLICABLE LAWS, VUNIVERSE’S ENTIRE LIABILITY AND YOUR<br />
<br />
EXCLUSIVE REMEDY UNDER THIS AGREEMENT WILL BE LIMITED TO THE GREATER OF ONE HUNDRED DOLLARS (US $100.00), OR THE<br />
<br />
TOTAL AMOUNT PAID BY YOU FOR THE APPLICABLE VUNIVERSE SERVICES AT ISSUE.<br />
<br />
14. INDEMNITY<br />
YOU WILL INDEMNIFY, DEFEND AND HOLD VUNIVERSE, ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS AND LICENSORS HARMLESS WITH RESPECT TO ANY SUITS OR CLAIMS ARISING OUT OF 1) YOUR BREACH OF THIS AGREEMENT, INCLUDING, BUT NOT LIMITED TO, ANY INFRINGEMENT BY YOU OF THE COPYRIGHT OR INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD-PARTY; OR 2) YOUR USE OF THE VUNIVERSE SERVICES.<br />
<br />
15. GENERAL<br />
15.1 SCREEN & OTHER NECESSARY HARDWARE<br />
You will be responsible for providing the DSL, cable modem and any other hardware (such as a screen) necessary to use VUniverse Services with the Equipment.<br />
<br />
15.2 THIRD-PARTY ADVERTISEMENTS & PROMOTIONAL MATERIALS<br />
VUniverse or its partners may present advertisements or promotional materials via VUniverse Services. Your dealings with, or participation in promotions of any third-party advertisers via VUniverse Services are solely between you and such third parties; and, your participation is subject to the third-parties’ terms and conditions associated the advertisement or promotion. You agree that VUniverse is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings, or as the result of the presence of such third parties via VUniverse.<br />
<br />
15.3 THIRD-PARTY WEBSITES & SERVICES<br />
VUniverse may present links to third-party websites or third-party services not owned or operated by us. We are not responsible for the availability of these third-party sites or services or their contents. You agree that we are not responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with your use of or reliance on any content of any such third-party site or service, or goods or services available through any such third-party site or service.<br />
<br />
15.4 NO RIGHT TO LICENSE VUNIVERSE SERVICES<br />
VUniverse Services are owned or licensed by VUniverse or its partners and subsidiaries and are protected by United States and foreign copyright laws, as well as international treaty provisions. You will not sublicense, assign, or transfer the license granted to you under this Agreement. Any attempt to sublicense, assign, or transfer any of the rights, duties or obligations in violation of this Agreement is void.<br />
<br />
15.5 VUNIVERSE INC. NOTIFICATIONS<br />
VUniverse may provide notifications, whether such notifications are required by law or are for marketing or other business related purposes. Where required by the applicable law, we will obtain your consent before sending you such notifications. In accordance with the applicable law, we may do so via email, mobile text message, written or hard copy notice, or through conspicuous posting through our Services, or on our website, VUniverse reserves the right to determine the form and means of providing notifications to you, provided that you may, opt out of receiving marketing notifications by following the instructions set out in Section 2.2 (Registration) or following the instructions in those notifications.<br />
<br />
15.6 RESETTING OF SMART TV UPON SALE<br />
If you intend to sell or otherwise give away your Smart TV, you must perform a “factory reset.” This will 1) reset the VUniverse advertising ID, and 2) allow the new owner of the TV to choose whether they want to opt in or opt out of VUniverse Services.<br />
<br />
16. CHOICE OF LAW & VENUE<br />
Without regard to choice or conflicts of law principles, the interpretation of the TOS — as well as any non-contractual disputes or claims arising out of or in connection with it or between you and VUniverse — is subject to the laws of the State of New York, United States of America.<br />
<br />
Further, if not otherwise subject to mandatory arbitration under Section 17 (Arbitration Agreement), you and VUniverse irrevocably consent and agree to the exclusive jurisdiction of the Southern District of New York to resolve any dispute, claim or controversy that relates to or arises in connection with the TOS, as well as any non-contractual disputes or claims relating to or arising in connection with the TOS. You waive any right to object to such exclusive jurisdiction and venue being inconvenient.<br />
<br />
VUniverse does not accept any codes of conduct as mandatory in connection with the Services provided under this TOS.<br />
<br />
17. ARBITRATION AGREEMENT<br />
17.1 SECTION ONLY APPLICABLE TO USERS IN THE UNITED STATES & CANADA<br />
THIS ARBITRATION AGREEMENT SET FORTH HEREIN IN SECTION 17 APPLIES ONLY TO USERS IN THE UNITED STATES AND CANADA.<br />
<br />
17.2 Dispute Resolution & Arbitration<br />
You and VUniverse agree that any dispute, claim, or controversy between you and VUniverse arising in connection with or relating in any way to this TOS or to your relationship with VUniverse as a use of the Services, will be determined by mandatory binding individual — not class arbitration. Thus, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory, and whether the claims arise during or after the termination of the TOS any dispute, claim, or controversy between you and VUniverse arising in connection with or relating in any way to this TOS or to your relationship with VUniverse as a user of the Services, will be determined by mandatory binding individual arbitration, by one arbitrator.<br />
<br />
You and VUniverse further agree that the arbitrator shall have the exclusive power to rule on his or her own jurisdiction, including any objections with respect to the existence, scope or validity of the Arbitration Agreement; or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this Agreement and can award the same damages and relief as a court (including attorney fees), except that the arbitrator may not award any relief, including declaratory or injunctive relief, benefiting anyone but the parties to the arbitration. This arbitration provision will survive termination of the Agreement.<br />
<br />
17.3 ARBITRATION RULES<br />
Either you or we may start arbitration proceedings. Any arbitration between you and VUniverse will take place under the Consumer Arbitration Rules of the American Arbitration Association (“AAA”) then in force (the “AAA Rules”), as modified by this Arbitration Agreement set forth here in Section 17. Notwithstanding the Choice of Law terms set forth in Section 16, you and VUniverse agree that the Federal Arbitration Act applies and governs the interpretation and enforcement of this provision. The AAA Rules, as well as instructions on how to file an arbitration proceeding with the AAA, appear at https://www.adr.org; or you may call the AAA at 1-800-778-7879. VUniverse can also help put you in touch with the AAA.<br />
<br />
Any arbitration hearings will take place in New York, NY, provided that if the claim is for $25,000 or less, VUniverse, in its sole discretion, may choose whether the arbitration will be conducted 1) solely on the basis of documents submitted to the arbitrator; 2) through a non-appearance based telephonic hearing; or 3) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address.<br />
<br />
If you choose to file an arbitration proceeding, you will be responsible for the filing fee. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding; and, upon a request from either party made within 15 days of the arbitrator’s ruling on the merits. In any arbitration arising out of or related to this TOS, the arbitrator shall award to the substantially prevailing party, if any, the costs and attorneys’ fees reasonably incurred by such party in connection with the arbitration.<br />
<br />
17.4 NOTICES & PROCESS<br />
A party who intends to seek arbitration must first send a written notice (“Notice”) of the dispute to the other, by certified mail, Federal Express, UPS, or Express Mail (signature required). In the event that we do not have a physical address on file for you, we may send you Notice by electronic mail.<br />
<br />
VUniverse's address for Notice is:<br />
<br />
VUniverse Inc.<br />
575 Fifth Avenue<br />
New York, NY 10017<br />
USA.<br />
<br />
The Notice must 1) describe the nature and basis of the claim or dispute; and 2) set forth the specific relief sought ("Demand"). We agree to use good faith efforts to resolve the claim directly, but if we do not reach an agreement to do so within 30 days after the Notice is received, you or VUniverse may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or VUniverse shall not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. In the event our dispute is finally resolved through arbitration in your favor, and the arbitrator issues you an award that is greater than the value of VUniverse’s last written settlement offer, then VUniverse will instead pay you either the amount of the award or $1,000, whichever is greater. All documents and information disclosed in the course of the arbitration shall be kept strictly confidential by the recipient and shall not be used by the recipient for any purpose other than for purposes of the arbitration, or the enforcement of the arbitrator’s decision and award; and, shall not be disclosed except in confidence to persons who have a need to know for such purposes or as required by applicable law.<br />
<br />
17.5 EXCEPTIONS TO ARBITRATION<br />
Notwithstanding the above, you and VUniverse both agree that nothing in Section 17 will be deemed to waive, preclude, or otherwise limit either of our rights, at any time, to 1) bring an individual action in a U.S. small claims court or 2) bring an individual action seeking only temporary or preliminary individualized injunctive relief in a court of law. In addition, this Arbitration Agreement doesn’t stop you or us from bringing issues to the attention of federal, state, or local agencies. Such agencies can, if the law allows, seek relief against us on your behalf or vice versa.<br />
<br />
17.6 RIGHTS TO OPT OUT OF ARBITRATION PROVISIONS<br />
You have the right to opt out and not be bound by the arbitration and class action waiver provisions set forth above by sending an email of your decision to opt-out to the following email address: legal@vuniverse.com<br />
<br />
The notice must be sent within 30 days of acceptance of this Agreement. Otherwise you shall be bound to arbitrate disputes in accordance with the terms of this Agreement. If you opt-out of these arbitration provisions, VUniverse also will not be bound by them.<br />
<br />
17.7 ENFORCEABILITY<br />
If the Arbitration Agreement under Section 17 is invalidated in whole or in part, the parties agree that the exclusive jurisdiction and venue described in Section 16 shall govern any claim in court arising out of or related to the TOS.<br />
<br />
18. NO CLASS OR REPRESENTATIVE PROCEEDINGS: CLASS ACTION WAIVER<br />
YOU AND VUNIVERSE AGREE, SUBJECT TO THE LIMITATIONS OF THE APPLICABLE LAW, THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and VUniverse agree, no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any form of a representative or class proceeding. The arbitrator may award injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. If a court decides that applicable law precludes enforcement of any of this paragraph’s limitations as to a particular claim for relief, then that claim — and only that claim — must be severed from the arbitration and may be brought in court.<br />
<br />
19. REMEDIES, LIMITATION & TIME FOR FILING CLAIMS<br />
YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH VUNIVERSE OR ITS SERVICES IS TO DISABLE AND TO STOP USING VUNIVERSE SERVICES. WHILE VUNIVERSE ACCEPTS NO RESPONSIBILITY FOR THIRD-PARTY APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR RELATIONSHIP WITH SUCH THIRD-PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT TO VUNIVERSE, FOR ANY PROBLEMS OR DISSATISFACTION WITH THIRD-PARTY APPLICATIONS OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP USING ANY SUCH THIRD-PARTY APPLICATIONS.<br />
<br />
Some aspects of this Section 19 may not apply in some jurisdictions if prohibited by applicable law.<br />
<br />
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ANY CLAIM AGAINST VUNIVERSE MUST BE COMMENCED BY FILING A DEMAND FOR ARBITRATION UNDER SECTION 17.4 OR FILING AN INDIVIDUAL ACTION UNDER SECTION 17.5 WITHIN ONE (1) YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION, OR DEFAULT GIVING RISE TO THE CLAIM; AND, THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.<br />
<br />
You agree that any violation or breach by you of this TOS may result in irreparable harm to us, that monetary damages will be an inadequate remedy, and you hereby agree that We shall be entitled to seek injunctive relief without the requirement of proving actual damage or posting a bond or other security.<br />
<br />
20. ASSIGNMENT<br />
VUniverse may assign this Agreement or any part of it, and VUniverse may delegate any of its obligations under the Agreement. You may not assign the Agreement or any part of it, nor transfer or sub-license your rights under the Agreement, to any third-party.<br />
<br />
21. SEVERABILITY & WAIVER<br />
Unless as otherwise stated in the Agreement, should any provision of the TOS be held invalid or unenforceable for any reason or to any extent, such invalidity or enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreement, and the application of that provision shall be enforced to the extent permitted by law.<br />
<br />
Any failure by VUniverse or any third-party beneficiary to enforce the Agreement, or any provision thereof, shall not waive VUniverse’s or the applicable third-party beneficiary’s right to do so.<br />
<br />
22. ENTIRE AGREEMENT<br />
Other than as stated in this Section 22, or as explicitly agreed upon in writing between you and VUniverse, this Agreement constitutes all the terms and conditions agreed upon between you and VUniverse and supersedes any prior agreements in relation to the subject matter of this Agreement, whether written or oral.<br />
<br />
Please note, however, that other aspects of your use of VUniverse Services may be governed by additional agreements. To the extent that there is any irreconcilable conflict between any additional terms and this TOS, the additional terms shall prevail.<br />
<br />
23. CONTACT US<br />
If you have any questions concerning the VUniverse’s Services or this TOS, please contact VUniverse customer support by visiting https://VUniverse.com/contact. If you are a California resident, you may have the TOS mailed to you electronically by making a request, with your email, to support@vuniverse.com.<br />
<br />
In addition, in order to resolve a complaint regarding the Services or to receive further information regarding their use, under New York Consolidated Code §350, you may contact the Complaint Assistance Unit of the Division of Consumer Protection of the New York Department of Consumer Affairs in writing at:<br />
<br />
New York State Department of State<br />
Division of Consumer Protection Consumer<br />
Assistance Unit 99 Washington Avenue<br />
Albany, New York 12231-0001<br />
<br />
Or by telephone at 1-800-697-1220.<br />
<br />
Thank you for reading our Terms of Service. We hope you enjoy VUniverse.<br />
<br />
YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT AND WILL BE BOUND BY ITS TERMS AND CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THIS AGREEMENT REPRESENTS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.
      
      </pre>
    </>
  )
}
