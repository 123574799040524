import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { api_client } from '../../config/api-client'
import { LAST_SIGNUP_STEP, MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH, RECAPTCHA_TOKEN, RECAPTCHA_KEY } from '../../config/constants'
import { useSelector, useDispatch } from 'react-redux'
import { setLoggedInUser } from '../../actions/user'
import UserMediumGenreSelector from './UserMediumGenreSelector'
import MediumProviderSelector from './MediumProviderSelector'
import StepMeter from './StepMeter'
import TermsModal from './TermsModal'
import './SignupForm.scss'
import PasswordField from '../Login/PasswordField'
import useReCaptcha from '../../hooks/useReCaptcha'

export default function SignupForm() {
  const EMAIL_STEP = 1
  const DISPLAY_NAME_STEP = 2
  const PASSWORD_STEP = 3
  const PROVIDER_STEP = 4
  const GENRE_STEP = 9999 // Removed from signup steps
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [displayName, setDisplayName] = useState('')
  const [mediumProviders, setMediumProviders] = useState([])
  const [mediumProvidersFeatured, setMediumProvidersFeatured] = useState([])
  const [userMediumGenres, setUserMediumGenres] = useState([])
  const [selectedGenresIds, setSelectedGenresIds] = useState([])
  const [selectedMediumProviderIds, setSelectedMediumProviderIds] = useState([])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [currentStep, setCurrentStep] = useState(1)
  const [validPassword, setValidPassword] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [confirmedAge, setConfirmedAge] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)
  const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha()

  const genreIsSelected = (genreId) => {
    return selectedGenresIds.some(item => genreId === item)
  }

  const mediumProviderIsSelected = (mediumProviderId) => {
    return selectedMediumProviderIds.some(item => mediumProviderId === item)
  }

  const changeGenreSelected = (genreId, newValue) => {
    if(newValue) {
      const newArray = [ ...selectedGenresIds, genreId]
      setSelectedGenresIds(newArray)
    } else {
      const newArray = selectedGenresIds.filter(function(selectedGenreId) {
        return selectedGenreId !== genreId
      })
      setSelectedGenresIds(newArray)
    }
  }

  const changeMediumProviderSelected = (mediumProviderId, newValue) => {
    if(newValue) {
      const newArray = [ ...selectedMediumProviderIds, mediumProviderId]
      setSelectedMediumProviderIds(newArray)
    } else {
      const newArray = selectedMediumProviderIds.filter(function(selectedMediumProviderId) {
        return selectedMediumProviderId !== mediumProviderId
      })
      setSelectedMediumProviderIds(newArray)
    }
  }

  const checkEmailExists = async () => {
    await generateReCaptchaToken('signup_email')
    api_client().post('/api/v1/users/check_email_exists.json', {
      email: email,
      recaptcha: localStorage.getItem(RECAPTCHA_TOKEN)
    }).then((response) => {
      if(response.data.email_exists) {
        setErrors({email: 'Email has already been taken'})
      } else {
        setCurrentStep(currentStep + 1)
      }
    }).catch((error) => {
      if(error.response && error.response.data) {
        if(error.response.data.message) {
          setErrors({email: error.response.data.message})
        } else {
          setErrors(error.response.data.errors)
        }
      } else {
        setErrors({ user: 'Unknown error trying to verify email existence' })
      }
    })
  }

  const checkDisplayNameExists = () => {
    api_client().post('/api/v1/users/check_display_name_exists.json', {
      display_name: displayName
    }).then((response) => {
      if(!response.data.valid || response.data.valid === '0') {
        setErrors({
          display_name: response.data.errors.replace("\n", "<br />")
        })
      } else {
        setCurrentStep(currentStep + 1)
      }
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to verify public name existence' })
      }
    })
  }

  const nextStep = () => {
    setErrors({})
    switch(currentStep) {
      case EMAIL_STEP:
        if (email === '') {
          setErrors({email: 'Email is required'})
        } else {
          checkEmailExists()
        }
        break;
      case DISPLAY_NAME_STEP:
        if (displayName === '') {
          setErrors({name: 'Display name is required'})
        } else {
          checkDisplayNameExists()
        }
        break;
      case PASSWORD_STEP:
        if (password === '') {
          setErrors({password: 'Password is required'})
        } else {
          submit();
        }
        break;
      case GENRE_STEP:
        updateSelectedGenres()
        break;
      case PROVIDER_STEP:
        updateSelectedMediumProviders()
        break;
      }
  }

  const updateSelectedGenres = () => {
    api_client(localStorage.getItem('access_token')).post('/api/v1/user_medium_genres/update_all.json', {
      genres_ids: selectedGenresIds
    }).then((response) => {
      setCurrentStep(currentStep + 1)
      if(currentStep === LAST_SIGNUP_STEP) {
        navigate('/')
      }
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to update genres' })
      }
    })
  }

  const updateSelectedMediumProviders = () => {
    api_client(localStorage.getItem('access_token')).post('/api/v1/user_medium_providers/update_all.json', {
      medium_providers_ids: selectedMediumProviderIds
    }).then((response) => {
      updateSignupStep(currentStep + 1, false)
      setCurrentStep(currentStep + 1)
      if(currentStep === LAST_SIGNUP_STEP) {
        navigate('/')
      }
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to update providers' })
      }
    })
  }

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      if(currentStep <= LAST_SIGNUP_STEP && !nextDisabled) {
        nextStep()
      }
    }
  }

  const submit = () => {
    api_client().post('/users.json', {
      user: {
        email: email,
        password: password,
        display_name: displayName,
        signup_step: currentStep + 1
      }
    }).then((response) => {
      const user = response.data
      dispatch(setLoggedInUser(user, user.access_token))
      setCurrentStep(currentStep + 1)
    }).catch((error) => {
      if(error.response && error.response.data.errors) {
        if(error.response.data.errors.email) {
          setCurrentStep(1)
        }
        setErrors(error.response.data.errors)
      } else {
        setErrors({ user: 'Unknown error trying to register user' })
      }
    })
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    if(user.id && accessToken !== null) {

      api_client(accessToken).get('/api/v1/user_medium_genres').then((response) => {
        const userMediumGenres = response.data.user_medium_genres
        const activeGenres = []
        for(var i = 0; i < userMediumGenres.length; i++) {
          if(userMediumGenres[i].active) {
            activeGenres.push(userMediumGenres[i].id)
          }
        }
        setSelectedGenresIds(activeGenres)
        setUserMediumGenres(userMediumGenres)
      })

      api_client(accessToken).get('/api/v1/user_medium_providers').then((response) => {
        const mp = response.data.medium_providers.filter(item => !item.featured)
        const mpFeatured = response.data.medium_providers.filter(item => item.featured)
        const groupedColumns = []
        const groupedColumnsFeatured = []
        for(var i = 0; i < mp.length; i+=2) {
          const rowItems = []
          rowItems.push(mp[i])
          if(i + 1 < mp.length) {
            rowItems.push(mp[i + 1])
          }
          groupedColumns.push(rowItems)
        }
        for(var i = 0; i < mpFeatured.length; i+=2) {
          const rowItems = []
          rowItems.push(mpFeatured[i])
          if(i + 1 < mpFeatured.length) {
            rowItems.push(mpFeatured[i + 1])
          }
          groupedColumnsFeatured.push(rowItems)
        }
        setMediumProviders(groupedColumns)
        setMediumProvidersFeatured(groupedColumnsFeatured)
        setSelectedMediumProviderIds(response.data.user_medium_provider_ids)
      })
    }
  }, [user, localStorage.getItem('access_token')])

  useEffect(() => {
    if(user && user.access_token && currentStep === 1) {
      if(!user.signup_step || user.signup_step < 5) {
        setCurrentStep(4)
      } else {
        setCurrentStep(user.signup_step)
      }
    }
  }, [user])

  const updateSignupStep = (stepNumber, redirectToPlans) => {
    const accessToken = localStorage.getItem('access_token')
    if(user.id && accessToken !== null && stepNumber > 3) {
      api_client(accessToken).patch('/api/v1/user.json', {
        user: {
          signup_step: stepNumber
        }
      }).then((response) => {
        const u = response.data.user
        dispatch(setLoggedInUser(u, u.access_token))
        setCurrentStep(stepNumber)
        if(redirectToPlans) {
          navigate('/plans')
        }
      }).catch((error) => {
        if(error.response && error.response.data.errors) {
          setErrors(error.response.data.errors)
        } else {
          setErrors({ user: 'Unknown error trying to update user' })
        }
      })
    }
  }

  useEffect(() => {
    updateSignupStep(currentStep, false)
  }, [currentStep])

  useEffect(() => {
    if(password.length >= MIN_PASSWORD_LENGTH && password.length <= MAX_PASSWORD_LENGTH && /\d/.test(password)) {
      if(!validPassword) setValidPassword(true)
    } else {
      if(validPassword) setValidPassword(false)
    }
  }, [password])

  useEffect(() => {
    if(currentStep === EMAIL_STEP) {
      if(confirmedAge && acceptedTerms) {
        if(nextDisabled) setNextDisabled(false)
      } else {
        if(!nextDisabled) setNextDisabled(true)
      }
    } else if(currentStep !== PASSWORD_STEP || validPassword) {
      if(nextDisabled) setNextDisabled(false)
    } else {
      if(!nextDisabled) setNextDisabled(true)
    }
  }, [validPassword, currentStep, acceptedTerms, confirmedAge])

  return (
    <>
      <div className="signup-form">
        <Container>
          <Row>
            <Col>
              <h3>Register for VU</h3>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <div className="shadowed-box">
                <h1><StepMeter step={currentStep}/></h1>
                {errors && errors.user && <div className='error'>{errors.user}</div>}
                {currentStep === EMAIL_STEP && <Form.Group controlId='email' className="email-step-fields">
                  <TermsModal visible={termsVisible} setVisible={setTermsVisible} />
                  <Form.Label>Where should we email you?</Form.Label>
                  <p className="subtitle">Enter your e-mail address</p>
                  <Form.Control type="email" value={email} onKeyPress={onKeyPress} onChange={(event) => { setEmail(event.target.value) }}/>
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>
                        I have read and agree to the <a onClick={() => setTermsVisible(true)} className="simple-link" href="javascript: void(0)">Terms and Conditions</a>
                      </>
                    )}
                    checked={acceptedTerms}
                    onChange={() => { setAcceptedTerms(!acceptedTerms) }}
                    onKeyPress={onKeyPress}
                  />
                  <Form.Check
                    type="checkbox"
                    label={(
                      <>
                        I confirm I am 13 years old or older
                      </>
                    )}
                    checked={confirmedAge}
                    onChange={() => { setConfirmedAge(!confirmedAge) }}
                    onKeyPress={onKeyPress}
                  />
                  {errors.email && <div className='error'>{errors.email}</div>}
                </Form.Group>}
                {currentStep === DISPLAY_NAME_STEP && <Form.Group controlId='name'>
                  <Form.Label>What should we call you?</Form.Label>
                  <p className="subtitle">Enter your public name</p>
                  <p style={{fontSize: '.8rem', color: '#cccccc'}}>
                    Must have at least 3 characters.<br />
                    Must contain at least one letter.<br />
                    Must NOT contain space or special characters.<br />
                  </p>
                  <Form.Control type="text" maxlength={16} value={displayName} onKeyPress={onKeyPress} onChange={(event) => { setDisplayName(event.target.value) }}/>
                  {errors.display_name && <div dangerouslySetInnerHTML={{__html: errors.display_name}} className='error' />}
                </Form.Group>}
                {currentStep === PASSWORD_STEP && <Form.Group controlId='password'>
                  <Form.Label>Set your password</Form.Label>
                  <div style={{ marginBottom: '10px', color: (validPassword ? 'green' : 'red')}}>(at least {MIN_PASSWORD_LENGTH} characters and 1 number)</div>
                  <PasswordField 
                    value={password}
                    onKeyPress={onKeyPress}
                    setPassword={setPassword}
                    />
                  {errors && errors.password && <div className='error'>{errors.password}</div>}
                </Form.Group>}
                {currentStep === GENRE_STEP && <Form.Group controlId='medium_genres'>
                  <Form.Label>What genres do you prefer?</Form.Label>
                  <Container fluid>
                    {userMediumGenres.length > 0 && userMediumGenres.map((user_medium_genre, index) => <UserMediumGenreSelector key={user_medium_genre.id} user_medium_genre={user_medium_genre} isSelected={genreIsSelected(user_medium_genre.id)} changeSelected={changeGenreSelected}/> )}
                  </Container>
                </Form.Group>}
                {currentStep === PROVIDER_STEP && <Form.Group controlId='streaming_providers'>
                  <Form.Label>Welcome to VU!</Form.Label>
                  <p className="subtitle">Select your streaming services</p>
                  <Container fluid>
                    {mediumProvidersFeatured.length > 0 && mediumProvidersFeatured.map((medium_provider, index) => (
                      <Row className="medium-provider-row">
                        <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[0]} isSelected={mediumProviderIsSelected(medium_provider[0].id)} changeSelected={changeMediumProviderSelected}/>
                        {medium_provider.length > 1 && <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[1]} isSelected={mediumProviderIsSelected(medium_provider[1].id)} changeSelected={changeMediumProviderSelected}/>}
                      </Row>
                     ))}
                  </Container>
                  <p className="subtitle" style={{ marginTop: '15px' }}>Additional Platforms</p>
                  <Container fluid>
                    {mediumProviders.length > 0 && mediumProviders.map((medium_provider, index) => (
                      <Row className="medium-provider-row">
                        <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[0]} isSelected={mediumProviderIsSelected(medium_provider[0].id)} changeSelected={changeMediumProviderSelected}/>
                        {medium_provider.length > 1 && <MediumProviderSelector key={Math.random()} medium_provider={medium_provider[1]} isSelected={mediumProviderIsSelected(medium_provider[1].id)} changeSelected={changeMediumProviderSelected}/>}
                      </Row>
                     ))}
                  </Container>
                </Form.Group>}
                <div className='centered'>
                  {currentStep <= LAST_SIGNUP_STEP && <Button disabled={nextDisabled} className="btn-success top-margin-button top-20" onClick={nextStep}>{currentStep < LAST_SIGNUP_STEP ? 'Next' : 'Save'}</Button>}
                  {!user.id && <div className="top-20">
                    Have an account? <Link to="/login" className="simple-link">Sign in</Link>
                  </div>}
                </div>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
