import { useEffect, useState } from 'react'
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap"
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

export default function MediaBrowserFilter( { allStreaming, setAllStreaming, mediaType, setMediaType }) {
  const [ mediaTypeLabel, setMediaTypeLabel ] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if(parsed.all_streaming === 'false') {
      if(allStreaming) setAllStreaming(false)
    } else if(parsed.all_streaming !== undefined) {
      if(!allStreaming) setAllStreaming(true)
    }
    if(parsed.media_type) {
      if(parsed.media_type === 'movies') {
        if(mediaType !== 'movies') setMediaType('movies')
      } else if(parsed.media_type === 'series') {
        if(mediaType !== 'series') setMediaType('series')
      } else if(parsed.media_type !== undefined) {
        if(mediaType !== 'all') setMediaType('all')
      }
    }
  }, [location.search])

  useEffect(() => {
    if(mediaType === 'all') {
      setMediaTypeLabel('All Movies And Series')
    } else if(mediaType === 'movies') {
      setMediaTypeLabel('Movies')
    } else {
      setMediaTypeLabel('Series')
    }
  }, [mediaType])

  const changeFilter = (newMediaType, newAllStreaming) => {
    if(newMediaType !== mediaType) setMediaType(newMediaType)
    if(newAllStreaming !== allStreaming) setAllStreaming(newAllStreaming)
    const path = `?all_streaming=${newAllStreaming ? '' : 'false'}&media_type=${newMediaType}`
    navigate(path)
  }

  return (
    <Container style={{paddingLeft: '0px'}}>
      <Row className="filter-row">
        <Col>
          <span className="check-label">All streaming services</span>
          <Form.Check
            className="check-field"
            type="switch"
            id="custom-switch"
            checked={allStreaming}
            onChange={() => { changeFilter(mediaType, !allStreaming) }}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col>
          <Dropdown style={{ width: '200px'}}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {mediaTypeLabel} &nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#all" onClick={() => { changeFilter('all', allStreaming) }}>All Movies And Series</Dropdown.Item>
              <Dropdown.Item href="#movies" onClick={() => { changeFilter('movies', allStreaming) }}>Movies</Dropdown.Item>
              <Dropdown.Item href="#series" onClick={() => { changeFilter('series', allStreaming) }}>Series</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  )
}