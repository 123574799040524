import { SET_RATING_MODAL_DATA } from "../config/constants.js"

const rating_modal_data = (state = {}, action) => {
  switch (action.type) {
    case SET_RATING_MODAL_DATA:
      return action.rating_modal_data
    default:
      return state
  }
}

export default rating_modal_data
