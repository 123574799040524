import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { LAST_SIGNUP_STEP } from '../../config/constants'
import './PublicPage.scss'

export default function DeletionPage() {
  const user = useSelector(state => state.user)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    if (user.access_token && user.signup_step > LAST_SIGNUP_STEP) {
      if(!loggedIn) setLoggedIn(true)
    } else {
      if(loggedIn) setLoggedIn(false)
    }
  }, [user])

  return (
    <>
      <pre className={loggedIn ? "pre-logged-in" : "pre-not-logged"} style={{marginTop: '30px'}}>
        User Data Deletion<br />
        You can delete all your data by logging in and going to Settings -> Delete Account (inside the Options Tab).<br />
        You can also send an email to support@vuniverse.com asking for data deletion.
      </pre>
    </>
  )
}