import { api_client } from '../../config/api-client'
import { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setSearchQuery } from '../../actions/search'
import MediaBrowser from '../MediaBrowser/MediaBrowser'
import { Container } from "react-bootstrap"

export default function ShowPerson({
  initialSelection,
  setSelection,
  selection,
  match,
}) {

  const [media, setMedia] = useState([])
  const [person, setPerson] = useState({})
  const [mediaByGenre, setMediaByGenre] = useState([])
  const dispatch = useDispatch()
  const { personId } = useParams()

  const fetchMediaByGenre = async () => {
    const access_token = localStorage.getItem('access_token')
    var requestParams = { grouped_genres: true, selection: initialSelection, personId: personId }
    const response = await api_client(access_token).get(`/api/v2/media`, { params: requestParams })
    const resData = response.data.grouped_media
    if(response.data.person) {
      setPerson(response.data.person)
    }
    setMedia(resData[0].media)
    setMediaByGenre(resData.slice(1))
    setPerson(response.data.person)
  }

  useEffect(() => {
    if((media.length < 1 && mediaByGenre.length < 1) || personId !== person.id) {
      fetchMediaByGenre()
    }
    dispatch(setSearchQuery(''))
    if(selection !== initialSelection) setSelection(initialSelection)
  }, [personId])

  return (
    <Container>
      {person && person.full_name && <h4>Movies And Shows with {person.full_name}</h4>}
      <MediaBrowser setSelection={setSelection} selection={selection} initialSelection={'from_person'} media={media} mediaByGenre={mediaByGenre} person={person} />
    </Container>
  );
}
