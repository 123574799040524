import React, { useState, useEffect } from 'react'
import { api_client } from '../../config/api-client'
import { Row, Col } from 'react-bootstrap'
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.css"
import './PlanListItem.scss'
import ConfirmDialog from '../Settings/ConfirmDialog'

export default function FreePlanListItem({ item, current_user }) {
  const [showingConfirmation, setShowingConfirmation] = useState(false)
  const [inOtherPlan, setInOtherPlan] = useState(false)
  const [isCurrentPlan, setIsCurrentPlan] = useState(false)
  const [ canSelect, setCanSelect ] = useState(false)

  const confirmChangePlan = () => {
    if(!canSelect) return;
    setShowingConfirmation(true)
  }

  const changePlan = () => {
    api_client(localStorage.getItem('access_token')).post('/api/v1/plans/change.json', {
      plan_id: item.id,
    }).then((response) => {
      window.location.href = '/'
    }).catch((error) => {
      if(error.response && error.response.data.error) {
        alert(error.response.data.message)
      } else {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    setIsCurrentPlan(current_user.product_id === item.id)
    if(current_user.product_id && current_user.product_id.length > 1 && current_user.product_id !== item.id) {
      setInOtherPlan(true)
    }
  }, [])

  useEffect(() => {
    setCanSelect((!isCurrentPlan || (current_user.subscription_canceled && !current_user.subscribed)))
  }, [isCurrentPlan, current_user])

  return(
    <>
      <Row className={isCurrentPlan ? 'plan current-plan' : 'plan'}  onClick={confirmChangePlan}>
        <Col md={7} className="left-aligned">
          <div className="plan-name">Early Adaptor Special:</div>
          <div className="plan-price"><br />Try us! You will love VU! Free for Life for a Limited Time Only</div>
        </Col>
        {canSelect && <Col md={5} className="right-aligned">
          <div>$FREE for LIFE</div>
          <Button variant="primary">Select</Button>
        </Col>}
      </Row>

      <ConfirmDialog
        visible={showingConfirmation}
        setVisible={setShowingConfirmation}
        title={"Subscribe"}
        prompt={(
          <>
            {!inOtherPlan && <span>Are you sure you want to subscribe to <b>{item.name}</b>?</span>}
            {inOtherPlan && <span>Are you sure you want to switch from <b>{current_user.product_name}</b> to <b>{item.name}</b>?</span>}
          </>
        )}
        onConfirm={changePlan}
        buttonLabel="Yes, I am sure"
      />
    </>
  )
}
